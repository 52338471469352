import { Duration, DurationObjectUnits } from 'luxon';
import * as yup from 'yup';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { utils } from 'ethers';
import BigNumber from 'bignumber.js';

import errorMap from '../constants/contractErrorMap.json';
import { ChainId } from '../constants';
import Fund from '../contracts/abis/Fund.json';
import Registry from '../contracts/abis/Registry.json';
import tokenList from '../constants/tokenList.json';

export function etherscanLink(address: string, chainId: string) {
  if (chainId === ChainId.POLYGON || chainId === ChainId.NOT_CONNECTED) {
    return `https://polygonscan.com/address/${address}`;
  } else {
    return '';
  }
}

export function etherscanTxLink(network: string, txHash: string) {
  if (network === 'ethereum') {
    return `https://etherscan.io/tx/${txHash}`;
  } else if (network === 'polygon') {
    return `https://polygonscan.com/tx/${txHash}`;
  } else if (network === 'binance-smart-chain') {
    return `https://bscscan.com/tx/${txHash}`;
  } else {
    return `https://etherscan.io/tx/${txHash}`;
  }
}

export function ethAddressSubstring(address: string, charsEachEnd = 4) {
  return `${address.substring(0, charsEachEnd + 2)}...${address.substring(
    42 - charsEachEnd,
    42,
  )}`;
}

export function errorLookup(error: any) {
  console.log(error);
  const errorHash =
    error?.data?.data || error?.error?.data?.originalError?.data;
  if (errorHash) {
    try {
      const fundInterface = new utils.Interface(Fund);
      const errorName = fundInterface.parseError(errorHash)?.name;
      if (errorName) {
        const errorMessage = (errorMap as any)[errorName];
        return errorMessage || errorName;
      }
    } catch (err) {
      console.log(err);
    }
    try {
      const registryInterface = new utils.Interface(Registry);
      const errorName = registryInterface.parseError(errorHash)?.name;
      if (errorName) {
        const errorMessage = (errorMap as any)[errorName];
        return errorMessage || errorName;
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    error?.data?.message ||
    error?.error?.data?.originalError?.message ||
    error?.message
  );
}

export function convertDuration(
  amount: string,
  fromUnit: keyof DurationObjectUnits,
  toUnit: keyof DurationObjectUnits,
): number {
  return Duration.fromObject({ [fromUnit]: parseFloat(amount) }).as(toUnit);
}

export function percentToBasisPoints(percent: string): string {
  return (parseFloat(percent) * 100).toString();
}

export const ipfsDataSchema = yup.object().shape({
  schema: yup.number().required().positive().integer(),
  fund: yup.string().required(),
  timestamp: yup.number().required().positive().integer(),
  assets: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      notes: yup.string().optional(),
      amount: yup.string().required(),
      value: yup.string().required(),
      // v2
      img: yup.string().optional(),
      contract: yup.string().optional(),
      chain: yup.string().optional(),
      type: yup.string().optional(),
    }),
  ),
});

export function tokenIconLookup(address: string): string {
  return (
    tokenList.tokens.find(
      (token) => token.address.toLowerCase() === address.toLowerCase(),
    )?.logoURI || ''
  );
}

export function dollarGridFormatter({ value }: GridValueFormatterParams) {
  return typeof value === 'number' ? formatDollars(value) : value;
}

export function priceGridFormatter({ value }: GridValueFormatterParams) {
  return typeof value === 'number' ? formatDollars(value, 2, 4) : value;
}

export function percentGridFormatter({ value }: GridValueFormatterParams) {
  return typeof value === 'number'
    ? `${new BigNumber(value).toFormat(2)}%`
    : value;
}

export function formatDollars(
  value: number | string | BigNumber,
  decimals = 2,
  maxDecimals = decimals,
) {
  const valueNumber =
    typeof value === 'number'
      ? value
      : typeof value === 'string'
      ? parseFloat(value.replaceAll(',', ''))
      : value.toNumber();
  return valueNumber.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: maxDecimals,
    style: 'currency',
    currency: 'USD',
  });
}

export const regexes = {
  address: /^0x[a-fA-F0-9]{40}$/,
  decimal: /^[0-9]+\.?[0-9]*$/,
};

export const ipfsGatewayUrls = [
  ...(window.location.host.startsWith('localhost')
    ? [
        (ipfsHash: string) => `http://ipfs.localhost/ipfs/${ipfsHash}`,
        (ipfsHash: string) =>
          `https://ipfs.staging.smartfunds.xyz/ipfs/${ipfsHash}`,
        (ipfsHash: string) =>
          `https://ipfs.app.smartfunds.xyz/ipfs/${ipfsHash}`,
      ]
    : window.location.host.startsWith('staging')
    ? [
        (ipfsHash: string) =>
          `https://ipfs.staging.smartfunds.xyz/ipfs/${ipfsHash}`,
        (ipfsHash: string) =>
          `https://ipfs.app.smartfunds.xyz/ipfs/${ipfsHash}`,
      ]
    : [
        (ipfsHash: string) =>
          `https://ipfs.app.smartfunds.xyz/ipfs/${ipfsHash}`,
        (ipfsHash: string) =>
          `https://ipfs.staging.smartfunds.xyz/ipfs/${ipfsHash}`,
      ]),
];

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const idx = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${parseFloat((bytes / Math.pow(1024, idx)).toFixed(decimals))} ${
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][idx]
  }`;
}
