/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { Fund, FundInterface } from "../Fund";

const _abi = [
  {
    inputs: [],
    name: "AfterDeadline",
    type: "error",
  },
  {
    inputs: [],
    name: "AlreadySweepedThisPeriod",
    type: "error",
  },
  {
    inputs: [],
    name: "AlreadyWhitelisted",
    type: "error",
  },
  {
    inputs: [],
    name: "AumUpdaterOnly",
    type: "error",
  },
  {
    inputs: [],
    name: "CannotTransferUsdToCustodian",
    type: "error",
  },
  {
    inputs: [],
    name: "CustodianCannotCreateRequests",
    type: "error",
  },
  {
    inputs: [],
    name: "FeeBeneficiaryNotSet",
    type: "error",
  },
  {
    inputs: [],
    name: "FeeSweeping",
    type: "error",
  },
  {
    inputs: [],
    name: "FundClosed",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidAmountReturnedZero",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidFeeBeneficiary",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidFeeTimestamp",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialPrice",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInvestmentId",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInvestor",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidMaxAmount",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidMaxInvestors",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidMinInvestmentAmount",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidTimelock",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidUpgrade",
    type: "error",
  },
  {
    inputs: [],
    name: "InvestmentLockedUp",
    type: "error",
  },
  {
    inputs: [],
    name: "InvestmentRedeemed",
    type: "error",
  },
  {
    inputs: [],
    name: "InvestmentTooSmall",
    type: "error",
  },
  {
    inputs: [],
    name: "InvestorIsActive",
    type: "error",
  },
  {
    inputs: [],
    name: "ManagerOnly",
    type: "error",
  },
  {
    inputs: [],
    name: "MaxInvestmentsReached",
    type: "error",
  },
  {
    inputs: [],
    name: "NoExistingRequest",
    type: "error",
  },
  {
    inputs: [],
    name: "NoLongerImportingInvestments",
    type: "error",
  },
  {
    inputs: [],
    name: "NotActive",
    type: "error",
  },
  {
    inputs: [],
    name: "NotEnoughFees",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInvestmentOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInvestor",
    type: "error",
  },
  {
    inputs: [],
    name: "NotTransferable",
    type: "error",
  },
  {
    inputs: [],
    name: "NotUsingUsdToken",
    type: "error",
  },
  {
    inputs: [],
    name: "PermitValueMismatch",
    type: "error",
  },
  {
    inputs: [],
    name: "PriceOutsideTolerance",
    type: "error",
  },
  {
    inputs: [],
    name: "RequestOutOfDate",
    type: "error",
  },
  {
    inputs: [],
    name: "TooManyInvestors",
    type: "error",
  },
  {
    inputs: [],
    name: "WhitelistedOnly",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "previousAdmin",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newAdmin",
        type: "address",
      },
    ],
    name: "AdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "aumUpdater",
        type: "address",
      },
    ],
    name: "AumUpdaterChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "beacon",
        type: "address",
      },
    ],
    name: "BeaconUpgraded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
    ],
    name: "Blacklisted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "Closed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "delegator",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "fromDelegate",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "toDelegate",
        type: "address",
      },
    ],
    name: "DelegateChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "delegate",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "previousBalance",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newBalance",
        type: "uint256",
      },
    ],
    name: "DelegateVotesChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "DoneImportingInvestments",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "feeBeneficiary",
        type: "address",
      },
    ],
    name: "FeeBeneficiaryChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "FeeSweepEnded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "FeeSweepStarted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "managementFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "performanceFee",
        type: "uint256",
      },
    ],
    name: "FeesChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "investmentId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "feeSweepId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "highWaterMark",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdManagementFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdPerformanceFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fundManagementFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fundPerformanceFee",
        type: "uint256",
      },
    ],
    name: "FeesSwept",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "feeWithdrawalId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fundAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "usdTransferred",
        type: "bool",
      },
    ],
    name: "FeesWithdrawn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "logoUrl",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "contactInfo",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "tags",
        type: "string",
      },
    ],
    name: "FundDetailsChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "investmentId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "investmentRequestId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fundAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "highWaterMark",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "managementFeeCostBasis",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "lockupTimestamp",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "lastFeeSweepTimestamp",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "imported",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "usdTransferred",
        type: "bool",
      },
    ],
    name: "Invested",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "investmentRequestId",
        type: "uint256",
      },
    ],
    name: "InvestmentRequestCanceled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "investmentRequestId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minFundAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxFundAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "deadline",
        type: "uint256",
      },
    ],
    name: "InvestmentRequested",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "maxInvestors",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxInvestmentsPerInvestor",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minInvestmentAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "timelock",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "feeSweepInterval",
        type: "uint256",
      },
    ],
    name: "InvestorLimitsChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "navId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "aum",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "supply",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalCapitalContributed",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "ipfsHash",
        type: "string",
      },
    ],
    name: "NavUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "investmentId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "redemptionId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "redemptionRequestId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fundAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "usdTransferred",
        type: "bool",
      },
    ],
    name: "Redeemed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "investmentId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "redemptionRequestId",
        type: "uint256",
      },
    ],
    name: "RedemptionRequestCanceled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "investmentId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "redemptionRequestId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minUsdAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "deadline",
        type: "uint256",
      },
    ],
    name: "RedemptionRequested",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "implementation",
        type: "address",
      },
    ],
    name: "Upgraded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
    ],
    name: "Whitelisted",
    type: "event",
  },
  {
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "usdAmount",
        type: "uint256",
      },
    ],
    name: "addManualInvestment",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "investmentId",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "transferUsd",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "permitValue",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "permitDeadline",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "addManualRedemption",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "blacklistedInvestors",
        type: "address[]",
      },
    ],
    name: "blacklistMulti",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "cancelInvestmentRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "investmentId",
        type: "uint256",
      },
    ],
    name: "cancelRedemptionRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "pos",
        type: "uint32",
      },
    ],
    name: "checkpoints",
    outputs: [
      {
        components: [
          {
            internalType: "uint32",
            name: "fromBlock",
            type: "uint32",
          },
          {
            internalType: "uint224",
            name: "votes",
            type: "uint224",
          },
        ],
        internalType: "struct ERC20VotesUpgradeable.Checkpoint",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "usdAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minFundAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxFundAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "update",
        type: "bool",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "createOrUpdateInvestmentRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "investmentId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minUsdAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "update",
        type: "bool",
      },
    ],
    name: "createOrUpdateRedemptionRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "subtractedValue",
        type: "uint256",
      },
    ],
    name: "decreaseAllowance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "delegatee",
        type: "address",
      },
    ],
    name: "delegate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "delegatee",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "expiry",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "delegateBySig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "delegates",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAumUpdater",
        type: "address",
      },
    ],
    name: "editAumUpdater",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newFeeBeneficiary",
        type: "address",
      },
    ],
    name: "editFeeBeneficiary",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "newLogoUrl",
        type: "string",
      },
      {
        internalType: "string",
        name: "newContactInfo",
        type: "string",
      },
      {
        internalType: "string",
        name: "newTags",
        type: "string",
      },
    ],
    name: "editFundDetails",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newMaxInvestors",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newMaxInvestmentsPerInvestor",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newMinInvestmentAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newTimelock",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newFeeSweepInterval",
        type: "uint256",
      },
    ],
    name: "editInvestorLimits",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "blockNumber",
        type: "uint256",
      },
    ],
    name: "getPastTotalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "blockNumber",
        type: "uint256",
      },
    ],
    name: "getPastVotes",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getVotes",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "usdAmountRemaining",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "lockupTimestamp",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "highWaterMark",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "originalUsdAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "lastFeeSweepTimestamp",
        type: "uint256",
      },
    ],
    name: "importInvestment",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "addedValue",
        type: "uint256",
      },
    ],
    name: "increaseAllowance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[2]",
        name: "addressParams",
        type: "address[2]",
      },
      {
        internalType: "uint256[9]",
        name: "uintParams",
        type: "uint256[9]",
      },
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "symbol",
        type: "string",
      },
      {
        internalType: "string",
        name: "newLogoUrl",
        type: "string",
      },
      {
        internalType: "string",
        name: "newContactInfo",
        type: "string",
      },
      {
        internalType: "string",
        name: "newTags",
        type: "string",
      },
      {
        internalType: "bool",
        name: "usingUsdToken",
        type: "bool",
      },
      {
        internalType: "address",
        name: "manager",
        type: "address",
      },
      {
        internalType: "contract RegistryV0",
        name: "registry",
        type: "address",
      },
      {
        internalType: "contract ERC20Permit",
        name: "usdToken",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "numCheckpoints",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "permit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "investmentIds",
        type: "uint256[]",
      },
    ],
    name: "processFees",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "investmentRequestId",
        type: "uint256",
      },
    ],
    name: "processInvestmentRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "redemptionRequestId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "permitValue",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "permitDeadline",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "processRedemptionRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "investmentId",
        type: "uint256",
      },
    ],
    name: "redemptionUsdAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "usdAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "aum",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "ipfsHash",
        type: "string",
      },
    ],
    name: "updateAum",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
    ],
    name: "upgradeTo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "upgradeToAndCall",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "newInvestors",
        type: "address[]",
      },
    ],
    name: "whitelistMulti",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "fundAmount",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "transferUsd",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "permitValue",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "permitDeadline",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "withdrawFees",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class Fund__factory {
  static readonly abi = _abi;
  static createInterface(): FundInterface {
    return new utils.Interface(_abi) as FundInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Fund {
    return new Contract(address, _abi, signerOrProvider) as Fund;
  }
}
