import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import Cookies from 'js-cookie';
import { utils } from 'ethers';
import { useConnectWallet, useWallets } from '@web3-onboard/react';

import { REGISTRY_ADDRESS } from '../../constants';
import {
  updateUsdBalance,
  setGraph,
  updateRefreshTimestamp,
  updateEthBalance,
} from './actions';
import { AppDispatch, AppState, useAppDispatch } from '..';
import { useUSDCTestTokenContract } from '../../hooks/contracts';
import { BoolMap } from './reducer';
import { useBlockNumberLazyQuery } from '../../apollo/generated';
import { useActiveWeb3 } from '../../hooks/wallet';
import { useInterval } from '../../hooks/misc';

export const useRefreshTimestamp = () => {
  const { chainId } = useActiveWeb3();
  const refreshTimestamp = useSelector(
    (state: AppState) => state.application.refreshTimestamp[chainId],
  );
  return refreshTimestamp;
};

export const useUsdcBalanceByAddr = (
  address: string,
): [string, () => Promise<string>] => {
  const usdcToken = useUSDCTestTokenContract();
  const [balance, setBalance] = useState('');

  const getUsdcBalance = useCallback(async () => {
    let balance = '';
    if (address) {
      balance = utils.formatUnits(await usdcToken.balanceOf(address), 6);
    }
    setBalance(balance);
    return balance;
  }, [usdcToken, address]);

  return [balance, getUsdcBalance];
};

export const useUsdcBalance = (): [string, () => Promise<string>] => {
  const dispatch: AppDispatch = useDispatch();
  const usdcToken = useUSDCTestTokenContract();
  const { address } = useActiveWeb3();
  const balance = useSelector(
    (state: AppState) => state.application.usdBalance,
  );

  const getUsdcBalance = useCallback(async () => {
    let balance = '0';
    if (address) {
      balance = utils.formatUnits(await usdcToken.balanceOf(address), 6);
    }
    dispatch(updateUsdBalance({ balance }));
    return balance;
  }, [dispatch, address, usdcToken]);

  return [balance, getUsdcBalance];
};

export function useRefreshEthBalance() {
  const dispatch = useAppDispatch();
  const { address, provider } = useActiveWeb3();

  return useCallback(async () => {
    let balance = '0';
    if (address) {
      const weiBalance = await provider.getBalance(address);
      balance = utils.formatEther(weiBalance);
    }
    dispatch(updateEthBalance({ balance }));
    return balance;
  }, [dispatch, address, provider]);
}

export const useRefreshOnWalletChange = () => {
  const { chainId, address } = useActiveWeb3();
  const refresh = useRefresh();
  useEffect(() => {
    if (REGISTRY_ADDRESS[chainId]) {
      refresh();
    }
  }, [refresh, chainId, address]);
};

export const useEagerConnect = () => {
  const [, connectWallet] = useConnectWallet();
  const wallets = useWallets();

  const walletLabel = wallets[0]?.label;
  useEffect(() => {
    if (walletLabel) {
      window.localStorage.setItem('connectedWalletLabel', walletLabel);
    }
  }, [walletLabel]);

  useEffect(() => {
    const storedWalletLabel = window.localStorage.getItem(
      'connectedWalletLabel',
    );
    if (Cookies.get('agree') && storedWalletLabel) {
      connectWallet({
        autoSelect: { label: storedWalletLabel, disableModals: true },
      });
    }
  }, [connectWallet]);
};

export function useSelectGraph(): [BoolMap, (graph: string) => void] {
  const dispatch = useDispatch<AppDispatch>();

  const selectedGraph = useSelector(
    (state: AppState) => state.application.selectedGraph,
  );

  const changeGraph = useCallback(
    (graph) => {
      dispatch(setGraph({ graph }));
    },
    [dispatch],
  );

  return [selectedGraph, changeGraph];
}

// Function to refresh standard everyday data
export const useRefresh = () => {
  const { chainId } = useActiveWeb3();
  const [, refreshUsdBalance] = useUsdcBalance();

  const dispatch: AppDispatch = useDispatch();
  const client = useApolloClient();
  const [fetchBlockNumber] = useBlockNumberLazyQuery();
  const refreshEthBalance = useRefreshEthBalance();

  return useCallback(
    async (blockNum?: number) => {
      try {
        if (blockNum) {
          let resp = await fetchBlockNumber();
          while ((resp?.data?._meta?.block?.number || 0) < blockNum) {
            await new Promise((res) => setTimeout(res, 5000));
            resp = await fetchBlockNumber();
          }
        }
        await client.refetchQueries({
          include: 'active', //TODO: recommended not to use but keeps cache in sync
        });
        await refreshUsdBalance();
        await refreshEthBalance();
        const timestamp = new Date().getTime();
        dispatch(updateRefreshTimestamp({ chainId, timestamp }));
      } catch (err) {
        // toast(err?.error?.message || err?.message || "Wallet Error");
        console.error(err);
      }
    },
    [
      dispatch,
      chainId,
      refreshUsdBalance,
      client,
      fetchBlockNumber,
      refreshEthBalance,
    ],
  );
};

export function usePollBalances() {
  const refreshEth = useRefreshEthBalance();
  const [, refreshUsdc] = useUsdcBalance();

  useInterval(() => {
    refreshEth();
    refreshUsdc();
  }, 30000);
}
