import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Alert, { AlertColor } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import { Toaster, ToastType } from 'react-hot-toast';
import { Suspense } from 'react';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import DisclaimerAndWalletCheck from './DisclaimerAndWalletCheck';
import {
  useRefreshOnWalletChange,
  useEagerConnect,
  usePollBalances,
} from '../state/application/hooks';
import { mobileBreakpoint, sidebarWidth } from '../theme';
import CenteredProgress from './CenteredProgress';

const toastTypeToAlertSeverity: { [key in ToastType]: AlertColor } = {
  success: 'success',
  error: 'error',
  loading: 'info',
  blank: 'info',
  custom: 'info',
};

export default function AppLayout() {
  useEagerConnect();
  useRefreshOnWalletChange();
  usePollBalances();

  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Sidebar />
      <Box
        component='main'
        sx={{
          paddingY: 3,
          width: {
            xs: '100%',
            [mobileBreakpoint]: `calc(100% - ${sidebarWidth}px)`,
          },
        }}
      >
        <Toolbar />
        <Container fixed>
          <DisclaimerAndWalletCheck>
            <Suspense fallback={<CenteredProgress />}>
              <Outlet />
            </Suspense>
          </DisclaimerAndWalletCheck>
        </Container>
      </Box>
      <Toaster
        position='bottom-right'
        toastOptions={{ blank: { duration: Infinity } }}
      >
        {(t) => (
          <Slide in={t.visible} direction='up'>
            <Alert
              elevation={6}
              severity={toastTypeToAlertSeverity[t.type]}
              icon={
                t.type === 'loading' ? (
                  <CircularProgress size={22} color='secondary' />
                ) : undefined
              }
            >
              {t.message}
            </Alert>
          </Slide>
        )}
      </Toaster>
    </Box>
  );
}
