import { useSetChain } from '@web3-onboard/react';
import { useState, ReactNode } from 'react';
import Cookies from 'js-cookie';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { ChainId, CHAIN_NAMES } from '../constants';
import { useActiveWeb3 } from '../hooks/wallet';

function ValidChain({ children }: { children: ReactNode }) {
  const { chainId } = useActiveWeb3();
  const [, setChain] = useSetChain();

  const isValidChain =
    chainId === ChainId.POLYGON ||
    chainId === ChainId.NOT_CONNECTED;

  if (!isValidChain) {
    return (
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
        spacing={3}
        mt={5}
      >
        <Typography variant='h6'>
          Please switch to a supported network in your wallet
        </Typography>
        <Button
          variant='contained'
          onClick={() => setChain({ chainId: ChainId.POLYGON })}
        >
          {CHAIN_NAMES[ChainId.POLYGON]}
        </Button>
      </Stack>
    );
  } else {
    return <>{children}</>;
  }
}

export default function DisclaimerAndWalletCheck({
  children,
}: {
  children: ReactNode;
}) {
  const [cookieValue, setCookieValue] = useState(() => Cookies.get('agree'));
  if (cookieValue) {
    return <ValidChain>{children}</ValidChain>;
  } else {
    return (
      <Box display='flex' justifyContent='center'>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={3}
          mt={5}
          maxWidth={(theme) => theme.breakpoints.values.sm}
        >
          <Typography variant='h5'>Disclaimer</Typography>
          <Typography textAlign='center'>
            SmartFunds does not provide investment advice and is not liable for
            lost capital. This product is still in beta. Use it at your own
            risk. By using this product, you also agree to the Smart Money{' '}
            <Link href='https://www.smartfunds.xyz/terms.html' target='_blank'>
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link
              href='https://www.smartfunds.xyz/privacy.html'
              target='_blank'
            >
              Privacy Policy
            </Link>
          </Typography>
          <Button
            variant='contained'
            onClick={() => {
              Cookies.set('agree', '1');
              setCookieValue('1');
            }}
          >
            I Agree
          </Button>
        </Stack>
      </Box>
    );
  }
}
