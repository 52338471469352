import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from '@apollo/client';
import { ReactNode } from 'react';

import { GRAPH_URL, ChainId } from '../constants';
import { useActiveWeb3 } from '../hooks/wallet';

function createClient(uri: string) {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: createHttpLink({ uri }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
}

const clients: any = {};
for (const [key, value] of Object.entries(GRAPH_URL)) {
  clients[key] = createClient(value);
}

export default function ApolloClientWrapper({
  children,
  ...rest
}: {
  children: ReactNode;
}) {
  const { chainId } = useActiveWeb3();
  let chainIdOverride = chainId;
  if (!Object.values(ChainId).includes(chainIdOverride)) {
    chainIdOverride = ChainId.NOT_CONNECTED;
  }
  return (
    <ApolloProvider client={clients[chainIdOverride]} {...rest}>
      {children}
    </ApolloProvider>
  );
}
