import { createReducer } from '@reduxjs/toolkit';

import { updateState, resetState, nextStep, previousStep } from './actions';

export interface OnboardingData {
  onboardingStep: number;
  manager: string;
  aumUpdater: string;
  feeBeneficiary: string;
  timelock_months: string;
  managementFee_percent: string;
  performanceFee_percent: string;
  maxInvestors: string;
  maxInvestmentsPerInvestor: string;
  minInvestmentAmount_dollars: string;
  initialPrice_dollars: string;
  initialAum_dollars: string;
  feeTimelock_months: string;
  usingUsdToken: boolean;
  name: string;
  symbol: string;
  logoUrl: string;
  contactInfo: string;
  tags: string;
}

const initialState: OnboardingData = {
  onboardingStep: 1,
  manager: '',
  aumUpdater: '',
  feeBeneficiary: '',
  timelock_months: '',
  managementFee_percent: '',
  performanceFee_percent: '',
  maxInvestors: '',
  maxInvestmentsPerInvestor: '',
  minInvestmentAmount_dollars: '',
  initialPrice_dollars: '',
  initialAum_dollars: '',
  feeTimelock_months: '',
  usingUsdToken: true,
  name: '',
  symbol: '',
  logoUrl: '',
  contactInfo: '',
  tags: '',
};

export default createReducer(initialState, (buidler) => {
  buidler
    .addCase(updateState, (state, action) => {
      state = Object.assign(state, action.payload.data);
    })
    .addCase(resetState, (state) => {
      state = Object.assign(state, initialState);
    })
    .addCase(nextStep, (state) => {
      state.onboardingStep++;
    })
    .addCase(previousStep, (state) => {
      state.onboardingStep--;
    });
});
