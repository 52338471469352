import {
  experimental_extendTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import type {
  Theme as MuiTheme,
  CssVarsThemeOptions,
} from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/material/themeCssVarsAugmentation';

import './onboard.css';

declare module '@mui/material/styles' {
  // augment for default palette css vars
  interface Theme {
    vars: Omit<
      MuiTheme,
      'typography' | 'mixins' | 'breakpoints' | 'direction' | 'transitions'
    >;
  }

  // augment for custom palette css vars
  interface Palette {
    custom: {
      tables: {
        divider: string;
      };
      outlinedInput: {
        border: string;
      };
    };
  }
  interface PaletteOptions {
    custom: {
      tables: {
        divider: string;
      };
      outlinedInput: {
        border: string;
      };
    };
  }
}

const customTheme: CssVarsThemeOptions = {
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#303f9f',
          light: '#666ad1',
          dark: '#001970',
        },
        secondary: {
          main: '#9c27b0',
          light: '#d05ce3',
          dark: '#6A0080',
        },
        success: {
          main: '#66bb6a',
          light: '#81c784',
          dark: '#388e3c',
        },
        warning: {
          main: '#ffa726',
          light: '#ffb74d',
          dark: '#f57c00',
        },
        error: {
          main: '#f44336',
          light: '#e57373',
          dark: '#d32f2f',
        },
        background: { default: '#EEF0FE', paper: '#FCFCFC' },
        text: {
          primary: '#121212',
        },
        divider: '#E0E4FF',
        Avatar: {
          defaultBg: '#F4F7FE',
        },
        custom: {
          tables: {
            divider: 'rgba(110, 113, 145, 0.3)',
          },
          outlinedInput: {
            border: '#828493',
          },
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: '#789fd6',
        },
        secondary: {
          main: '#9c27b0',
        },
        success: {
          main: '#66bb6a',
        },
        warning: {
          main: '#ffa726',
        },
        error: {
          main: '#f44336',
        },
        background: {
          default: '#222426',
        },
        custom: {
          tables: {
            divider: 'rgba(110, 113, 145, 0.3)',
          },
          outlinedInput: {
            border: 'rgba(255,255,255,0.23)',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Poppins,Helvetica,Arial,sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '6.25rem',
      lineHeight: 1.33,
      letterSpacing: '0.01em',
    },
    h2: {
      fontWeight: 700,
      fontSize: '3.75rem',
      lineHeight: 1.33,
      letterSpacing: '0.01em',
    },
    h3: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.33,
      letterSpacing: '0.01em',
    },
    h4: {
      fontWeight: 700,
      fontSize: '2.125rem',
      lineHeight: 1.33,
      letterSpacing: '0.01em',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: 1.33,
      letterSpacing: '0.01em',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.33,
      letterSpacing: '0.01em',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.33,
      letterSpacing: '0.01em',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.33,
      letterSpacing: '0.01em',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: 1.33,
      letterSpacing: '0.01em',
    },
    body2: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.33,
      letterSpacing: '0.01em',
    },
    button: {
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: 1.5,
      letterSpacing: '0.05em',
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.33,
      letterSpacing: '0.03em',
    },
    overline: {
      fontWeight: 800,
      fontSize: '0.75rem',
      lineHeight: 1.58,
      letterSpacing: '0.15em',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          borderRadius: 10,
        },
        outlined: {
          borderRadius: 10,
          borderWidth: 2,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomWidth: 2,
        },
        vertical: {
          borderRightWidth: 2,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.vars.palette.custom.tables.divider,
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderStyle: 'none',
          '& .MuiDataGrid-row--lastVisible': {
            '& .MuiDataGrid-cell': {
              borderBottomColor: 'transparent',
            },
          },
        },
        // remove these ts-expect-error lines after this gets merged https://github.com/mui/mui-x/pull/5818
        columnHeaders: ({ theme }) => ({
          // @ts-expect-error waiting on above PR
          borderBottomColor: theme.vars.palette.custom.tables.divider,
        }),
        cell: ({ theme }) => ({
          // @ts-expect-error waiting on above PR
          borderBottomColor: theme.vars.palette.custom.tables.divider,
        }),
        footerContainer: ({ theme }) => ({
          // @ts-expect-error waiting on above PR
          borderColor: theme.vars.palette.custom.tables.divider,
        }),
        overlay: ({ theme }) => ({
          // @ts-expect-error waiting on above PR
          borderBottomColor: theme.vars.palette.custom.tables.divider,
          // @ts-expect-error waiting on above PR
          backgroundColor: theme.vars.palette.background.paper,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({ theme }) => ({
          borderColor: theme.vars.palette.custom.outlinedInput.border,
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingLeft: 44,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: 44,
        },
      },
    },
  },
};

export const sidebarWidth = 250;
export const mobileBreakpoint = 'md';

export const theme = responsiveFontSizes(experimental_extendTheme(customTheme));
