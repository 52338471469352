import { Link as RouterLink, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { useConnectWallet } from '@web3-onboard/react';
import BigNumber from 'bignumber.js';
import { Helmet } from 'react-helmet';
import { useColorScheme } from '@mui/material/styles';

import { ethAddressSubstring } from '../utils';
import { CHAIN_NAMES, CHAIN_TOKEN_NAMES } from '../constants';
import { useActiveWeb3 } from '../hooks/wallet';
import logo from '../assets/img/logo-80.png';
import whitelabelLogo from '../assets/img/logo-whitelabel.png';
import { useAppDispatch, useAppSelector } from '../state';
import { toggleSidebar } from '../state/application/actions';
import { mobileBreakpoint } from '../theme';
import { useIsDesktop } from '../hooks/misc';

export default function Header() {
  const [{ wallet }, connectWallet, disconnectWallet] = useConnectWallet();
  const { address, balance, chainId } = useActiveWeb3();
  const txPending = useAppSelector((state) => state.application.txPending);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const usdBalance = useAppSelector((state) => state.application.usdBalance);
  const isDesktop = useIsDesktop();
  const { mode, systemMode } = useColorScheme();
  const myMode = mode === 'system' ? systemMode : mode;

  const whitelabel = window.location.hostname === 'funds.tripleafunds.com';

  return (
    <AppBar
      position='fixed'
      color='default'
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
        borderRadius: 0,
      }}
    >
      <Helmet>
        <title>{whitelabel ? 'Triple A Funds' : 'SmartFunds'}</title>
        <link
          rel='icon'
          href={
            whitelabel
              ? `${window.location.origin}/favicon-whitelabel.png`
              : `${window.location.origin}/favicon.png`
          }
        />
        <meta
          name='description'
          content={
            whitelabel
              ? 'Triple A Funds - Web3 Native Funds'
              : 'SmartFunds - Web3 Native Funds'
          }
        />
        <link
          rel='manifest'
          href={
            whitelabel
              ? `${window.location.origin}/manifest-whitelabel.json`
              : `${window.location.origin}/manifest.json`
          }
        />
      </Helmet>
      <Toolbar>
        <IconButton
          edge='start'
          sx={{ display: { [mobileBreakpoint]: 'none' } }}
          onClick={() => dispatch(toggleSidebar())}
        >
          <MenuIcon />
        </IconButton>
        <RouterLink
          to='/'
          style={{ textDecoration: 'inherit', color: 'inherit' }}
        >
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            sx={{ ml: { [mobileBreakpoint]: '15px' } }}
          >
            <Avatar
              src={whitelabel ? whitelabelLogo : logo}
              sx={{ width: 30, height: 30, bgcolor: 'transparent' }}
              variant='square'
              alt='logo'
            />
            <Typography
              variant='h5'
              color='transparent'
              sx={{
                backgroundImage: (theme) =>
                  `linear-gradient(90deg, ${theme.vars.palette.primary.main}, ${theme.vars.palette.secondary.main})`,
                backgroundClip: 'text',
              }}
            >
              {whitelabel ? 'Triple A Funds' : 'SmartFunds'}
            </Typography>
          </Stack>
        </RouterLink>
        <Box flexGrow={1} />
        {txPending && (
          <CircularProgress
            color='secondary'
            sx={{ display: { xs: 'none', md: 'block' }, mx: 2 }}
            size={22}
          />
        )}
        {CHAIN_NAMES[chainId] && (
          <Typography sx={{ display: { xs: 'none', md: 'block' }, mx: 2 }}>
            {CHAIN_NAMES[chainId]}
          </Typography>
        )}
        {address && (
          <>
            <Typography sx={{ display: { xs: 'none', md: 'block' }, mx: 2 }}>
              {new BigNumber(balance).toFormat(4)}{' '}
              {CHAIN_TOKEN_NAMES[chainId] || 'ETH'}
            </Typography>
            <Typography sx={{ display: { xs: 'none', md: 'block' }, mx: 2 }}>
              ${new BigNumber(usdBalance).toFormat(2)} USDC
            </Typography>
          </>
        )}
        <Tooltip
          title={address ? 'Disconnect Wallet' : ''}
          arrow
          sx={{ mx: 2 }}
        >
          <Button
            variant={myMode === 'light' ? 'contained' : 'outlined'}
            color={myMode === 'light' ? 'primary' : 'inherit'}
            onClick={() => {
              if (address) {
                window.localStorage.removeItem('connectedWalletLabel');
                disconnectWallet({ label: wallet?.label || '' });
                navigate('/');
              } else {
                connectWallet();
              }
            }}
          >
            {address
              ? ethAddressSubstring(address)
              : `Connect${isDesktop ? ' Wallet' : ''}`}
          </Button>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}
