import { createApi } from '@reduxjs/toolkit/query/react';
import { ipfsGatewayUrls } from '../../utils';

export interface IpfsAsset {
  name: string; // token symbol if pulled from api
  img?: string;
  notes: string;
  chain: string;
  type: string;
  hide?: boolean;
  amount: string; // balance of with decimals, or 0 if unpriced
  value: string; // $ value
  contract?: string; // contract address if pulled from api
}

export interface IpfsAssetData {
  schema: 1;
  fund: string;
  timestamp: number;
  assets: IpfsAsset[];
}

export const ipfsAssetsApi = createApi({
  reducerPath: 'assets-history',
  baseQuery: async (ipfsHash: string) => {
    let data: IpfsAssetData | undefined;
    for (const url of ipfsGatewayUrls) {
      try {
        const resp = await fetch(url(ipfsHash));
        data = (await resp.json()) as IpfsAssetData;
        break;
      } catch (err) {
        console.log(err);
      }
    }
    if (data) {
      return { data };
    } else {
      return { error: 'All fetches failed' };
    }
  },
  endpoints: (build) => ({
    getIpfsAssetData: build.query<IpfsAssetData, string>({
      query: (ipfsHash) => ipfsHash,
    }),
  }),
});

export const { useGetIpfsAssetDataQuery } = ipfsAssetsApi;
