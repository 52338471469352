import { createAction } from '@reduxjs/toolkit';

export const updateRefreshTimestamp = createAction<{
  chainId: string;
  timestamp: number;
}>('application/updateRefreshTimestamp');
export const updateUsdBalance = createAction<{ balance: string }>(
  'application/updateUsdcBalance',
);
export const updateEthBalance = createAction<{ balance: string }>(
  'application/updateEthBalance',
);

export const setGraph = createAction<{ graph: string }>('application/setGraph');

// Tx messaging
export const txSent = createAction('application/txSent');
export const txMined = createAction('application/txMined');

export const toggleSidebar = createAction('application/toggleSidebar');
