import { utils } from 'ethers';

export enum ChainId {
  NOT_CONNECTED = '0x0',
  // LOCALHOST = 1337,
  POLYGON = '0x89',
}

export const CHAIN_NAMES: { [chainId in ChainId]: string } = {
  [ChainId.NOT_CONNECTED]: 'Polygon',
  // [ChainId.LOCALHOST]: 'Localhost Testnet',
  [ChainId.POLYGON]: 'Polygon',
};

export const CHAIN_TOKEN_NAMES: { [chainId in ChainId]: string } = {
  [ChainId.NOT_CONNECTED]: 'POL',
  // [ChainId.LOCALHOST]: 'tETH',
  [ChainId.POLYGON]: 'POL',
};

export const GRAPH_URL: { [chainId in ChainId]: string } = {
  [ChainId.NOT_CONNECTED]:
    'https://api.studio.thegraph.com/query/20479/smartfunds/version/latest',
  // [ChainId.LOCALHOST]:
  //   'https://api.studio.thegraph.com/query/20479/smartfunds/version/latest',
  [ChainId.POLYGON]:
    'https://api.studio.thegraph.com/query/20479/smartfunds/version/latest',
};

export const USDC_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.NOT_CONNECTED]: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  // [ChainId.LOCALHOST]: constants.AddressZero,
  [ChainId.POLYGON]: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
};

export const PERMIT_DOMAIN: {
  [chainId in ChainId]: {
    name: string;
    version: string;
    chainId?: string;
    salt?: Uint8Array;
    verifyingContract: string;
  };
} = {
  [ChainId.NOT_CONNECTED]: {
    name: 'USD Coin (PoS)',
    version: '1',
    salt: utils.zeroPad(utils.arrayify(utils.hexValue(ChainId.POLYGON)), 32),
    verifyingContract: USDC_ADDRESS[ChainId.POLYGON],
  },
  // [ChainId.LOCALHOST]: {
  //   name: 'USD Coin',
  //   version: '1',
  //   chainId: ChainId.LOCALHOST,
  //   verifyingContract: USDC_ADDRESS[ChainId.LOCALHOST],
  // },
  [ChainId.POLYGON]: {
    name: 'USD Coin (PoS)',
    version: '1',
    salt: utils.zeroPad(utils.arrayify(utils.hexValue(ChainId.POLYGON)), 32),
    verifyingContract: USDC_ADDRESS[ChainId.POLYGON],
  },
};

export const REGISTRY_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.NOT_CONNECTED]: '0x9a7317071fab80ab63ED97128705F81B185BAB37',
  // [ChainId.LOCALHOST]: constants.AddressZero,
  [ChainId.POLYGON]: '0x9a7317071fab80ab63ED97128705F81B185BAB37',
};
