import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { Experimental_CssVarsProvider as ExperimentalCssVarsProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import App from './pages/App';
import store from './state';
import { theme } from './theme';
import ApolloClientWrapper from './apollo/ApolloClientWrapper';
import reportWebVitals from './reportWebVitals';

render(
  <StrictMode>
    <ExperimentalCssVarsProvider
      theme={theme}
      defaultMode='system'
      enableColorScheme
    >
      <CssBaseline enableColorScheme />
      <Provider store={store}>
        <ApolloClientWrapper>
          <HashRouter>
            <App />
          </HashRouter>
        </ApolloClientWrapper>
      </Provider>
    </ExperimentalCssVarsProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
