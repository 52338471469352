import { useCallback, useMemo } from 'react';
import { constants } from 'ethers';

import { useActiveWeb3 } from '../hooks/wallet';
import { REGISTRY_ADDRESS, USDC_ADDRESS } from '../constants';
import {
  TestUSDCoin__factory,
  Registry__factory,
  Fund__factory,
} from '../contracts/generated';

// Contract creation hooks
export const useRegistryContract = () => {
  const { provider, chainId } = useActiveWeb3();
  return useMemo(() => {
    return Registry__factory.connect(
      REGISTRY_ADDRESS[chainId] || constants.AddressZero,
      provider.getSigner(),
    );
  }, [provider, chainId]);
};

export const useUSDCTestTokenContract = () => {
  const { provider, chainId } = useActiveWeb3();
  return useMemo(() => {
    return TestUSDCoin__factory.connect(
      USDC_ADDRESS[chainId] || constants.AddressZero,
      provider.getSigner(),
    );
  }, [provider, chainId]);
};

export function useFundContract(fundAddress: string) {
  const { provider } = useActiveWeb3();
  return useMemo(() => {
    return Fund__factory.connect(
      fundAddress || constants.AddressZero,
      provider.getSigner(),
    );
  }, [fundAddress, provider]);
}

export function useFundContractGenerator() {
  const { provider } = useActiveWeb3();
  return useCallback(
    (fundAddress: string) => {
      return Fund__factory.connect(
        fundAddress || constants.AddressZero,
        provider.getSigner(),
      );
    },
    [provider],
  );
}
