import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { providers, utils } from 'ethers';
import { SiweMessage } from 'siwe';
import toast from 'react-hot-toast';

import { onboardApi } from '../hooks/wallet';
import { IpfsAsset, IpfsAssetData } from './assets/history';
import { ipfsDataSchema } from '../utils';
import { ChainId } from '../constants';

interface SessionData {
  nonce?: string;
  address?: string;
  chainId?: number;
  ipnsId?: string;
}

interface Wallet {
  name: string;
  address: string;
  chain: string;
}

export interface IpfsWalletData {
  schema: 1;
  wallets: { [address: string]: Wallet };
}

export interface IpfsInvestorDetails {
  schema: 1;
  investors: { [address: string]: { name: string } };
}

interface IpfsFundDetails {
  schema: 1;
  fund: string;
  description: string;
}

interface IpfsDataRoomFileListItem {
  name: string;
  type: 'directory' | 'file';
  size: number;
  cid: string;
}

interface IpfsPrivateAcl {
  [keyName: string]: {
    c?: boolean;
    r?: boolean;
    u?: boolean;
    d?: boolean;
  };
}

function getBackendKeyName() {
  const wallet = onboardApi.state.get().wallets[0];
  if (!wallet) {
    throw new Error('Wallet not connected');
  }
  const address = utils.getAddress(wallet.accounts[0].address);
  const chainId = parseInt(wallet.chains[0].id);
  return `${address}-${chainId}`;
}

export const backendApi = createApi({
  reducerPath: 'backendApi',
  tagTypes: [
    'Me',
    'Wallets',
    'InvestorDetails',
    'FundDetails',
    'DataRoomFileList',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
    credentials: 'include',
  }),
  endpoints: (build) => ({
    login: build.mutation<SessionData, void>({
      queryFn: async (args, queryApi, extraOptions, baseQuery) => {
        const id = toast.loading('Connecting to backend...');
        try {
          let me = (await baseQuery({
            url: 'nonce',
            method: 'post',
          })) as QueryReturnValue<
            SessionData,
            FetchBaseQueryError,
            FetchBaseQueryMeta
          >;
          if (me.error) {
            throw me.error;
          }
          const wallet = onboardApi.state.get().wallets[0];
          if (!wallet) {
            throw new Error('Wallet not connected');
          }
          const address = utils.getAddress(wallet.accounts[0].address);
          const chainId = parseInt(wallet.chains[0].id);
          // do nothing if already logged in with the right address and chainId
          if (
            address.toLowerCase() !== me.data.address?.toLowerCase() ||
            chainId !== me.data.chainId
          ) {
            if (me.data.address) {
              // logout and get new nonce if already logged in
              await baseQuery({ url: 'logout', method: 'post' });
              me = (await baseQuery({
                url: 'nonce',
                method: 'post',
              })) as QueryReturnValue<
                SessionData,
                FetchBaseQueryError,
                FetchBaseQueryMeta
              >;
              if (me.error) {
                throw me.error;
              }
            }
            if (!me.data.nonce) {
              throw new Error('Failed to get nonce');
            }
            // generate message with nonce
            const expiration = new Date();
            expiration.setDate(expiration.getDate() + 1);
            const message = new SiweMessage({
              domain: window.location.host,
              address,
              uri: window.location.origin,
              version: '1',
              chainId: chainId,
              nonce: me.data.nonce,
              expirationTime: expiration.toISOString(),
            });
            // sign message in connected wallet
            const signer = new providers.Web3Provider(
              wallet.provider,
            ).getSigner();
            const signaturePromise = signer.signMessage(
              message.prepareMessage(),
            );
            toast('Sign message in wallet to connect to IPFS...', { id });
            let signature: string;
            try {
              signature = await signaturePromise;
            } catch (err) {
              throw new Error('Signing message failed');
            }
            toast.loading('Connecting to IPFS...', { id });
            // login to backend
            me = (await baseQuery({
              url: 'login',
              method: 'post',
              body: { message, signature },
            })) as QueryReturnValue<
              SessionData,
              FetchBaseQueryError,
              FetchBaseQueryMeta
            >;
            if (me.error) {
              throw me.error;
            }
          }
          toast.success('Connected to IPFS', { id });
          return { data: me.data };
        } catch (err) {
          toast.error('Connecting to IPFS failed', { id });
          throw err;
        }
      },
      invalidatesTags: ['Me', 'Wallets', 'InvestorDetails'],
    }),
    me: build.query<SessionData, void>({
      query: () => 'me',
      providesTags: ['Me'],
    }),
    getWallets: build.query<IpfsWalletData, void>({
      query: () => `private/${getBackendKeyName()}/wallets/wallets.json`,
      providesTags: ['Wallets'],
    }),
    addWallets: build.mutation<
      IpfsWalletData,
      { address: string; name: string; chain: string }[]
    >({
      queryFn: async (wallets, queryApi, extraOptions, baseQuery) => {
        // get current wallets file
        const filePath = `private/${getBackendKeyName()}/wallets/wallets.json`;
        const walletsResp = (await baseQuery(filePath)) as QueryReturnValue<
          IpfsWalletData,
          FetchBaseQueryError,
          FetchBaseQueryMeta
        >;
        let method = 'put';
        if (walletsResp.error) {
          if (
            walletsResp.error.status === 404 &&
            (walletsResp.error.data as any)?.error === 'File Does Not Exist'
          ) {
            // post to add a new file instead of put to replace existing
            method = 'post';
          } else {
            throw walletsResp.error;
          }
        }
        let data = walletsResp.data;
        for (const walletInput of wallets) {
          const walletAddress = utils.getAddress(walletInput.address);
          const wallet = {
            address: walletAddress,
            name: walletInput.name,
            chain: walletInput.chain,
          };
          if (data) {
            if (!data.wallets) {
              data.wallets = { [walletAddress]: wallet };
            } else {
              data.wallets[walletAddress] = wallet;
            }
          } else {
            data = { schema: 1, wallets: { [walletAddress]: wallet } };
          }
        }
        if (!data) {
          data = { schema: 1, wallets: {} };
        }
        const formData = new FormData();
        const fileBlob = new Blob([JSON.stringify(data)], {
          type: 'application/json',
        });
        formData.append('uploaded_file', fileBlob);
        const updateResp = await baseQuery({
          url: filePath,
          method,
          body: formData,
        });
        if (updateResp.error) {
          throw updateResp.error;
        }
        return { data };
      },
      invalidatesTags: ['Wallets'],
    }),
    deleteWallet: build.mutation<IpfsWalletData, { address: string }>({
      queryFn: async ({ address }, queryApi, extraOptions, baseQuery) => {
        const walletAddress = utils.getAddress(address);
        // get current wallets file
        const filePath = `private/${getBackendKeyName()}/wallets/wallets.json`;
        const walletsResp = (await baseQuery(filePath)) as QueryReturnValue<
          IpfsWalletData,
          FetchBaseQueryError,
          FetchBaseQueryMeta
        >;
        if (walletsResp.error) {
          throw walletsResp.error;
        }
        let data = walletsResp.data;
        if (!data?.wallets?.[walletAddress]) {
          throw new Error('Address not found in wallets list');
        }
        delete data.wallets[walletAddress];
        const formData = new FormData();
        const fileBlob = new Blob([JSON.stringify(data)], {
          type: 'application/json',
        });
        formData.append('uploaded_file', fileBlob);
        const updateResp = await baseQuery({
          url: filePath,
          method: 'put',
          body: formData,
        });
        if (updateResp.error) {
          throw updateResp.error;
        }
        return { data };
      },
      invalidatesTags: ['Wallets'],
    }),
    getInvestorDetails: build.query<IpfsInvestorDetails, void>({
      query: () =>
        `private/${getBackendKeyName()}/investorDetails/investorDetails.json`,
      providesTags: ['InvestorDetails'],
    }),
    addInvestorDetails: build.mutation<
      IpfsInvestorDetails,
      { address: string; name: string }[]
    >({
      queryFn: async (investorDetails, queryApi, extraOptions, baseQuery) => {
        // get current wallets file
        const filePath = `private/${getBackendKeyName()}/investorDetails/investorDetails.json`;
        const detailsResp = (await baseQuery(filePath)) as QueryReturnValue<
          IpfsInvestorDetails,
          FetchBaseQueryError,
          FetchBaseQueryMeta
        >;
        let method = 'put';
        if (detailsResp.error) {
          if (
            detailsResp.error.status === 404 &&
            (detailsResp.error.data as any)?.error === 'File Does Not Exist'
          ) {
            // post to add a new file instead of put to replace existing
            method = 'post';
          } else {
            throw detailsResp.error;
          }
        }
        let details = detailsResp.data;
        for (const investorDetail of investorDetails) {
          const investorAddress = utils.getAddress(investorDetail.address);
          const name = investorDetail.name;
          if (details) {
            if (!details.investors) {
              details.investors = { [investorAddress]: { name } };
            } else if (!details.investors[investorAddress]) {
              details.investors[investorAddress] = { name };
            } else {
              details.investors[investorAddress].name = name;
            }
          } else {
            details = { schema: 1, investors: { [investorAddress]: { name } } };
          }
        }
        if (!details) {
          details = { schema: 1, investors: {} };
        }
        const formData = new FormData();
        const fileBlob = new Blob([JSON.stringify(details)], {
          type: 'application/json',
        });
        formData.append('uploaded_file', fileBlob);
        const updateResp = await baseQuery({
          url: filePath,
          method,
          body: formData,
        });
        if (updateResp.error) {
          throw updateResp.error;
        }
        return { data: details };
      },
      invalidatesTags: ['InvestorDetails'],
    }),
    getFundDetails: build.query<
      IpfsFundDetails,
      { manager: string; chainId: string; fundAddress: string }
    >({
      query: ({ manager, chainId, fundAddress }) =>
        `public/${utils.getAddress(manager)}-${parseInt(
          chainId,
        )}/${utils.getAddress(fundAddress)}/details.json`,
      providesTags: ['FundDetails'],
    }),
    editFundDetails: build.mutation<
      IpfsFundDetails,
      { fundAddress: string; description: string }
    >({
      query: ({ fundAddress, description }) => {
        const checksummedFundAddress = utils.getAddress(fundAddress);
        const details: IpfsFundDetails = {
          schema: 1,
          fund: checksummedFundAddress,
          description,
        };
        const formData = new FormData();
        const fileBlob = new Blob([JSON.stringify(details)], {
          type: 'application/json',
        });
        formData.append('uploaded_file', fileBlob);
        return {
          url: `public/${getBackendKeyName()}/${checksummedFundAddress}/details.json`,
          method: 'put',
          body: formData,
        };
      },
      invalidatesTags: ['FundDetails'],
    }),
    addAssetSnapshot: build.mutation<
      { hash: string },
      { assets: IpfsAsset[]; fundAddress: string }
    >({
      query: ({ assets, fundAddress }) => {
        const checksummedFundAddress = utils.getAddress(fundAddress);
        const timestamp = Math.floor(Date.now() / 1000);
        const assetSnapshot: IpfsAssetData = {
          schema: 1,
          fund: checksummedFundAddress,
          timestamp,
          assets,
        };
        // validate schema
        ipfsDataSchema.validateSync(assetSnapshot);
        const formData = new FormData();
        const fileBlob = new Blob([JSON.stringify(assetSnapshot)], {
          type: 'application/json',
        });
        formData.append('uploaded_file', fileBlob);
        return {
          url: `public/${getBackendKeyName()}/${checksummedFundAddress}/assetSnapshots/${timestamp}.json`,
          method: 'post',
          body: formData,
        };
      },
    }),
    addLogo: build.mutation<
      { hash: string },
      { logo: File; fundAddress: string }
    >({
      query: ({ logo, fundAddress }) => {
        const timestamp = Math.floor(Date.now() / 1000);
        const fileType = logo.type.split('/')[1];
        const formData = new FormData();
        formData.append('uploaded_file', logo);
        return {
          url: `public/${getBackendKeyName()}/${utils.getAddress(
            fundAddress,
          )}/logos/${timestamp}.${fileType}`,
          method: 'post',
          body: formData,
        };
      },
    }),
    getDataRoomFileList: build.query<
      IpfsDataRoomFileListItem[],
      { manager: string; chainId: string; fundAddress: string }
    >({
      query: ({ manager, chainId, fundAddress }) =>
        `private/${utils.getAddress(manager)}-${parseInt(
          chainId,
        )}/${utils.getAddress(fundAddress)}/`,
      providesTags: ['DataRoomFileList'],
    }),
    addDataRoomFile: build.mutation<
      void,
      { file: File; fundAddress: string; investors: string[]; chainId: ChainId }
    >({
      queryFn: async (
        { file, fundAddress, investors, chainId },
        queryApi,
        extraOptions,
        baseQuery,
      ) => {
        await queryApi.dispatch(
          backendApi.endpoints.addInvestorsToDataRoomAcl.initiate({
            fundAddress,
            investors,
            chainId,
          }),
        );
        const formData = new FormData();
        formData.append('uploaded_file', file);
        const resp = await baseQuery({
          url: `private/${getBackendKeyName()}/${utils.getAddress(
            fundAddress,
          )}/${file.name}`,
          method: 'post',
          body: formData,
        });
        if (resp.error) {
          throw resp.error;
        }
        return { data: undefined };
      },
      invalidatesTags: ['DataRoomFileList'],
    }),
    deleteDataRoomFile: build.mutation<
      { status: 'Deleted' },
      { fileName: string; fundAddress: string }
    >({
      query: ({ fileName, fundAddress }) => ({
        url: `private/${getBackendKeyName()}/${utils.getAddress(
          fundAddress,
        )}/${fileName}`,
        method: 'delete',
      }),
      invalidatesTags: ['DataRoomFileList'],
    }),
    addInvestorsToDataRoomAcl: build.mutation<
      IpfsPrivateAcl,
      { investors: string[]; chainId: ChainId; fundAddress: string }
    >({
      queryFn: async (
        { investors, chainId, fundAddress },
        queryApi,
        extraOptions,
        baseQuery,
      ) => {
        const aclPath = `private/${getBackendKeyName()}/${utils.getAddress(
          fundAddress,
        )}.acl.json`;
        const aclResp = (await baseQuery(aclPath)) as QueryReturnValue<
          IpfsPrivateAcl,
          FetchBaseQueryError,
          FetchBaseQueryMeta
        >;
        if (aclResp.error) {
          if (
            aclResp.error.status !== 404 ||
            (aclResp.error.data as any)?.error !== 'File Does Not Exist'
          ) {
            throw aclResp.error;
          }
        }
        let acl = aclResp.data;
        for (const investor of investors) {
          const investorKey = `${utils.getAddress(investor)}-${parseInt(
            chainId,
          )}`;
          if (acl) {
            if (!acl[investorKey]) {
              acl[investorKey] = { r: true };
            } else {
              acl[investorKey].r = true;
            }
          } else {
            acl = { [investorKey]: { r: true } };
          }
        }
        if (!acl) {
          acl = {};
        }
        const formData = new FormData();
        const fileBlob = new Blob([JSON.stringify(acl)], {
          type: 'application/json',
        });
        formData.append('uploaded_file', fileBlob);
        const updateResp = await baseQuery({
          url: aclPath,
          method: 'put',
          body: formData,
        });
        if (updateResp.error) {
          throw updateResp.error;
        }
        return { data: acl };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useMeQuery,
  useGetWalletsQuery,
  useAddWalletsMutation,
  useDeleteWalletMutation,
  useGetInvestorDetailsQuery,
  useAddInvestorDetailsMutation,
  useAddAssetSnapshotMutation,
  useGetFundDetailsQuery,
  useEditFundDetailsMutation,
  useAddLogoMutation,
  useGetDataRoomFileListQuery,
  useAddDataRoomFileMutation,
  useDeleteDataRoomFileMutation,
  useAddInvestorsToDataRoomAclMutation,
} = backendApi;
