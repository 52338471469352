import { createAction } from '@reduxjs/toolkit';

import { OnboardingData } from './reducer';

export const updateState = createAction<{
  data: Partial<OnboardingData>;
}>('onboarding/updateState');
export const resetState = createAction('onboarding/resetState');
export const nextStep = createAction('onboarding/nextStep');
export const previousStep = createAction('onboarding/previousStep');
