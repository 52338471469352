import { init, useWallets } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import trezorModule from '@web3-onboard/trezor';
import ledgerModule from '@web3-onboard/ledger';
import walletConnectModule from '@web3-onboard/walletconnect';
import coinbaseWalletModule from '@web3-onboard/coinbase';
// import portisModule from '@web3-onboard/portis';
// import fortmaticModule from '@web3-onboard/fortmatic';
import keepkeyModule from '@web3-onboard/keepkey';
// import mewModule from '@web3-onboard/mew';
import gnosisModule from '@web3-onboard/gnosis';
import keystoneModule from '@web3-onboard/keystone';
// import magicModule from '@web3-onboard/magic';
import dcentModule from '@web3-onboard/dcent';
import { providers, utils } from 'ethers';
import { useMemo } from 'react';

import { ChainId } from '../constants';
import logo from '../assets/img/logo-160.png';
import { useAppSelector } from '../state';

const injected = injectedModule();
const coinbaseWallet = coinbaseWalletModule();
const walletConnect = walletConnectModule();
// const portis = portisModule({
//   apiKey: 'b2b7586f-2b1e-4c30-a7fb-c2d1533b153b',
// });
// const fortmatic = fortmaticModule({
//   apiKey: 'pk_test_886ADCAB855632AA',
// });
const ledger = ledgerModule();
const keepkey = keepkeyModule();
const trezor = trezorModule({
  email: 'info@smartmoney.finance',
  appUrl: 'https://smartfunds.xyz',
});
// const mew = mewModule();
const gnosis = gnosisModule();
const keystone = keystoneModule();
// const magic = magicModule({ apiKey: 'API_KEY' });
const dcent = dcentModule();

export const onboardApi = init({
  wallets: [
    injected,
    coinbaseWallet,
    walletConnect,
    gnosis,
    ledger,
    trezor,
    keepkey,
    // mew,
    keystone,
    dcent,
    // portis,
    // fortmatic,
    // magic,
  ],
  chains: [
    {
      id: '0x89',
      token: 'MATIC',
      label: 'Polygon Mainnet',
      rpcUrl: `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    },
  ],
  appMetadata: {
    name: 'SmartFunds',
    icon: logo,
    description: 'Web3 Native Funds',
    recommendedInjectedWallets: [
      { name: 'MetaMask', url: 'https://metamask.io' },
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
    ],
  },
  accountCenter: {
    // @ts-ignore
    desktop: { enabled: false },
    // @ts-ignore
    mobile: { enabled: false },
  },
  notify: { enabled: false },
});

const defaultProvider = new providers.JsonRpcProvider(
  `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  parseInt(ChainId.POLYGON),
);

function useProvider() {
  const wallets = useWallets();
  const provider = wallets[0]?.provider;
  const chainId = (wallets[0]?.chains[0].id || '0x0') as ChainId;
  return useMemo(() => {
    if (provider && chainId) {
      return new providers.Web3Provider(provider);
    } else {
      return defaultProvider;
    }
  }, [provider, chainId]);
}

export function useActiveWeb3() {
  const provider = useProvider();
  const wallets = useWallets();
  const balance = useAppSelector((state) => state.application.ethBalance);
  // depending on the underlying package, web3-onboard will return lowercase or checksum cased address, so this normalizes it to checksum case
  const address = wallets[0]?.accounts[0].address
    ? utils.getAddress(wallets[0]?.accounts[0].address)
    : '';
  // to normalize chainId as hex in case the package changes the return type
  const chainId = `0x${parseInt(wallets[0]?.chains[0].id || '0x0').toString(
    16,
  )}` as ChainId;
  return { address, chainId, balance, provider };
}
