import { createApi } from '@reduxjs/toolkit/query/react';

const BASE_URL = 'https://api.zapper.xyz';
const API_KEY = process.env.REACT_APP_ZAPPER_KEY;
const fetchOpts: RequestInit = {
  credentials: 'include',
  headers: {
    Authorization: `Basic ${Buffer.from(`${API_KEY}:`, 'binary').toString(
      'base64',
    )}`,
  },
};

interface ZapperBalancesAppResponse {
  address: string;
  appId: string;
  appImage: string;
  network: string;
  products: ZapperProduct[];
}

interface ZapperBalancesTokenResponse {
  [address: string]: {
    address: string;
    network: string;
    token: {
      address: string;
      balanceRaw: string;
      decimals: number;
      symbol: string;
      price: number;
      balanceUSD?: number;
    };
  }[];
}

interface ZapperProtocol {
  img?: string;
  appId: string;
  balances: ZapperBalances;
  network: string;
}

interface ZapperMeta {
  value: number;
}
interface ZapperProduct {
  assets: ZapperAsset[];
}
interface ZapperAsset {
  address: string;
  hide?: boolean;
  symbol: string;
  price: number;
  type: string;
  decimals?: number;
  balanceRaw: string;
  balanceUSD?: number;
  tokens?: ZapperToken[];
}
interface ZapperToken {
  address: string;
  balanceRaw: string;
  decimals: number;
  symbol: string;
  price: number;
  type: string;
  balanceUSD?: number;
}

interface ZapperBalance {
  meta?: ZapperMeta[];
  products: ZapperProduct[];
}

interface ZapperBalances {
  balances: {
    [address: string]: ZapperBalance;
  };
}

async function getWalletInfo(addresses: string[]): Promise<ZapperProtocol[]> {
  try {
    const addressesStr = addresses
      .reduce(
        (str, address) =>
          str.concat(`&addresses%5B%5D=${address.toLowerCase()}`),
        '',
      )
      .substring(1);
    const balancesAppsURL = `${BASE_URL}/v2/balances/apps?${addressesStr}`;
    const balancesAppsResponse: ZapperBalancesAppResponse[] = await fetch(
      balancesAppsURL,
      fetchOpts,
    ).then((res) => res.json());
    const formattedResponse = balancesAppsResponse.map((app) => ({
      img: app.appImage,
      network: app.network,
      appId: app.appId,
      balances: { balances: { [app.address]: { products: app.products } } },
    }));
    const balancesTokensURL = `${BASE_URL}/v2/balances/tokens?${addressesStr}`;
    const balancesTokensResponse: ZapperBalancesTokenResponse = await fetch(
      balancesTokensURL,
      fetchOpts,
    ).then((res) => res.json());
    const formattedResponse2 = Object.values(balancesTokensResponse)
      .flat()
      .map((token) => ({
        img: `https://storage.googleapis.com/zapper-fi-assets/tokens/${token.network}/${token.token.address}.png`,
        network: token.network,
        appId: 'tokens',
        balances: {
          balances: {
            [token.address]: {
              products: [
                {
                  assets: [
                    {
                      address: token.token.address,
                      symbol: token.token.symbol,
                      price: token.token.price,
                      type: 'base-token',
                      decimals: token.token.decimals,
                      balanceRaw: token.token.balanceRaw,
                      balanceUSD: token.token.balanceUSD,
                    },
                  ],
                },
              ],
            },
          },
        },
      }));
    return formattedResponse.concat(formattedResponse2);
  } catch (err) {
    console.log(err);
    return [];
  }
}

export const zapperAssetsApi = createApi({
  reducerPath: 'assets-zapper',
  baseQuery: async (addresses) => {
    if (!addresses || addresses?.length === 0) {
      return { error: 'No addresses provided' };
    }
    const data = await getWalletInfo(addresses);
    return { data };
  },
  endpoints: (build) => ({
    getZapperAssets: build.query<ZapperProtocol[], string[]>({
      query: (addresses) => addresses,
    }),
  }),
  refetchOnMountOrArgChange: true,
});

// const getZapperTxns = async (addresses: string[]): Promise<any> => {
//   try {
//     const addressesStr = addresses
//       .reduce(
//         (str, address) =>
//           str.concat(`&addresses%5B%5D=${address.toLowerCase()}`),
//         '',
//       )
//       .substring(1);
//     const supportedProtocolURL = `${BASE_URL}/v2/transactions?${addressesStr}`;
//     const txns = await fetch(supportedProtocolURL, {
//       credentials: 'include',
//       headers: {
//         Authorization: `Basic ${Buffer.from(API_KEY + ':' || ':').toString(
//           'base64',
//         )}`,
//       },
//     }).then((res) => res.json());
//     return txns;
//   } catch (err) {
//     console.log(err);
//     return undefined;
//   }
// };

// export const zapperTxnApi = createApi({
//   reducerPath: 'txns-zapper',
//   baseQuery: async (addresses) => {
//     if (!addresses) {
//       return { error: 'No addresses provided' };
//     }
//     const data = await getZapperTxns(addresses);
//     return { data };
//   },
//   endpoints: (build) => ({
//     getZapperTxns: build.query<TxnHistoryResponse | undefined, string[]>({
//       query: (addresses) => addresses,
//     }),
//   }),
//   refetchOnMountOrArgChange: true,
// });

const { useGetZapperAssetsQuery } = zapperAssetsApi;
// const { useGetZapperTxnsQuery } = zapperTxnApi;
export { useGetZapperAssetsQuery };
// export { useGetZapperAssetsQuery, useGetZapperTxnsQuery };
