import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
// import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useColorScheme } from '@mui/material/styles';

import { mobileBreakpoint, sidebarWidth } from '../theme';
import { useRefreshTimestamp } from '../state/application/hooks';
// import { useTestFaucet } from '../hooks/transactions';
import { useFundByManagerQuery, useInvestorQuery } from '../apollo/generated';
// import { ChainId, CHAIN_TOKEN_NAMES } from '../constants';
import { useActiveWeb3 } from '../hooks/wallet';
import { useAppDispatch, useAppSelector } from '../state';
import { toggleSidebar } from '../state/application/actions';
import { useInterval, useIsDesktop } from '../hooks/misc';

function RefreshListItem() {
  const [secSinceRefresh, setSecSinceRefresh] = useState(0);
  const refreshTimestamp = useRefreshTimestamp();
  useInterval(() => {
    if (refreshTimestamp) {
      setSecSinceRefresh(
        Math.floor((new Date().getTime() - refreshTimestamp) / 1000),
      );
    } else {
      setSecSinceRefresh(0);
    }
  }, 1000);

  return (
    <ListItem disabled>
      <ListItemText
        primary={`Updated ${
          secSinceRefresh > 0 ? `${secSinceRefresh}s ago` : '-'
        }`}
      />
    </ListItem>
  );
}

function NavLinkListItem({
  children,
  to,
  end,
}: {
  children: ReactNode;
  to: string;
  end: boolean;
}) {
  return (
    <NavLink
      to={to}
      end={end}
      style={{
        textDecoration: 'inherit',
        color: 'inherit',
      }}
    >
      {({ isActive }) => (
        <ListItemButton selected={isActive}>{children}</ListItemButton>
      )}
    </NavLink>
  );
}

export default function Sidebar() {
  const { mode, systemMode, setMode } = useColorScheme();
  const isDesktop = useIsDesktop();
  const dispatch = useAppDispatch();
  const sidebarOpen = useAppSelector((state) => state.application.sidebarOpen);

  // const faucet = useTestFaucet();
  const { address } = useActiveWeb3();
  const { data } = useFundByManagerQuery({
    variables: { manager: address.toLowerCase() },
    skip: !address,
  });
  const { data: investorData } = useInvestorQuery({
    variables: { investor: address.toLowerCase() },
    skip: !address,
  });

  const myMode = mode === 'system' ? systemMode : mode;

  const myFundId = data?.funds?.[0]?.id;
  // const isTestnet = chainId === ChainId.RINKEBY || chainId === ChainId.GOERLI;
  const isWhitelisted = !!investorData?.investor?.fundsWhitelisted.length;

  // TODO: move the constant arrays outside of render?
  const investorLinks = [
    // ...(isTestnet
    //   ? [{ icon: ListOutlinedIcon, text: 'Explore Funds', path: '/funds' }]
    //   : []),
    ...(isWhitelisted
      ? [
          {
            icon: AutoGraphOutlinedIcon,
            text: 'My Portfolio',
            path: '/portfolio',
          },
        ]
      : []),
  ];
  const managerLinks = myFundId
    ? [
        {
          icon: DashboardOutlinedIcon,
          text: 'Dashboard',
          path: `/funds/${myFundId}`,
        },
        {
          icon: PieChartOutlinedIcon,
          text: 'Assets',
          path: `/manage/${myFundId}/assets`,
        },
        {
          icon: SellOutlinedIcon,
          text: 'Contributions',
          path: `/manage/${myFundId}/contributions`,
        },
        {
          icon: GroupsOutlinedIcon,
          text: 'Investors',
          path: `/manage/${myFundId}/investors`,
        },
        {
          icon: PercentOutlinedIcon,
          text: 'Fees',
          path: `/manage/${myFundId}/fees`,
        },
        {
          icon: AccountBalanceWalletOutlinedIcon,
          text: 'Wallets',
          path: `/manage/${myFundId}/wallets`,
        },
        {
          icon: PictureAsPdfOutlinedIcon,
          text: 'Data Room',
          path: `/manage/${myFundId}/dataroom`,
        },
        {
          icon: SettingsOutlinedIcon,
          text: 'Details',
          path: `/manage/${myFundId}/details`,
        },
      ]
    : [
        {
          icon: AddBusinessOutlinedIcon,
          text: 'Start My Fund',
          path: '/manage',
        },
      ];

  return (
    <Box
      component='nav'
      sx={{
        width: { [mobileBreakpoint]: sidebarWidth },
        flexShrink: { [mobileBreakpoint]: 0 },
      }}
    >
      <Drawer
        variant={isDesktop ? 'permanent' : 'temporary'}
        open={sidebarOpen}
        onClose={() => dispatch(toggleSidebar())}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: sidebarWidth,
            boxShadow: '5px 0px 15px rgba(0, 0, 0, 0.1)',
            borderRadius: 0,
            borderRight: 0,
          },
        }}
      >
        <Toolbar />
        {investorLinks.length > 0 && (
          <>
            <List sx={{ mt: 2 }}>
              {investorLinks.map((link, idx) => (
                <NavLinkListItem key={idx} to={link.path} end>
                  <ListItemIcon sx={{ minWidth: 44 }}>
                    <link.icon />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                    {link.text}
                  </ListItemText>
                </NavLinkListItem>
              ))}
            </List>
          </>
        )}
        <List
          subheader={
            <ListSubheader sx={{ backgroundColor: 'unset' }}>
              Fund Admin
            </ListSubheader>
          }
        >
          {managerLinks.map((link, idx) => (
            <NavLinkListItem key={idx} to={link.path} end>
              <ListItemIcon sx={{ minWidth: 44 }}>
                <link.icon />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {link.text}
              </ListItemText>
            </NavLinkListItem>
          ))}
        </List>
        <Box flexGrow={1} />
        <List dense>
          <Link href='https://discord.gg/pSZGKX4Bs4' target='_blank'>
            <ListItemButton>
              <ListItemText primary='Discord' />
            </ListItemButton>
          </Link>
          <Link href='https://docs.smartfunds.xyz' target='_blank'>
            <ListItemButton>
              <ListItemText primary='Docs' />
            </ListItemButton>
          </Link>
          <Link href='https://www.smartfunds.xyz/#contact' target='_blank'>
            <ListItemButton>
              <ListItemText primary='Contact Us' />
            </ListItemButton>
          </Link>
          {/*
          {isTestnet && (
            <>
              <Link
                href={
                  chainId === ChainId.GOERLI
                    ? 'https://fauceth.komputing.org/?chain=5'
                    : 'https://faucet.rinkeby.io'
                }
                target='_blank'
                rel='noopener noreferrer'
              >
                <ListItemButton>
                  <ListItemText
                    primary={`${CHAIN_TOKEN_NAMES[chainId]} Faucet`}
                  />
                </ListItemButton>
              </Link>
              <ListItemButton onClick={faucet}>
                <ListItemText primary='Test USDC Faucet' />
              </ListItemButton>
            </>
          )}
          */}
          <ListItemButton
            onClick={() => setMode(myMode === 'light' ? 'dark' : 'light')}
          >
            <ListItemText primary='Toggle dark mode' />
          </ListItemButton>
          <ListItem disabled>
            <ListItemText
              primary={`Version ${
                (process.env.REACT_APP_BUILD_VERSION || '').split('-')[1]
              }`}
            />
          </ListItem>
          <RefreshListItem />
        </List>
      </Drawer>
    </Box>
  );
}
