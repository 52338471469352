import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  /**
   * 8 bytes signed integer
   *
   */
  Int8: any;
  /**
   * A string representation of microseconds UNIX timestamp (16 digits)
   *
   */
  Timestamp: any;
};




export enum Aggregation_Interval {
  Hour = 'hour',
  Day = 'day'
}



export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: Maybe<Scalars['Bytes']>;
  number?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
};


export type FeeSweep = {
  __typename?: 'FeeSweep';
  id: Scalars['ID'];
  fund: Fund;
  feeSweepId: Scalars['BigInt'];
  investment?: Maybe<Investment>;
  highWaterMark: Scalars['BigInt'];
  usdManagementFee: Scalars['BigInt'];
  usdPerformanceFee: Scalars['BigInt'];
  fundAmountManagementFee: Scalars['BigInt'];
  fundAmountPerformanceFee: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
};

export type FeeSweep_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  fund?: Maybe<Scalars['String']>;
  fund_not?: Maybe<Scalars['String']>;
  fund_gt?: Maybe<Scalars['String']>;
  fund_lt?: Maybe<Scalars['String']>;
  fund_gte?: Maybe<Scalars['String']>;
  fund_lte?: Maybe<Scalars['String']>;
  fund_in?: Maybe<Array<Scalars['String']>>;
  fund_not_in?: Maybe<Array<Scalars['String']>>;
  fund_contains?: Maybe<Scalars['String']>;
  fund_contains_nocase?: Maybe<Scalars['String']>;
  fund_not_contains?: Maybe<Scalars['String']>;
  fund_not_contains_nocase?: Maybe<Scalars['String']>;
  fund_starts_with?: Maybe<Scalars['String']>;
  fund_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_not_starts_with?: Maybe<Scalars['String']>;
  fund_not_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_ends_with?: Maybe<Scalars['String']>;
  fund_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_not_ends_with?: Maybe<Scalars['String']>;
  fund_not_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_?: Maybe<Fund_Filter>;
  feeSweepId?: Maybe<Scalars['BigInt']>;
  feeSweepId_not?: Maybe<Scalars['BigInt']>;
  feeSweepId_gt?: Maybe<Scalars['BigInt']>;
  feeSweepId_lt?: Maybe<Scalars['BigInt']>;
  feeSweepId_gte?: Maybe<Scalars['BigInt']>;
  feeSweepId_lte?: Maybe<Scalars['BigInt']>;
  feeSweepId_in?: Maybe<Array<Scalars['BigInt']>>;
  feeSweepId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  investment?: Maybe<Scalars['String']>;
  investment_not?: Maybe<Scalars['String']>;
  investment_gt?: Maybe<Scalars['String']>;
  investment_lt?: Maybe<Scalars['String']>;
  investment_gte?: Maybe<Scalars['String']>;
  investment_lte?: Maybe<Scalars['String']>;
  investment_in?: Maybe<Array<Scalars['String']>>;
  investment_not_in?: Maybe<Array<Scalars['String']>>;
  investment_contains?: Maybe<Scalars['String']>;
  investment_contains_nocase?: Maybe<Scalars['String']>;
  investment_not_contains?: Maybe<Scalars['String']>;
  investment_not_contains_nocase?: Maybe<Scalars['String']>;
  investment_starts_with?: Maybe<Scalars['String']>;
  investment_starts_with_nocase?: Maybe<Scalars['String']>;
  investment_not_starts_with?: Maybe<Scalars['String']>;
  investment_not_starts_with_nocase?: Maybe<Scalars['String']>;
  investment_ends_with?: Maybe<Scalars['String']>;
  investment_ends_with_nocase?: Maybe<Scalars['String']>;
  investment_not_ends_with?: Maybe<Scalars['String']>;
  investment_not_ends_with_nocase?: Maybe<Scalars['String']>;
  investment_?: Maybe<Investment_Filter>;
  highWaterMark?: Maybe<Scalars['BigInt']>;
  highWaterMark_not?: Maybe<Scalars['BigInt']>;
  highWaterMark_gt?: Maybe<Scalars['BigInt']>;
  highWaterMark_lt?: Maybe<Scalars['BigInt']>;
  highWaterMark_gte?: Maybe<Scalars['BigInt']>;
  highWaterMark_lte?: Maybe<Scalars['BigInt']>;
  highWaterMark_in?: Maybe<Array<Scalars['BigInt']>>;
  highWaterMark_not_in?: Maybe<Array<Scalars['BigInt']>>;
  usdManagementFee?: Maybe<Scalars['BigInt']>;
  usdManagementFee_not?: Maybe<Scalars['BigInt']>;
  usdManagementFee_gt?: Maybe<Scalars['BigInt']>;
  usdManagementFee_lt?: Maybe<Scalars['BigInt']>;
  usdManagementFee_gte?: Maybe<Scalars['BigInt']>;
  usdManagementFee_lte?: Maybe<Scalars['BigInt']>;
  usdManagementFee_in?: Maybe<Array<Scalars['BigInt']>>;
  usdManagementFee_not_in?: Maybe<Array<Scalars['BigInt']>>;
  usdPerformanceFee?: Maybe<Scalars['BigInt']>;
  usdPerformanceFee_not?: Maybe<Scalars['BigInt']>;
  usdPerformanceFee_gt?: Maybe<Scalars['BigInt']>;
  usdPerformanceFee_lt?: Maybe<Scalars['BigInt']>;
  usdPerformanceFee_gte?: Maybe<Scalars['BigInt']>;
  usdPerformanceFee_lte?: Maybe<Scalars['BigInt']>;
  usdPerformanceFee_in?: Maybe<Array<Scalars['BigInt']>>;
  usdPerformanceFee_not_in?: Maybe<Array<Scalars['BigInt']>>;
  fundAmountManagementFee?: Maybe<Scalars['BigInt']>;
  fundAmountManagementFee_not?: Maybe<Scalars['BigInt']>;
  fundAmountManagementFee_gt?: Maybe<Scalars['BigInt']>;
  fundAmountManagementFee_lt?: Maybe<Scalars['BigInt']>;
  fundAmountManagementFee_gte?: Maybe<Scalars['BigInt']>;
  fundAmountManagementFee_lte?: Maybe<Scalars['BigInt']>;
  fundAmountManagementFee_in?: Maybe<Array<Scalars['BigInt']>>;
  fundAmountManagementFee_not_in?: Maybe<Array<Scalars['BigInt']>>;
  fundAmountPerformanceFee?: Maybe<Scalars['BigInt']>;
  fundAmountPerformanceFee_not?: Maybe<Scalars['BigInt']>;
  fundAmountPerformanceFee_gt?: Maybe<Scalars['BigInt']>;
  fundAmountPerformanceFee_lt?: Maybe<Scalars['BigInt']>;
  fundAmountPerformanceFee_gte?: Maybe<Scalars['BigInt']>;
  fundAmountPerformanceFee_lte?: Maybe<Scalars['BigInt']>;
  fundAmountPerformanceFee_in?: Maybe<Array<Scalars['BigInt']>>;
  fundAmountPerformanceFee_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<FeeSweep_Filter>>>;
  or?: Maybe<Array<Maybe<FeeSweep_Filter>>>;
};

export enum FeeSweep_OrderBy {
  Id = 'id',
  Fund = 'fund',
  FundId = 'fund__id',
  FundName = 'fund__name',
  FundSymbol = 'fund__symbol',
  FundManager = 'fund__manager',
  FundFeeBeneficiary = 'fund__feeBeneficiary',
  FundTimelock = 'fund__timelock',
  FundFeeSweepInterval = 'fund__feeSweepInterval',
  FundManagementFee = 'fund__managementFee',
  FundPerformanceFee = 'fund__performanceFee',
  FundMaxInvestors = 'fund__maxInvestors',
  FundMaxInvestmentsPerInvestor = 'fund__maxInvestmentsPerInvestor',
  FundMinInvestmentAmount = 'fund__minInvestmentAmount',
  FundInitialPrice = 'fund__initialPrice',
  FundLogoUrl = 'fund__logoUrl',
  FundContactInfo = 'fund__contactInfo',
  FundTags = 'fund__tags',
  FundUsingUsdToken = 'fund__usingUsdToken',
  FundDoneImportingInvestments = 'fund__doneImportingInvestments',
  FundLastFeeSweepEndedTimestamp = 'fund__lastFeeSweepEndedTimestamp',
  FundFeeSweeping = 'fund__feeSweeping',
  FundFeesSweptNotWithdrawn = 'fund__feesSweptNotWithdrawn',
  FundClosed = 'fund__closed',
  FundTimestamp = 'fund__timestamp',
  FeeSweepId = 'feeSweepId',
  Investment = 'investment',
  InvestmentId = 'investment__id',
  InvestmentInvestmentId = 'investment__investmentId',
  InvestmentTimestamp = 'investment__timestamp',
  InvestmentLockupTimestamp = 'investment__lockupTimestamp',
  InvestmentInitialUsdAmount = 'investment__initialUsdAmount',
  InvestmentInitialFundAmount = 'investment__initialFundAmount',
  InvestmentInitialHighWaterMark = 'investment__initialHighWaterMark',
  InvestmentInitialLastFeeSweepTimestamp = 'investment__initialLastFeeSweepTimestamp',
  InvestmentManagementFeeCostBasis = 'investment__managementFeeCostBasis',
  InvestmentUsdTransferred = 'investment__usdTransferred',
  InvestmentImported = 'investment__imported',
  InvestmentRemainingFundAmount = 'investment__remainingFundAmount',
  InvestmentUsdManagementFeesSwept = 'investment__usdManagementFeesSwept',
  InvestmentUsdPerformanceFeesSwept = 'investment__usdPerformanceFeesSwept',
  InvestmentFundManagementFeesSwept = 'investment__fundManagementFeesSwept',
  InvestmentFundPerformanceFeesSwept = 'investment__fundPerformanceFeesSwept',
  InvestmentHighWaterMark = 'investment__highWaterMark',
  InvestmentLastFeeSweepTimestamp = 'investment__lastFeeSweepTimestamp',
  HighWaterMark = 'highWaterMark',
  UsdManagementFee = 'usdManagementFee',
  UsdPerformanceFee = 'usdPerformanceFee',
  FundAmountManagementFee = 'fundAmountManagementFee',
  FundAmountPerformanceFee = 'fundAmountPerformanceFee',
  Timestamp = 'timestamp'
}

export type FeeWithdrawal = {
  __typename?: 'FeeWithdrawal';
  id: Scalars['ID'];
  fund: Fund;
  feeWithdrawalId: Scalars['BigInt'];
  address: Scalars['String'];
  fundAmount: Scalars['BigInt'];
  usdAmount: Scalars['BigInt'];
  usdTransferred: Scalars['Boolean'];
  timestamp: Scalars['BigInt'];
};

export type FeeWithdrawal_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  fund?: Maybe<Scalars['String']>;
  fund_not?: Maybe<Scalars['String']>;
  fund_gt?: Maybe<Scalars['String']>;
  fund_lt?: Maybe<Scalars['String']>;
  fund_gte?: Maybe<Scalars['String']>;
  fund_lte?: Maybe<Scalars['String']>;
  fund_in?: Maybe<Array<Scalars['String']>>;
  fund_not_in?: Maybe<Array<Scalars['String']>>;
  fund_contains?: Maybe<Scalars['String']>;
  fund_contains_nocase?: Maybe<Scalars['String']>;
  fund_not_contains?: Maybe<Scalars['String']>;
  fund_not_contains_nocase?: Maybe<Scalars['String']>;
  fund_starts_with?: Maybe<Scalars['String']>;
  fund_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_not_starts_with?: Maybe<Scalars['String']>;
  fund_not_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_ends_with?: Maybe<Scalars['String']>;
  fund_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_not_ends_with?: Maybe<Scalars['String']>;
  fund_not_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_?: Maybe<Fund_Filter>;
  feeWithdrawalId?: Maybe<Scalars['BigInt']>;
  feeWithdrawalId_not?: Maybe<Scalars['BigInt']>;
  feeWithdrawalId_gt?: Maybe<Scalars['BigInt']>;
  feeWithdrawalId_lt?: Maybe<Scalars['BigInt']>;
  feeWithdrawalId_gte?: Maybe<Scalars['BigInt']>;
  feeWithdrawalId_lte?: Maybe<Scalars['BigInt']>;
  feeWithdrawalId_in?: Maybe<Array<Scalars['BigInt']>>;
  feeWithdrawalId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_gt?: Maybe<Scalars['String']>;
  address_lt?: Maybe<Scalars['String']>;
  address_gte?: Maybe<Scalars['String']>;
  address_lte?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Scalars['String']>>;
  address_not_in?: Maybe<Array<Scalars['String']>>;
  address_contains?: Maybe<Scalars['String']>;
  address_contains_nocase?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_not_contains_nocase?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_starts_with_nocase?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with_nocase?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_ends_with_nocase?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with_nocase?: Maybe<Scalars['String']>;
  fundAmount?: Maybe<Scalars['BigInt']>;
  fundAmount_not?: Maybe<Scalars['BigInt']>;
  fundAmount_gt?: Maybe<Scalars['BigInt']>;
  fundAmount_lt?: Maybe<Scalars['BigInt']>;
  fundAmount_gte?: Maybe<Scalars['BigInt']>;
  fundAmount_lte?: Maybe<Scalars['BigInt']>;
  fundAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  fundAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  usdAmount?: Maybe<Scalars['BigInt']>;
  usdAmount_not?: Maybe<Scalars['BigInt']>;
  usdAmount_gt?: Maybe<Scalars['BigInt']>;
  usdAmount_lt?: Maybe<Scalars['BigInt']>;
  usdAmount_gte?: Maybe<Scalars['BigInt']>;
  usdAmount_lte?: Maybe<Scalars['BigInt']>;
  usdAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  usdAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  usdTransferred?: Maybe<Scalars['Boolean']>;
  usdTransferred_not?: Maybe<Scalars['Boolean']>;
  usdTransferred_in?: Maybe<Array<Scalars['Boolean']>>;
  usdTransferred_not_in?: Maybe<Array<Scalars['Boolean']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<FeeWithdrawal_Filter>>>;
  or?: Maybe<Array<Maybe<FeeWithdrawal_Filter>>>;
};

export enum FeeWithdrawal_OrderBy {
  Id = 'id',
  Fund = 'fund',
  FundId = 'fund__id',
  FundName = 'fund__name',
  FundSymbol = 'fund__symbol',
  FundManager = 'fund__manager',
  FundFeeBeneficiary = 'fund__feeBeneficiary',
  FundTimelock = 'fund__timelock',
  FundFeeSweepInterval = 'fund__feeSweepInterval',
  FundManagementFee = 'fund__managementFee',
  FundPerformanceFee = 'fund__performanceFee',
  FundMaxInvestors = 'fund__maxInvestors',
  FundMaxInvestmentsPerInvestor = 'fund__maxInvestmentsPerInvestor',
  FundMinInvestmentAmount = 'fund__minInvestmentAmount',
  FundInitialPrice = 'fund__initialPrice',
  FundLogoUrl = 'fund__logoUrl',
  FundContactInfo = 'fund__contactInfo',
  FundTags = 'fund__tags',
  FundUsingUsdToken = 'fund__usingUsdToken',
  FundDoneImportingInvestments = 'fund__doneImportingInvestments',
  FundLastFeeSweepEndedTimestamp = 'fund__lastFeeSweepEndedTimestamp',
  FundFeeSweeping = 'fund__feeSweeping',
  FundFeesSweptNotWithdrawn = 'fund__feesSweptNotWithdrawn',
  FundClosed = 'fund__closed',
  FundTimestamp = 'fund__timestamp',
  FeeWithdrawalId = 'feeWithdrawalId',
  Address = 'address',
  FundAmount = 'fundAmount',
  UsdAmount = 'usdAmount',
  UsdTransferred = 'usdTransferred',
  Timestamp = 'timestamp'
}

export type Fund = {
  __typename?: 'Fund';
  id: Scalars['ID'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  manager: Scalars['String'];
  feeBeneficiary: Scalars['String'];
  timelock: Scalars['BigInt'];
  feeSweepInterval: Scalars['BigInt'];
  managementFee: Scalars['BigInt'];
  performanceFee: Scalars['BigInt'];
  maxInvestors: Scalars['BigInt'];
  maxInvestmentsPerInvestor: Scalars['BigInt'];
  minInvestmentAmount: Scalars['BigInt'];
  initialPrice: Scalars['BigInt'];
  logoUrl: Scalars['String'];
  contactInfo: Scalars['String'];
  tags: Scalars['String'];
  usingUsdToken: Scalars['Boolean'];
  doneImportingInvestments: Scalars['Boolean'];
  lastFeeSweepEndedTimestamp: Scalars['BigInt'];
  feeSweeping: Scalars['Boolean'];
  feesSweptNotWithdrawn: Scalars['BigInt'];
  closed: Scalars['Boolean'];
  timestamp: Scalars['BigInt'];
  latestNav?: Maybe<Nav>;
  investments: Array<Investment>;
  feeWithdrawals: Array<FeeWithdrawal>;
};


export type FundInvestmentsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Investment_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Investment_Filter>;
};


export type FundFeeWithdrawalsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FeeWithdrawal_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FeeWithdrawal_Filter>;
};

export type Fund_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_lt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_contains?: Maybe<Scalars['String']>;
  name_contains_nocase?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_not_contains_nocase?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_starts_with_nocase?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with_nocase?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_ends_with_nocase?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with_nocase?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  symbol_not?: Maybe<Scalars['String']>;
  symbol_gt?: Maybe<Scalars['String']>;
  symbol_lt?: Maybe<Scalars['String']>;
  symbol_gte?: Maybe<Scalars['String']>;
  symbol_lte?: Maybe<Scalars['String']>;
  symbol_in?: Maybe<Array<Scalars['String']>>;
  symbol_not_in?: Maybe<Array<Scalars['String']>>;
  symbol_contains?: Maybe<Scalars['String']>;
  symbol_contains_nocase?: Maybe<Scalars['String']>;
  symbol_not_contains?: Maybe<Scalars['String']>;
  symbol_not_contains_nocase?: Maybe<Scalars['String']>;
  symbol_starts_with?: Maybe<Scalars['String']>;
  symbol_starts_with_nocase?: Maybe<Scalars['String']>;
  symbol_not_starts_with?: Maybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  symbol_ends_with?: Maybe<Scalars['String']>;
  symbol_ends_with_nocase?: Maybe<Scalars['String']>;
  symbol_not_ends_with?: Maybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['String']>;
  manager_not?: Maybe<Scalars['String']>;
  manager_gt?: Maybe<Scalars['String']>;
  manager_lt?: Maybe<Scalars['String']>;
  manager_gte?: Maybe<Scalars['String']>;
  manager_lte?: Maybe<Scalars['String']>;
  manager_in?: Maybe<Array<Scalars['String']>>;
  manager_not_in?: Maybe<Array<Scalars['String']>>;
  manager_contains?: Maybe<Scalars['String']>;
  manager_contains_nocase?: Maybe<Scalars['String']>;
  manager_not_contains?: Maybe<Scalars['String']>;
  manager_not_contains_nocase?: Maybe<Scalars['String']>;
  manager_starts_with?: Maybe<Scalars['String']>;
  manager_starts_with_nocase?: Maybe<Scalars['String']>;
  manager_not_starts_with?: Maybe<Scalars['String']>;
  manager_not_starts_with_nocase?: Maybe<Scalars['String']>;
  manager_ends_with?: Maybe<Scalars['String']>;
  manager_ends_with_nocase?: Maybe<Scalars['String']>;
  manager_not_ends_with?: Maybe<Scalars['String']>;
  manager_not_ends_with_nocase?: Maybe<Scalars['String']>;
  feeBeneficiary?: Maybe<Scalars['String']>;
  feeBeneficiary_not?: Maybe<Scalars['String']>;
  feeBeneficiary_gt?: Maybe<Scalars['String']>;
  feeBeneficiary_lt?: Maybe<Scalars['String']>;
  feeBeneficiary_gte?: Maybe<Scalars['String']>;
  feeBeneficiary_lte?: Maybe<Scalars['String']>;
  feeBeneficiary_in?: Maybe<Array<Scalars['String']>>;
  feeBeneficiary_not_in?: Maybe<Array<Scalars['String']>>;
  feeBeneficiary_contains?: Maybe<Scalars['String']>;
  feeBeneficiary_contains_nocase?: Maybe<Scalars['String']>;
  feeBeneficiary_not_contains?: Maybe<Scalars['String']>;
  feeBeneficiary_not_contains_nocase?: Maybe<Scalars['String']>;
  feeBeneficiary_starts_with?: Maybe<Scalars['String']>;
  feeBeneficiary_starts_with_nocase?: Maybe<Scalars['String']>;
  feeBeneficiary_not_starts_with?: Maybe<Scalars['String']>;
  feeBeneficiary_not_starts_with_nocase?: Maybe<Scalars['String']>;
  feeBeneficiary_ends_with?: Maybe<Scalars['String']>;
  feeBeneficiary_ends_with_nocase?: Maybe<Scalars['String']>;
  feeBeneficiary_not_ends_with?: Maybe<Scalars['String']>;
  feeBeneficiary_not_ends_with_nocase?: Maybe<Scalars['String']>;
  timelock?: Maybe<Scalars['BigInt']>;
  timelock_not?: Maybe<Scalars['BigInt']>;
  timelock_gt?: Maybe<Scalars['BigInt']>;
  timelock_lt?: Maybe<Scalars['BigInt']>;
  timelock_gte?: Maybe<Scalars['BigInt']>;
  timelock_lte?: Maybe<Scalars['BigInt']>;
  timelock_in?: Maybe<Array<Scalars['BigInt']>>;
  timelock_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeSweepInterval?: Maybe<Scalars['BigInt']>;
  feeSweepInterval_not?: Maybe<Scalars['BigInt']>;
  feeSweepInterval_gt?: Maybe<Scalars['BigInt']>;
  feeSweepInterval_lt?: Maybe<Scalars['BigInt']>;
  feeSweepInterval_gte?: Maybe<Scalars['BigInt']>;
  feeSweepInterval_lte?: Maybe<Scalars['BigInt']>;
  feeSweepInterval_in?: Maybe<Array<Scalars['BigInt']>>;
  feeSweepInterval_not_in?: Maybe<Array<Scalars['BigInt']>>;
  managementFee?: Maybe<Scalars['BigInt']>;
  managementFee_not?: Maybe<Scalars['BigInt']>;
  managementFee_gt?: Maybe<Scalars['BigInt']>;
  managementFee_lt?: Maybe<Scalars['BigInt']>;
  managementFee_gte?: Maybe<Scalars['BigInt']>;
  managementFee_lte?: Maybe<Scalars['BigInt']>;
  managementFee_in?: Maybe<Array<Scalars['BigInt']>>;
  managementFee_not_in?: Maybe<Array<Scalars['BigInt']>>;
  performanceFee?: Maybe<Scalars['BigInt']>;
  performanceFee_not?: Maybe<Scalars['BigInt']>;
  performanceFee_gt?: Maybe<Scalars['BigInt']>;
  performanceFee_lt?: Maybe<Scalars['BigInt']>;
  performanceFee_gte?: Maybe<Scalars['BigInt']>;
  performanceFee_lte?: Maybe<Scalars['BigInt']>;
  performanceFee_in?: Maybe<Array<Scalars['BigInt']>>;
  performanceFee_not_in?: Maybe<Array<Scalars['BigInt']>>;
  maxInvestors?: Maybe<Scalars['BigInt']>;
  maxInvestors_not?: Maybe<Scalars['BigInt']>;
  maxInvestors_gt?: Maybe<Scalars['BigInt']>;
  maxInvestors_lt?: Maybe<Scalars['BigInt']>;
  maxInvestors_gte?: Maybe<Scalars['BigInt']>;
  maxInvestors_lte?: Maybe<Scalars['BigInt']>;
  maxInvestors_in?: Maybe<Array<Scalars['BigInt']>>;
  maxInvestors_not_in?: Maybe<Array<Scalars['BigInt']>>;
  maxInvestmentsPerInvestor?: Maybe<Scalars['BigInt']>;
  maxInvestmentsPerInvestor_not?: Maybe<Scalars['BigInt']>;
  maxInvestmentsPerInvestor_gt?: Maybe<Scalars['BigInt']>;
  maxInvestmentsPerInvestor_lt?: Maybe<Scalars['BigInt']>;
  maxInvestmentsPerInvestor_gte?: Maybe<Scalars['BigInt']>;
  maxInvestmentsPerInvestor_lte?: Maybe<Scalars['BigInt']>;
  maxInvestmentsPerInvestor_in?: Maybe<Array<Scalars['BigInt']>>;
  maxInvestmentsPerInvestor_not_in?: Maybe<Array<Scalars['BigInt']>>;
  minInvestmentAmount?: Maybe<Scalars['BigInt']>;
  minInvestmentAmount_not?: Maybe<Scalars['BigInt']>;
  minInvestmentAmount_gt?: Maybe<Scalars['BigInt']>;
  minInvestmentAmount_lt?: Maybe<Scalars['BigInt']>;
  minInvestmentAmount_gte?: Maybe<Scalars['BigInt']>;
  minInvestmentAmount_lte?: Maybe<Scalars['BigInt']>;
  minInvestmentAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  minInvestmentAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  initialPrice?: Maybe<Scalars['BigInt']>;
  initialPrice_not?: Maybe<Scalars['BigInt']>;
  initialPrice_gt?: Maybe<Scalars['BigInt']>;
  initialPrice_lt?: Maybe<Scalars['BigInt']>;
  initialPrice_gte?: Maybe<Scalars['BigInt']>;
  initialPrice_lte?: Maybe<Scalars['BigInt']>;
  initialPrice_in?: Maybe<Array<Scalars['BigInt']>>;
  initialPrice_not_in?: Maybe<Array<Scalars['BigInt']>>;
  logoUrl?: Maybe<Scalars['String']>;
  logoUrl_not?: Maybe<Scalars['String']>;
  logoUrl_gt?: Maybe<Scalars['String']>;
  logoUrl_lt?: Maybe<Scalars['String']>;
  logoUrl_gte?: Maybe<Scalars['String']>;
  logoUrl_lte?: Maybe<Scalars['String']>;
  logoUrl_in?: Maybe<Array<Scalars['String']>>;
  logoUrl_not_in?: Maybe<Array<Scalars['String']>>;
  logoUrl_contains?: Maybe<Scalars['String']>;
  logoUrl_contains_nocase?: Maybe<Scalars['String']>;
  logoUrl_not_contains?: Maybe<Scalars['String']>;
  logoUrl_not_contains_nocase?: Maybe<Scalars['String']>;
  logoUrl_starts_with?: Maybe<Scalars['String']>;
  logoUrl_starts_with_nocase?: Maybe<Scalars['String']>;
  logoUrl_not_starts_with?: Maybe<Scalars['String']>;
  logoUrl_not_starts_with_nocase?: Maybe<Scalars['String']>;
  logoUrl_ends_with?: Maybe<Scalars['String']>;
  logoUrl_ends_with_nocase?: Maybe<Scalars['String']>;
  logoUrl_not_ends_with?: Maybe<Scalars['String']>;
  logoUrl_not_ends_with_nocase?: Maybe<Scalars['String']>;
  contactInfo?: Maybe<Scalars['String']>;
  contactInfo_not?: Maybe<Scalars['String']>;
  contactInfo_gt?: Maybe<Scalars['String']>;
  contactInfo_lt?: Maybe<Scalars['String']>;
  contactInfo_gte?: Maybe<Scalars['String']>;
  contactInfo_lte?: Maybe<Scalars['String']>;
  contactInfo_in?: Maybe<Array<Scalars['String']>>;
  contactInfo_not_in?: Maybe<Array<Scalars['String']>>;
  contactInfo_contains?: Maybe<Scalars['String']>;
  contactInfo_contains_nocase?: Maybe<Scalars['String']>;
  contactInfo_not_contains?: Maybe<Scalars['String']>;
  contactInfo_not_contains_nocase?: Maybe<Scalars['String']>;
  contactInfo_starts_with?: Maybe<Scalars['String']>;
  contactInfo_starts_with_nocase?: Maybe<Scalars['String']>;
  contactInfo_not_starts_with?: Maybe<Scalars['String']>;
  contactInfo_not_starts_with_nocase?: Maybe<Scalars['String']>;
  contactInfo_ends_with?: Maybe<Scalars['String']>;
  contactInfo_ends_with_nocase?: Maybe<Scalars['String']>;
  contactInfo_not_ends_with?: Maybe<Scalars['String']>;
  contactInfo_not_ends_with_nocase?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  tags_not?: Maybe<Scalars['String']>;
  tags_gt?: Maybe<Scalars['String']>;
  tags_lt?: Maybe<Scalars['String']>;
  tags_gte?: Maybe<Scalars['String']>;
  tags_lte?: Maybe<Scalars['String']>;
  tags_in?: Maybe<Array<Scalars['String']>>;
  tags_not_in?: Maybe<Array<Scalars['String']>>;
  tags_contains?: Maybe<Scalars['String']>;
  tags_contains_nocase?: Maybe<Scalars['String']>;
  tags_not_contains?: Maybe<Scalars['String']>;
  tags_not_contains_nocase?: Maybe<Scalars['String']>;
  tags_starts_with?: Maybe<Scalars['String']>;
  tags_starts_with_nocase?: Maybe<Scalars['String']>;
  tags_not_starts_with?: Maybe<Scalars['String']>;
  tags_not_starts_with_nocase?: Maybe<Scalars['String']>;
  tags_ends_with?: Maybe<Scalars['String']>;
  tags_ends_with_nocase?: Maybe<Scalars['String']>;
  tags_not_ends_with?: Maybe<Scalars['String']>;
  tags_not_ends_with_nocase?: Maybe<Scalars['String']>;
  usingUsdToken?: Maybe<Scalars['Boolean']>;
  usingUsdToken_not?: Maybe<Scalars['Boolean']>;
  usingUsdToken_in?: Maybe<Array<Scalars['Boolean']>>;
  usingUsdToken_not_in?: Maybe<Array<Scalars['Boolean']>>;
  doneImportingInvestments?: Maybe<Scalars['Boolean']>;
  doneImportingInvestments_not?: Maybe<Scalars['Boolean']>;
  doneImportingInvestments_in?: Maybe<Array<Scalars['Boolean']>>;
  doneImportingInvestments_not_in?: Maybe<Array<Scalars['Boolean']>>;
  lastFeeSweepEndedTimestamp?: Maybe<Scalars['BigInt']>;
  lastFeeSweepEndedTimestamp_not?: Maybe<Scalars['BigInt']>;
  lastFeeSweepEndedTimestamp_gt?: Maybe<Scalars['BigInt']>;
  lastFeeSweepEndedTimestamp_lt?: Maybe<Scalars['BigInt']>;
  lastFeeSweepEndedTimestamp_gte?: Maybe<Scalars['BigInt']>;
  lastFeeSweepEndedTimestamp_lte?: Maybe<Scalars['BigInt']>;
  lastFeeSweepEndedTimestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  lastFeeSweepEndedTimestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeSweeping?: Maybe<Scalars['Boolean']>;
  feeSweeping_not?: Maybe<Scalars['Boolean']>;
  feeSweeping_in?: Maybe<Array<Scalars['Boolean']>>;
  feeSweeping_not_in?: Maybe<Array<Scalars['Boolean']>>;
  feesSweptNotWithdrawn?: Maybe<Scalars['BigInt']>;
  feesSweptNotWithdrawn_not?: Maybe<Scalars['BigInt']>;
  feesSweptNotWithdrawn_gt?: Maybe<Scalars['BigInt']>;
  feesSweptNotWithdrawn_lt?: Maybe<Scalars['BigInt']>;
  feesSweptNotWithdrawn_gte?: Maybe<Scalars['BigInt']>;
  feesSweptNotWithdrawn_lte?: Maybe<Scalars['BigInt']>;
  feesSweptNotWithdrawn_in?: Maybe<Array<Scalars['BigInt']>>;
  feesSweptNotWithdrawn_not_in?: Maybe<Array<Scalars['BigInt']>>;
  closed?: Maybe<Scalars['Boolean']>;
  closed_not?: Maybe<Scalars['Boolean']>;
  closed_in?: Maybe<Array<Scalars['Boolean']>>;
  closed_not_in?: Maybe<Array<Scalars['Boolean']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  latestNav?: Maybe<Scalars['String']>;
  latestNav_not?: Maybe<Scalars['String']>;
  latestNav_gt?: Maybe<Scalars['String']>;
  latestNav_lt?: Maybe<Scalars['String']>;
  latestNav_gte?: Maybe<Scalars['String']>;
  latestNav_lte?: Maybe<Scalars['String']>;
  latestNav_in?: Maybe<Array<Scalars['String']>>;
  latestNav_not_in?: Maybe<Array<Scalars['String']>>;
  latestNav_contains?: Maybe<Scalars['String']>;
  latestNav_contains_nocase?: Maybe<Scalars['String']>;
  latestNav_not_contains?: Maybe<Scalars['String']>;
  latestNav_not_contains_nocase?: Maybe<Scalars['String']>;
  latestNav_starts_with?: Maybe<Scalars['String']>;
  latestNav_starts_with_nocase?: Maybe<Scalars['String']>;
  latestNav_not_starts_with?: Maybe<Scalars['String']>;
  latestNav_not_starts_with_nocase?: Maybe<Scalars['String']>;
  latestNav_ends_with?: Maybe<Scalars['String']>;
  latestNav_ends_with_nocase?: Maybe<Scalars['String']>;
  latestNav_not_ends_with?: Maybe<Scalars['String']>;
  latestNav_not_ends_with_nocase?: Maybe<Scalars['String']>;
  latestNav_?: Maybe<Nav_Filter>;
  investments_?: Maybe<Investment_Filter>;
  feeWithdrawals_?: Maybe<FeeWithdrawal_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Fund_Filter>>>;
  or?: Maybe<Array<Maybe<Fund_Filter>>>;
};

export enum Fund_OrderBy {
  Id = 'id',
  Name = 'name',
  Symbol = 'symbol',
  Manager = 'manager',
  FeeBeneficiary = 'feeBeneficiary',
  Timelock = 'timelock',
  FeeSweepInterval = 'feeSweepInterval',
  ManagementFee = 'managementFee',
  PerformanceFee = 'performanceFee',
  MaxInvestors = 'maxInvestors',
  MaxInvestmentsPerInvestor = 'maxInvestmentsPerInvestor',
  MinInvestmentAmount = 'minInvestmentAmount',
  InitialPrice = 'initialPrice',
  LogoUrl = 'logoUrl',
  ContactInfo = 'contactInfo',
  Tags = 'tags',
  UsingUsdToken = 'usingUsdToken',
  DoneImportingInvestments = 'doneImportingInvestments',
  LastFeeSweepEndedTimestamp = 'lastFeeSweepEndedTimestamp',
  FeeSweeping = 'feeSweeping',
  FeesSweptNotWithdrawn = 'feesSweptNotWithdrawn',
  Closed = 'closed',
  Timestamp = 'timestamp',
  LatestNav = 'latestNav',
  LatestNavId = 'latestNav__id',
  LatestNavNavId = 'latestNav__navId',
  LatestNavAum = 'latestNav__aum',
  LatestNavSupply = 'latestNav__supply',
  LatestNavTotalCapitalContributed = 'latestNav__totalCapitalContributed',
  LatestNavTimestamp = 'latestNav__timestamp',
  LatestNavIpfsHash = 'latestNav__ipfsHash',
  Investments = 'investments',
  FeeWithdrawals = 'feeWithdrawals'
}


export type Investment = {
  __typename?: 'Investment';
  id: Scalars['ID'];
  fund: Fund;
  investmentId: Scalars['BigInt'];
  investor: Investor;
  timestamp: Scalars['BigInt'];
  lockupTimestamp: Scalars['BigInt'];
  initialUsdAmount: Scalars['BigInt'];
  initialFundAmount: Scalars['BigInt'];
  initialHighWaterMark: Scalars['BigInt'];
  initialLastFeeSweepTimestamp: Scalars['BigInt'];
  managementFeeCostBasis: Scalars['BigInt'];
  investmentRequest?: Maybe<InvestmentRequest>;
  usdTransferred: Scalars['Boolean'];
  imported: Scalars['Boolean'];
  remainingFundAmount: Scalars['BigInt'];
  usdManagementFeesSwept: Scalars['BigInt'];
  usdPerformanceFeesSwept: Scalars['BigInt'];
  fundManagementFeesSwept: Scalars['BigInt'];
  fundPerformanceFeesSwept: Scalars['BigInt'];
  highWaterMark: Scalars['BigInt'];
  lastFeeSweepTimestamp: Scalars['BigInt'];
  feeSweeps: Array<FeeSweep>;
  redemptionRequest?: Maybe<RedemptionRequest>;
  redemption?: Maybe<Redemption>;
};


export type InvestmentFeeSweepsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FeeSweep_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FeeSweep_Filter>;
};

export type InvestmentRequest = {
  __typename?: 'InvestmentRequest';
  id: Scalars['ID'];
  fund: Fund;
  investmentRequestId: Scalars['BigInt'];
  investor: Investor;
  usdAmount: Scalars['BigInt'];
  minFundAmount: Scalars['BigInt'];
  maxFundAmount: Scalars['BigInt'];
  deadline: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  investment?: Maybe<Investment>;
  status: Status;
};

export type InvestmentRequest_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  fund?: Maybe<Scalars['String']>;
  fund_not?: Maybe<Scalars['String']>;
  fund_gt?: Maybe<Scalars['String']>;
  fund_lt?: Maybe<Scalars['String']>;
  fund_gte?: Maybe<Scalars['String']>;
  fund_lte?: Maybe<Scalars['String']>;
  fund_in?: Maybe<Array<Scalars['String']>>;
  fund_not_in?: Maybe<Array<Scalars['String']>>;
  fund_contains?: Maybe<Scalars['String']>;
  fund_contains_nocase?: Maybe<Scalars['String']>;
  fund_not_contains?: Maybe<Scalars['String']>;
  fund_not_contains_nocase?: Maybe<Scalars['String']>;
  fund_starts_with?: Maybe<Scalars['String']>;
  fund_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_not_starts_with?: Maybe<Scalars['String']>;
  fund_not_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_ends_with?: Maybe<Scalars['String']>;
  fund_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_not_ends_with?: Maybe<Scalars['String']>;
  fund_not_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_?: Maybe<Fund_Filter>;
  investmentRequestId?: Maybe<Scalars['BigInt']>;
  investmentRequestId_not?: Maybe<Scalars['BigInt']>;
  investmentRequestId_gt?: Maybe<Scalars['BigInt']>;
  investmentRequestId_lt?: Maybe<Scalars['BigInt']>;
  investmentRequestId_gte?: Maybe<Scalars['BigInt']>;
  investmentRequestId_lte?: Maybe<Scalars['BigInt']>;
  investmentRequestId_in?: Maybe<Array<Scalars['BigInt']>>;
  investmentRequestId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  investor?: Maybe<Scalars['String']>;
  investor_not?: Maybe<Scalars['String']>;
  investor_gt?: Maybe<Scalars['String']>;
  investor_lt?: Maybe<Scalars['String']>;
  investor_gte?: Maybe<Scalars['String']>;
  investor_lte?: Maybe<Scalars['String']>;
  investor_in?: Maybe<Array<Scalars['String']>>;
  investor_not_in?: Maybe<Array<Scalars['String']>>;
  investor_contains?: Maybe<Scalars['String']>;
  investor_contains_nocase?: Maybe<Scalars['String']>;
  investor_not_contains?: Maybe<Scalars['String']>;
  investor_not_contains_nocase?: Maybe<Scalars['String']>;
  investor_starts_with?: Maybe<Scalars['String']>;
  investor_starts_with_nocase?: Maybe<Scalars['String']>;
  investor_not_starts_with?: Maybe<Scalars['String']>;
  investor_not_starts_with_nocase?: Maybe<Scalars['String']>;
  investor_ends_with?: Maybe<Scalars['String']>;
  investor_ends_with_nocase?: Maybe<Scalars['String']>;
  investor_not_ends_with?: Maybe<Scalars['String']>;
  investor_not_ends_with_nocase?: Maybe<Scalars['String']>;
  investor_?: Maybe<Investor_Filter>;
  usdAmount?: Maybe<Scalars['BigInt']>;
  usdAmount_not?: Maybe<Scalars['BigInt']>;
  usdAmount_gt?: Maybe<Scalars['BigInt']>;
  usdAmount_lt?: Maybe<Scalars['BigInt']>;
  usdAmount_gte?: Maybe<Scalars['BigInt']>;
  usdAmount_lte?: Maybe<Scalars['BigInt']>;
  usdAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  usdAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  minFundAmount?: Maybe<Scalars['BigInt']>;
  minFundAmount_not?: Maybe<Scalars['BigInt']>;
  minFundAmount_gt?: Maybe<Scalars['BigInt']>;
  minFundAmount_lt?: Maybe<Scalars['BigInt']>;
  minFundAmount_gte?: Maybe<Scalars['BigInt']>;
  minFundAmount_lte?: Maybe<Scalars['BigInt']>;
  minFundAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  minFundAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  maxFundAmount?: Maybe<Scalars['BigInt']>;
  maxFundAmount_not?: Maybe<Scalars['BigInt']>;
  maxFundAmount_gt?: Maybe<Scalars['BigInt']>;
  maxFundAmount_lt?: Maybe<Scalars['BigInt']>;
  maxFundAmount_gte?: Maybe<Scalars['BigInt']>;
  maxFundAmount_lte?: Maybe<Scalars['BigInt']>;
  maxFundAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  maxFundAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  deadline?: Maybe<Scalars['BigInt']>;
  deadline_not?: Maybe<Scalars['BigInt']>;
  deadline_gt?: Maybe<Scalars['BigInt']>;
  deadline_lt?: Maybe<Scalars['BigInt']>;
  deadline_gte?: Maybe<Scalars['BigInt']>;
  deadline_lte?: Maybe<Scalars['BigInt']>;
  deadline_in?: Maybe<Array<Scalars['BigInt']>>;
  deadline_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  investment?: Maybe<Scalars['String']>;
  investment_not?: Maybe<Scalars['String']>;
  investment_gt?: Maybe<Scalars['String']>;
  investment_lt?: Maybe<Scalars['String']>;
  investment_gte?: Maybe<Scalars['String']>;
  investment_lte?: Maybe<Scalars['String']>;
  investment_in?: Maybe<Array<Scalars['String']>>;
  investment_not_in?: Maybe<Array<Scalars['String']>>;
  investment_contains?: Maybe<Scalars['String']>;
  investment_contains_nocase?: Maybe<Scalars['String']>;
  investment_not_contains?: Maybe<Scalars['String']>;
  investment_not_contains_nocase?: Maybe<Scalars['String']>;
  investment_starts_with?: Maybe<Scalars['String']>;
  investment_starts_with_nocase?: Maybe<Scalars['String']>;
  investment_not_starts_with?: Maybe<Scalars['String']>;
  investment_not_starts_with_nocase?: Maybe<Scalars['String']>;
  investment_ends_with?: Maybe<Scalars['String']>;
  investment_ends_with_nocase?: Maybe<Scalars['String']>;
  investment_not_ends_with?: Maybe<Scalars['String']>;
  investment_not_ends_with_nocase?: Maybe<Scalars['String']>;
  investment_?: Maybe<Investment_Filter>;
  status?: Maybe<Status>;
  status_not?: Maybe<Status>;
  status_in?: Maybe<Array<Status>>;
  status_not_in?: Maybe<Array<Status>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<InvestmentRequest_Filter>>>;
  or?: Maybe<Array<Maybe<InvestmentRequest_Filter>>>;
};

export enum InvestmentRequest_OrderBy {
  Id = 'id',
  Fund = 'fund',
  FundId = 'fund__id',
  FundName = 'fund__name',
  FundSymbol = 'fund__symbol',
  FundManager = 'fund__manager',
  FundFeeBeneficiary = 'fund__feeBeneficiary',
  FundTimelock = 'fund__timelock',
  FundFeeSweepInterval = 'fund__feeSweepInterval',
  FundManagementFee = 'fund__managementFee',
  FundPerformanceFee = 'fund__performanceFee',
  FundMaxInvestors = 'fund__maxInvestors',
  FundMaxInvestmentsPerInvestor = 'fund__maxInvestmentsPerInvestor',
  FundMinInvestmentAmount = 'fund__minInvestmentAmount',
  FundInitialPrice = 'fund__initialPrice',
  FundLogoUrl = 'fund__logoUrl',
  FundContactInfo = 'fund__contactInfo',
  FundTags = 'fund__tags',
  FundUsingUsdToken = 'fund__usingUsdToken',
  FundDoneImportingInvestments = 'fund__doneImportingInvestments',
  FundLastFeeSweepEndedTimestamp = 'fund__lastFeeSweepEndedTimestamp',
  FundFeeSweeping = 'fund__feeSweeping',
  FundFeesSweptNotWithdrawn = 'fund__feesSweptNotWithdrawn',
  FundClosed = 'fund__closed',
  FundTimestamp = 'fund__timestamp',
  InvestmentRequestId = 'investmentRequestId',
  Investor = 'investor',
  InvestorId = 'investor__id',
  UsdAmount = 'usdAmount',
  MinFundAmount = 'minFundAmount',
  MaxFundAmount = 'maxFundAmount',
  Deadline = 'deadline',
  Timestamp = 'timestamp',
  Investment = 'investment',
  InvestmentId = 'investment__id',
  InvestmentInvestmentId = 'investment__investmentId',
  InvestmentTimestamp = 'investment__timestamp',
  InvestmentLockupTimestamp = 'investment__lockupTimestamp',
  InvestmentInitialUsdAmount = 'investment__initialUsdAmount',
  InvestmentInitialFundAmount = 'investment__initialFundAmount',
  InvestmentInitialHighWaterMark = 'investment__initialHighWaterMark',
  InvestmentInitialLastFeeSweepTimestamp = 'investment__initialLastFeeSweepTimestamp',
  InvestmentManagementFeeCostBasis = 'investment__managementFeeCostBasis',
  InvestmentUsdTransferred = 'investment__usdTransferred',
  InvestmentImported = 'investment__imported',
  InvestmentRemainingFundAmount = 'investment__remainingFundAmount',
  InvestmentUsdManagementFeesSwept = 'investment__usdManagementFeesSwept',
  InvestmentUsdPerformanceFeesSwept = 'investment__usdPerformanceFeesSwept',
  InvestmentFundManagementFeesSwept = 'investment__fundManagementFeesSwept',
  InvestmentFundPerformanceFeesSwept = 'investment__fundPerformanceFeesSwept',
  InvestmentHighWaterMark = 'investment__highWaterMark',
  InvestmentLastFeeSweepTimestamp = 'investment__lastFeeSweepTimestamp',
  Status = 'status'
}

export type Investment_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  fund?: Maybe<Scalars['String']>;
  fund_not?: Maybe<Scalars['String']>;
  fund_gt?: Maybe<Scalars['String']>;
  fund_lt?: Maybe<Scalars['String']>;
  fund_gte?: Maybe<Scalars['String']>;
  fund_lte?: Maybe<Scalars['String']>;
  fund_in?: Maybe<Array<Scalars['String']>>;
  fund_not_in?: Maybe<Array<Scalars['String']>>;
  fund_contains?: Maybe<Scalars['String']>;
  fund_contains_nocase?: Maybe<Scalars['String']>;
  fund_not_contains?: Maybe<Scalars['String']>;
  fund_not_contains_nocase?: Maybe<Scalars['String']>;
  fund_starts_with?: Maybe<Scalars['String']>;
  fund_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_not_starts_with?: Maybe<Scalars['String']>;
  fund_not_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_ends_with?: Maybe<Scalars['String']>;
  fund_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_not_ends_with?: Maybe<Scalars['String']>;
  fund_not_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_?: Maybe<Fund_Filter>;
  investmentId?: Maybe<Scalars['BigInt']>;
  investmentId_not?: Maybe<Scalars['BigInt']>;
  investmentId_gt?: Maybe<Scalars['BigInt']>;
  investmentId_lt?: Maybe<Scalars['BigInt']>;
  investmentId_gte?: Maybe<Scalars['BigInt']>;
  investmentId_lte?: Maybe<Scalars['BigInt']>;
  investmentId_in?: Maybe<Array<Scalars['BigInt']>>;
  investmentId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  investor?: Maybe<Scalars['String']>;
  investor_not?: Maybe<Scalars['String']>;
  investor_gt?: Maybe<Scalars['String']>;
  investor_lt?: Maybe<Scalars['String']>;
  investor_gte?: Maybe<Scalars['String']>;
  investor_lte?: Maybe<Scalars['String']>;
  investor_in?: Maybe<Array<Scalars['String']>>;
  investor_not_in?: Maybe<Array<Scalars['String']>>;
  investor_contains?: Maybe<Scalars['String']>;
  investor_contains_nocase?: Maybe<Scalars['String']>;
  investor_not_contains?: Maybe<Scalars['String']>;
  investor_not_contains_nocase?: Maybe<Scalars['String']>;
  investor_starts_with?: Maybe<Scalars['String']>;
  investor_starts_with_nocase?: Maybe<Scalars['String']>;
  investor_not_starts_with?: Maybe<Scalars['String']>;
  investor_not_starts_with_nocase?: Maybe<Scalars['String']>;
  investor_ends_with?: Maybe<Scalars['String']>;
  investor_ends_with_nocase?: Maybe<Scalars['String']>;
  investor_not_ends_with?: Maybe<Scalars['String']>;
  investor_not_ends_with_nocase?: Maybe<Scalars['String']>;
  investor_?: Maybe<Investor_Filter>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  lockupTimestamp?: Maybe<Scalars['BigInt']>;
  lockupTimestamp_not?: Maybe<Scalars['BigInt']>;
  lockupTimestamp_gt?: Maybe<Scalars['BigInt']>;
  lockupTimestamp_lt?: Maybe<Scalars['BigInt']>;
  lockupTimestamp_gte?: Maybe<Scalars['BigInt']>;
  lockupTimestamp_lte?: Maybe<Scalars['BigInt']>;
  lockupTimestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  lockupTimestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  initialUsdAmount?: Maybe<Scalars['BigInt']>;
  initialUsdAmount_not?: Maybe<Scalars['BigInt']>;
  initialUsdAmount_gt?: Maybe<Scalars['BigInt']>;
  initialUsdAmount_lt?: Maybe<Scalars['BigInt']>;
  initialUsdAmount_gte?: Maybe<Scalars['BigInt']>;
  initialUsdAmount_lte?: Maybe<Scalars['BigInt']>;
  initialUsdAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  initialUsdAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  initialFundAmount?: Maybe<Scalars['BigInt']>;
  initialFundAmount_not?: Maybe<Scalars['BigInt']>;
  initialFundAmount_gt?: Maybe<Scalars['BigInt']>;
  initialFundAmount_lt?: Maybe<Scalars['BigInt']>;
  initialFundAmount_gte?: Maybe<Scalars['BigInt']>;
  initialFundAmount_lte?: Maybe<Scalars['BigInt']>;
  initialFundAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  initialFundAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  initialHighWaterMark?: Maybe<Scalars['BigInt']>;
  initialHighWaterMark_not?: Maybe<Scalars['BigInt']>;
  initialHighWaterMark_gt?: Maybe<Scalars['BigInt']>;
  initialHighWaterMark_lt?: Maybe<Scalars['BigInt']>;
  initialHighWaterMark_gte?: Maybe<Scalars['BigInt']>;
  initialHighWaterMark_lte?: Maybe<Scalars['BigInt']>;
  initialHighWaterMark_in?: Maybe<Array<Scalars['BigInt']>>;
  initialHighWaterMark_not_in?: Maybe<Array<Scalars['BigInt']>>;
  initialLastFeeSweepTimestamp?: Maybe<Scalars['BigInt']>;
  initialLastFeeSweepTimestamp_not?: Maybe<Scalars['BigInt']>;
  initialLastFeeSweepTimestamp_gt?: Maybe<Scalars['BigInt']>;
  initialLastFeeSweepTimestamp_lt?: Maybe<Scalars['BigInt']>;
  initialLastFeeSweepTimestamp_gte?: Maybe<Scalars['BigInt']>;
  initialLastFeeSweepTimestamp_lte?: Maybe<Scalars['BigInt']>;
  initialLastFeeSweepTimestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  initialLastFeeSweepTimestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  managementFeeCostBasis?: Maybe<Scalars['BigInt']>;
  managementFeeCostBasis_not?: Maybe<Scalars['BigInt']>;
  managementFeeCostBasis_gt?: Maybe<Scalars['BigInt']>;
  managementFeeCostBasis_lt?: Maybe<Scalars['BigInt']>;
  managementFeeCostBasis_gte?: Maybe<Scalars['BigInt']>;
  managementFeeCostBasis_lte?: Maybe<Scalars['BigInt']>;
  managementFeeCostBasis_in?: Maybe<Array<Scalars['BigInt']>>;
  managementFeeCostBasis_not_in?: Maybe<Array<Scalars['BigInt']>>;
  investmentRequest?: Maybe<Scalars['String']>;
  investmentRequest_not?: Maybe<Scalars['String']>;
  investmentRequest_gt?: Maybe<Scalars['String']>;
  investmentRequest_lt?: Maybe<Scalars['String']>;
  investmentRequest_gte?: Maybe<Scalars['String']>;
  investmentRequest_lte?: Maybe<Scalars['String']>;
  investmentRequest_in?: Maybe<Array<Scalars['String']>>;
  investmentRequest_not_in?: Maybe<Array<Scalars['String']>>;
  investmentRequest_contains?: Maybe<Scalars['String']>;
  investmentRequest_contains_nocase?: Maybe<Scalars['String']>;
  investmentRequest_not_contains?: Maybe<Scalars['String']>;
  investmentRequest_not_contains_nocase?: Maybe<Scalars['String']>;
  investmentRequest_starts_with?: Maybe<Scalars['String']>;
  investmentRequest_starts_with_nocase?: Maybe<Scalars['String']>;
  investmentRequest_not_starts_with?: Maybe<Scalars['String']>;
  investmentRequest_not_starts_with_nocase?: Maybe<Scalars['String']>;
  investmentRequest_ends_with?: Maybe<Scalars['String']>;
  investmentRequest_ends_with_nocase?: Maybe<Scalars['String']>;
  investmentRequest_not_ends_with?: Maybe<Scalars['String']>;
  investmentRequest_not_ends_with_nocase?: Maybe<Scalars['String']>;
  investmentRequest_?: Maybe<InvestmentRequest_Filter>;
  usdTransferred?: Maybe<Scalars['Boolean']>;
  usdTransferred_not?: Maybe<Scalars['Boolean']>;
  usdTransferred_in?: Maybe<Array<Scalars['Boolean']>>;
  usdTransferred_not_in?: Maybe<Array<Scalars['Boolean']>>;
  imported?: Maybe<Scalars['Boolean']>;
  imported_not?: Maybe<Scalars['Boolean']>;
  imported_in?: Maybe<Array<Scalars['Boolean']>>;
  imported_not_in?: Maybe<Array<Scalars['Boolean']>>;
  remainingFundAmount?: Maybe<Scalars['BigInt']>;
  remainingFundAmount_not?: Maybe<Scalars['BigInt']>;
  remainingFundAmount_gt?: Maybe<Scalars['BigInt']>;
  remainingFundAmount_lt?: Maybe<Scalars['BigInt']>;
  remainingFundAmount_gte?: Maybe<Scalars['BigInt']>;
  remainingFundAmount_lte?: Maybe<Scalars['BigInt']>;
  remainingFundAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  remainingFundAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  usdManagementFeesSwept?: Maybe<Scalars['BigInt']>;
  usdManagementFeesSwept_not?: Maybe<Scalars['BigInt']>;
  usdManagementFeesSwept_gt?: Maybe<Scalars['BigInt']>;
  usdManagementFeesSwept_lt?: Maybe<Scalars['BigInt']>;
  usdManagementFeesSwept_gte?: Maybe<Scalars['BigInt']>;
  usdManagementFeesSwept_lte?: Maybe<Scalars['BigInt']>;
  usdManagementFeesSwept_in?: Maybe<Array<Scalars['BigInt']>>;
  usdManagementFeesSwept_not_in?: Maybe<Array<Scalars['BigInt']>>;
  usdPerformanceFeesSwept?: Maybe<Scalars['BigInt']>;
  usdPerformanceFeesSwept_not?: Maybe<Scalars['BigInt']>;
  usdPerformanceFeesSwept_gt?: Maybe<Scalars['BigInt']>;
  usdPerformanceFeesSwept_lt?: Maybe<Scalars['BigInt']>;
  usdPerformanceFeesSwept_gte?: Maybe<Scalars['BigInt']>;
  usdPerformanceFeesSwept_lte?: Maybe<Scalars['BigInt']>;
  usdPerformanceFeesSwept_in?: Maybe<Array<Scalars['BigInt']>>;
  usdPerformanceFeesSwept_not_in?: Maybe<Array<Scalars['BigInt']>>;
  fundManagementFeesSwept?: Maybe<Scalars['BigInt']>;
  fundManagementFeesSwept_not?: Maybe<Scalars['BigInt']>;
  fundManagementFeesSwept_gt?: Maybe<Scalars['BigInt']>;
  fundManagementFeesSwept_lt?: Maybe<Scalars['BigInt']>;
  fundManagementFeesSwept_gte?: Maybe<Scalars['BigInt']>;
  fundManagementFeesSwept_lte?: Maybe<Scalars['BigInt']>;
  fundManagementFeesSwept_in?: Maybe<Array<Scalars['BigInt']>>;
  fundManagementFeesSwept_not_in?: Maybe<Array<Scalars['BigInt']>>;
  fundPerformanceFeesSwept?: Maybe<Scalars['BigInt']>;
  fundPerformanceFeesSwept_not?: Maybe<Scalars['BigInt']>;
  fundPerformanceFeesSwept_gt?: Maybe<Scalars['BigInt']>;
  fundPerformanceFeesSwept_lt?: Maybe<Scalars['BigInt']>;
  fundPerformanceFeesSwept_gte?: Maybe<Scalars['BigInt']>;
  fundPerformanceFeesSwept_lte?: Maybe<Scalars['BigInt']>;
  fundPerformanceFeesSwept_in?: Maybe<Array<Scalars['BigInt']>>;
  fundPerformanceFeesSwept_not_in?: Maybe<Array<Scalars['BigInt']>>;
  highWaterMark?: Maybe<Scalars['BigInt']>;
  highWaterMark_not?: Maybe<Scalars['BigInt']>;
  highWaterMark_gt?: Maybe<Scalars['BigInt']>;
  highWaterMark_lt?: Maybe<Scalars['BigInt']>;
  highWaterMark_gte?: Maybe<Scalars['BigInt']>;
  highWaterMark_lte?: Maybe<Scalars['BigInt']>;
  highWaterMark_in?: Maybe<Array<Scalars['BigInt']>>;
  highWaterMark_not_in?: Maybe<Array<Scalars['BigInt']>>;
  lastFeeSweepTimestamp?: Maybe<Scalars['BigInt']>;
  lastFeeSweepTimestamp_not?: Maybe<Scalars['BigInt']>;
  lastFeeSweepTimestamp_gt?: Maybe<Scalars['BigInt']>;
  lastFeeSweepTimestamp_lt?: Maybe<Scalars['BigInt']>;
  lastFeeSweepTimestamp_gte?: Maybe<Scalars['BigInt']>;
  lastFeeSweepTimestamp_lte?: Maybe<Scalars['BigInt']>;
  lastFeeSweepTimestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  lastFeeSweepTimestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeSweeps_?: Maybe<FeeSweep_Filter>;
  redemptionRequest?: Maybe<Scalars['String']>;
  redemptionRequest_not?: Maybe<Scalars['String']>;
  redemptionRequest_gt?: Maybe<Scalars['String']>;
  redemptionRequest_lt?: Maybe<Scalars['String']>;
  redemptionRequest_gte?: Maybe<Scalars['String']>;
  redemptionRequest_lte?: Maybe<Scalars['String']>;
  redemptionRequest_in?: Maybe<Array<Scalars['String']>>;
  redemptionRequest_not_in?: Maybe<Array<Scalars['String']>>;
  redemptionRequest_contains?: Maybe<Scalars['String']>;
  redemptionRequest_contains_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_not_contains?: Maybe<Scalars['String']>;
  redemptionRequest_not_contains_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_starts_with?: Maybe<Scalars['String']>;
  redemptionRequest_starts_with_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_not_starts_with?: Maybe<Scalars['String']>;
  redemptionRequest_not_starts_with_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_ends_with?: Maybe<Scalars['String']>;
  redemptionRequest_ends_with_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_not_ends_with?: Maybe<Scalars['String']>;
  redemptionRequest_not_ends_with_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_?: Maybe<RedemptionRequest_Filter>;
  redemption?: Maybe<Scalars['String']>;
  redemption_not?: Maybe<Scalars['String']>;
  redemption_gt?: Maybe<Scalars['String']>;
  redemption_lt?: Maybe<Scalars['String']>;
  redemption_gte?: Maybe<Scalars['String']>;
  redemption_lte?: Maybe<Scalars['String']>;
  redemption_in?: Maybe<Array<Scalars['String']>>;
  redemption_not_in?: Maybe<Array<Scalars['String']>>;
  redemption_contains?: Maybe<Scalars['String']>;
  redemption_contains_nocase?: Maybe<Scalars['String']>;
  redemption_not_contains?: Maybe<Scalars['String']>;
  redemption_not_contains_nocase?: Maybe<Scalars['String']>;
  redemption_starts_with?: Maybe<Scalars['String']>;
  redemption_starts_with_nocase?: Maybe<Scalars['String']>;
  redemption_not_starts_with?: Maybe<Scalars['String']>;
  redemption_not_starts_with_nocase?: Maybe<Scalars['String']>;
  redemption_ends_with?: Maybe<Scalars['String']>;
  redemption_ends_with_nocase?: Maybe<Scalars['String']>;
  redemption_not_ends_with?: Maybe<Scalars['String']>;
  redemption_not_ends_with_nocase?: Maybe<Scalars['String']>;
  redemption_?: Maybe<Redemption_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Investment_Filter>>>;
  or?: Maybe<Array<Maybe<Investment_Filter>>>;
};

export enum Investment_OrderBy {
  Id = 'id',
  Fund = 'fund',
  FundId = 'fund__id',
  FundName = 'fund__name',
  FundSymbol = 'fund__symbol',
  FundManager = 'fund__manager',
  FundFeeBeneficiary = 'fund__feeBeneficiary',
  FundTimelock = 'fund__timelock',
  FundFeeSweepInterval = 'fund__feeSweepInterval',
  FundManagementFee = 'fund__managementFee',
  FundPerformanceFee = 'fund__performanceFee',
  FundMaxInvestors = 'fund__maxInvestors',
  FundMaxInvestmentsPerInvestor = 'fund__maxInvestmentsPerInvestor',
  FundMinInvestmentAmount = 'fund__minInvestmentAmount',
  FundInitialPrice = 'fund__initialPrice',
  FundLogoUrl = 'fund__logoUrl',
  FundContactInfo = 'fund__contactInfo',
  FundTags = 'fund__tags',
  FundUsingUsdToken = 'fund__usingUsdToken',
  FundDoneImportingInvestments = 'fund__doneImportingInvestments',
  FundLastFeeSweepEndedTimestamp = 'fund__lastFeeSweepEndedTimestamp',
  FundFeeSweeping = 'fund__feeSweeping',
  FundFeesSweptNotWithdrawn = 'fund__feesSweptNotWithdrawn',
  FundClosed = 'fund__closed',
  FundTimestamp = 'fund__timestamp',
  InvestmentId = 'investmentId',
  Investor = 'investor',
  InvestorId = 'investor__id',
  Timestamp = 'timestamp',
  LockupTimestamp = 'lockupTimestamp',
  InitialUsdAmount = 'initialUsdAmount',
  InitialFundAmount = 'initialFundAmount',
  InitialHighWaterMark = 'initialHighWaterMark',
  InitialLastFeeSweepTimestamp = 'initialLastFeeSweepTimestamp',
  ManagementFeeCostBasis = 'managementFeeCostBasis',
  InvestmentRequest = 'investmentRequest',
  InvestmentRequestId = 'investmentRequest__id',
  InvestmentRequestInvestmentRequestId = 'investmentRequest__investmentRequestId',
  InvestmentRequestUsdAmount = 'investmentRequest__usdAmount',
  InvestmentRequestMinFundAmount = 'investmentRequest__minFundAmount',
  InvestmentRequestMaxFundAmount = 'investmentRequest__maxFundAmount',
  InvestmentRequestDeadline = 'investmentRequest__deadline',
  InvestmentRequestTimestamp = 'investmentRequest__timestamp',
  InvestmentRequestStatus = 'investmentRequest__status',
  UsdTransferred = 'usdTransferred',
  Imported = 'imported',
  RemainingFundAmount = 'remainingFundAmount',
  UsdManagementFeesSwept = 'usdManagementFeesSwept',
  UsdPerformanceFeesSwept = 'usdPerformanceFeesSwept',
  FundManagementFeesSwept = 'fundManagementFeesSwept',
  FundPerformanceFeesSwept = 'fundPerformanceFeesSwept',
  HighWaterMark = 'highWaterMark',
  LastFeeSweepTimestamp = 'lastFeeSweepTimestamp',
  FeeSweeps = 'feeSweeps',
  RedemptionRequest = 'redemptionRequest',
  RedemptionRequestId = 'redemptionRequest__id',
  RedemptionRequestRedemptionRequestId = 'redemptionRequest__redemptionRequestId',
  RedemptionRequestMinUsdAmount = 'redemptionRequest__minUsdAmount',
  RedemptionRequestDeadline = 'redemptionRequest__deadline',
  RedemptionRequestTimestamp = 'redemptionRequest__timestamp',
  Redemption = 'redemption',
  RedemptionId = 'redemption__id',
  RedemptionRedemptionId = 'redemption__redemptionId',
  RedemptionFundAmount = 'redemption__fundAmount',
  RedemptionUsdAmount = 'redemption__usdAmount',
  RedemptionTimestamp = 'redemption__timestamp',
  RedemptionUsdTransferred = 'redemption__usdTransferred'
}

export type Investor = {
  __typename?: 'Investor';
  id: Scalars['ID'];
  investments: Array<Investment>;
  fundsWhitelisted: Array<Fund>;
};


export type InvestorInvestmentsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Investment_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Investment_Filter>;
};


export type InvestorFundsWhitelistedArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Fund_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Fund_Filter>;
};

export type Investor_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  investments_?: Maybe<Investment_Filter>;
  fundsWhitelisted?: Maybe<Array<Scalars['String']>>;
  fundsWhitelisted_not?: Maybe<Array<Scalars['String']>>;
  fundsWhitelisted_contains?: Maybe<Array<Scalars['String']>>;
  fundsWhitelisted_contains_nocase?: Maybe<Array<Scalars['String']>>;
  fundsWhitelisted_not_contains?: Maybe<Array<Scalars['String']>>;
  fundsWhitelisted_not_contains_nocase?: Maybe<Array<Scalars['String']>>;
  fundsWhitelisted_?: Maybe<Fund_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Investor_Filter>>>;
  or?: Maybe<Array<Maybe<Investor_Filter>>>;
};

export enum Investor_OrderBy {
  Id = 'id',
  Investments = 'investments',
  FundsWhitelisted = 'fundsWhitelisted'
}

export type Nav = {
  __typename?: 'Nav';
  id: Scalars['ID'];
  fund: Fund;
  navId: Scalars['BigInt'];
  aum: Scalars['BigInt'];
  supply: Scalars['BigInt'];
  totalCapitalContributed: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  ipfsHash: Scalars['String'];
};

export type Nav_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  fund?: Maybe<Scalars['String']>;
  fund_not?: Maybe<Scalars['String']>;
  fund_gt?: Maybe<Scalars['String']>;
  fund_lt?: Maybe<Scalars['String']>;
  fund_gte?: Maybe<Scalars['String']>;
  fund_lte?: Maybe<Scalars['String']>;
  fund_in?: Maybe<Array<Scalars['String']>>;
  fund_not_in?: Maybe<Array<Scalars['String']>>;
  fund_contains?: Maybe<Scalars['String']>;
  fund_contains_nocase?: Maybe<Scalars['String']>;
  fund_not_contains?: Maybe<Scalars['String']>;
  fund_not_contains_nocase?: Maybe<Scalars['String']>;
  fund_starts_with?: Maybe<Scalars['String']>;
  fund_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_not_starts_with?: Maybe<Scalars['String']>;
  fund_not_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_ends_with?: Maybe<Scalars['String']>;
  fund_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_not_ends_with?: Maybe<Scalars['String']>;
  fund_not_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_?: Maybe<Fund_Filter>;
  navId?: Maybe<Scalars['BigInt']>;
  navId_not?: Maybe<Scalars['BigInt']>;
  navId_gt?: Maybe<Scalars['BigInt']>;
  navId_lt?: Maybe<Scalars['BigInt']>;
  navId_gte?: Maybe<Scalars['BigInt']>;
  navId_lte?: Maybe<Scalars['BigInt']>;
  navId_in?: Maybe<Array<Scalars['BigInt']>>;
  navId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  aum?: Maybe<Scalars['BigInt']>;
  aum_not?: Maybe<Scalars['BigInt']>;
  aum_gt?: Maybe<Scalars['BigInt']>;
  aum_lt?: Maybe<Scalars['BigInt']>;
  aum_gte?: Maybe<Scalars['BigInt']>;
  aum_lte?: Maybe<Scalars['BigInt']>;
  aum_in?: Maybe<Array<Scalars['BigInt']>>;
  aum_not_in?: Maybe<Array<Scalars['BigInt']>>;
  supply?: Maybe<Scalars['BigInt']>;
  supply_not?: Maybe<Scalars['BigInt']>;
  supply_gt?: Maybe<Scalars['BigInt']>;
  supply_lt?: Maybe<Scalars['BigInt']>;
  supply_gte?: Maybe<Scalars['BigInt']>;
  supply_lte?: Maybe<Scalars['BigInt']>;
  supply_in?: Maybe<Array<Scalars['BigInt']>>;
  supply_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalCapitalContributed?: Maybe<Scalars['BigInt']>;
  totalCapitalContributed_not?: Maybe<Scalars['BigInt']>;
  totalCapitalContributed_gt?: Maybe<Scalars['BigInt']>;
  totalCapitalContributed_lt?: Maybe<Scalars['BigInt']>;
  totalCapitalContributed_gte?: Maybe<Scalars['BigInt']>;
  totalCapitalContributed_lte?: Maybe<Scalars['BigInt']>;
  totalCapitalContributed_in?: Maybe<Array<Scalars['BigInt']>>;
  totalCapitalContributed_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  ipfsHash?: Maybe<Scalars['String']>;
  ipfsHash_not?: Maybe<Scalars['String']>;
  ipfsHash_gt?: Maybe<Scalars['String']>;
  ipfsHash_lt?: Maybe<Scalars['String']>;
  ipfsHash_gte?: Maybe<Scalars['String']>;
  ipfsHash_lte?: Maybe<Scalars['String']>;
  ipfsHash_in?: Maybe<Array<Scalars['String']>>;
  ipfsHash_not_in?: Maybe<Array<Scalars['String']>>;
  ipfsHash_contains?: Maybe<Scalars['String']>;
  ipfsHash_contains_nocase?: Maybe<Scalars['String']>;
  ipfsHash_not_contains?: Maybe<Scalars['String']>;
  ipfsHash_not_contains_nocase?: Maybe<Scalars['String']>;
  ipfsHash_starts_with?: Maybe<Scalars['String']>;
  ipfsHash_starts_with_nocase?: Maybe<Scalars['String']>;
  ipfsHash_not_starts_with?: Maybe<Scalars['String']>;
  ipfsHash_not_starts_with_nocase?: Maybe<Scalars['String']>;
  ipfsHash_ends_with?: Maybe<Scalars['String']>;
  ipfsHash_ends_with_nocase?: Maybe<Scalars['String']>;
  ipfsHash_not_ends_with?: Maybe<Scalars['String']>;
  ipfsHash_not_ends_with_nocase?: Maybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Nav_Filter>>>;
  or?: Maybe<Array<Maybe<Nav_Filter>>>;
};

export enum Nav_OrderBy {
  Id = 'id',
  Fund = 'fund',
  FundId = 'fund__id',
  FundName = 'fund__name',
  FundSymbol = 'fund__symbol',
  FundManager = 'fund__manager',
  FundFeeBeneficiary = 'fund__feeBeneficiary',
  FundTimelock = 'fund__timelock',
  FundFeeSweepInterval = 'fund__feeSweepInterval',
  FundManagementFee = 'fund__managementFee',
  FundPerformanceFee = 'fund__performanceFee',
  FundMaxInvestors = 'fund__maxInvestors',
  FundMaxInvestmentsPerInvestor = 'fund__maxInvestmentsPerInvestor',
  FundMinInvestmentAmount = 'fund__minInvestmentAmount',
  FundInitialPrice = 'fund__initialPrice',
  FundLogoUrl = 'fund__logoUrl',
  FundContactInfo = 'fund__contactInfo',
  FundTags = 'fund__tags',
  FundUsingUsdToken = 'fund__usingUsdToken',
  FundDoneImportingInvestments = 'fund__doneImportingInvestments',
  FundLastFeeSweepEndedTimestamp = 'fund__lastFeeSweepEndedTimestamp',
  FundFeeSweeping = 'fund__feeSweeping',
  FundFeesSweptNotWithdrawn = 'fund__feesSweptNotWithdrawn',
  FundClosed = 'fund__closed',
  FundTimestamp = 'fund__timestamp',
  NavId = 'navId',
  Aum = 'aum',
  Supply = 'supply',
  TotalCapitalContributed = 'totalCapitalContributed',
  Timestamp = 'timestamp',
  IpfsHash = 'ipfsHash'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Query = {
  __typename?: 'Query';
  fund?: Maybe<Fund>;
  funds: Array<Fund>;
  nav?: Maybe<Nav>;
  navs: Array<Nav>;
  investor?: Maybe<Investor>;
  investors: Array<Investor>;
  investment?: Maybe<Investment>;
  investments: Array<Investment>;
  feeSweep?: Maybe<FeeSweep>;
  feeSweeps: Array<FeeSweep>;
  feeWithdrawal?: Maybe<FeeWithdrawal>;
  feeWithdrawals: Array<FeeWithdrawal>;
  investmentRequest?: Maybe<InvestmentRequest>;
  investmentRequests: Array<InvestmentRequest>;
  redemptionRequest?: Maybe<RedemptionRequest>;
  redemptionRequests: Array<RedemptionRequest>;
  redemption?: Maybe<Redemption>;
  redemptions: Array<Redemption>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QueryFundArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFundsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Fund_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Fund_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryNavArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryNavsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Nav_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Nav_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryInvestorArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryInvestorsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Investor_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Investor_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryInvestmentArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryInvestmentsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Investment_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Investment_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFeeSweepArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFeeSweepsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FeeSweep_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FeeSweep_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFeeWithdrawalArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFeeWithdrawalsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FeeWithdrawal_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FeeWithdrawal_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryInvestmentRequestArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryInvestmentRequestsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<InvestmentRequest_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<InvestmentRequest_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRedemptionRequestArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRedemptionRequestsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RedemptionRequest_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RedemptionRequest_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRedemptionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRedemptionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Redemption_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Redemption_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type Redemption = {
  __typename?: 'Redemption';
  id: Scalars['ID'];
  fund: Fund;
  redemptionId: Scalars['BigInt'];
  investment: Investment;
  redemptionRequest?: Maybe<RedemptionRequest>;
  fundAmount: Scalars['BigInt'];
  usdAmount: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  usdTransferred: Scalars['Boolean'];
};

export type RedemptionRequest = {
  __typename?: 'RedemptionRequest';
  id: Scalars['ID'];
  fund: Fund;
  redemptionRequestId: Scalars['BigInt'];
  investment: Investment;
  minUsdAmount: Scalars['BigInt'];
  deadline: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  redemption?: Maybe<Redemption>;
};

export type RedemptionRequest_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  fund?: Maybe<Scalars['String']>;
  fund_not?: Maybe<Scalars['String']>;
  fund_gt?: Maybe<Scalars['String']>;
  fund_lt?: Maybe<Scalars['String']>;
  fund_gte?: Maybe<Scalars['String']>;
  fund_lte?: Maybe<Scalars['String']>;
  fund_in?: Maybe<Array<Scalars['String']>>;
  fund_not_in?: Maybe<Array<Scalars['String']>>;
  fund_contains?: Maybe<Scalars['String']>;
  fund_contains_nocase?: Maybe<Scalars['String']>;
  fund_not_contains?: Maybe<Scalars['String']>;
  fund_not_contains_nocase?: Maybe<Scalars['String']>;
  fund_starts_with?: Maybe<Scalars['String']>;
  fund_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_not_starts_with?: Maybe<Scalars['String']>;
  fund_not_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_ends_with?: Maybe<Scalars['String']>;
  fund_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_not_ends_with?: Maybe<Scalars['String']>;
  fund_not_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_?: Maybe<Fund_Filter>;
  redemptionRequestId?: Maybe<Scalars['BigInt']>;
  redemptionRequestId_not?: Maybe<Scalars['BigInt']>;
  redemptionRequestId_gt?: Maybe<Scalars['BigInt']>;
  redemptionRequestId_lt?: Maybe<Scalars['BigInt']>;
  redemptionRequestId_gte?: Maybe<Scalars['BigInt']>;
  redemptionRequestId_lte?: Maybe<Scalars['BigInt']>;
  redemptionRequestId_in?: Maybe<Array<Scalars['BigInt']>>;
  redemptionRequestId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  investment?: Maybe<Scalars['String']>;
  investment_not?: Maybe<Scalars['String']>;
  investment_gt?: Maybe<Scalars['String']>;
  investment_lt?: Maybe<Scalars['String']>;
  investment_gte?: Maybe<Scalars['String']>;
  investment_lte?: Maybe<Scalars['String']>;
  investment_in?: Maybe<Array<Scalars['String']>>;
  investment_not_in?: Maybe<Array<Scalars['String']>>;
  investment_contains?: Maybe<Scalars['String']>;
  investment_contains_nocase?: Maybe<Scalars['String']>;
  investment_not_contains?: Maybe<Scalars['String']>;
  investment_not_contains_nocase?: Maybe<Scalars['String']>;
  investment_starts_with?: Maybe<Scalars['String']>;
  investment_starts_with_nocase?: Maybe<Scalars['String']>;
  investment_not_starts_with?: Maybe<Scalars['String']>;
  investment_not_starts_with_nocase?: Maybe<Scalars['String']>;
  investment_ends_with?: Maybe<Scalars['String']>;
  investment_ends_with_nocase?: Maybe<Scalars['String']>;
  investment_not_ends_with?: Maybe<Scalars['String']>;
  investment_not_ends_with_nocase?: Maybe<Scalars['String']>;
  investment_?: Maybe<Investment_Filter>;
  minUsdAmount?: Maybe<Scalars['BigInt']>;
  minUsdAmount_not?: Maybe<Scalars['BigInt']>;
  minUsdAmount_gt?: Maybe<Scalars['BigInt']>;
  minUsdAmount_lt?: Maybe<Scalars['BigInt']>;
  minUsdAmount_gte?: Maybe<Scalars['BigInt']>;
  minUsdAmount_lte?: Maybe<Scalars['BigInt']>;
  minUsdAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  minUsdAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  deadline?: Maybe<Scalars['BigInt']>;
  deadline_not?: Maybe<Scalars['BigInt']>;
  deadline_gt?: Maybe<Scalars['BigInt']>;
  deadline_lt?: Maybe<Scalars['BigInt']>;
  deadline_gte?: Maybe<Scalars['BigInt']>;
  deadline_lte?: Maybe<Scalars['BigInt']>;
  deadline_in?: Maybe<Array<Scalars['BigInt']>>;
  deadline_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  redemption?: Maybe<Scalars['String']>;
  redemption_not?: Maybe<Scalars['String']>;
  redemption_gt?: Maybe<Scalars['String']>;
  redemption_lt?: Maybe<Scalars['String']>;
  redemption_gte?: Maybe<Scalars['String']>;
  redemption_lte?: Maybe<Scalars['String']>;
  redemption_in?: Maybe<Array<Scalars['String']>>;
  redemption_not_in?: Maybe<Array<Scalars['String']>>;
  redemption_contains?: Maybe<Scalars['String']>;
  redemption_contains_nocase?: Maybe<Scalars['String']>;
  redemption_not_contains?: Maybe<Scalars['String']>;
  redemption_not_contains_nocase?: Maybe<Scalars['String']>;
  redemption_starts_with?: Maybe<Scalars['String']>;
  redemption_starts_with_nocase?: Maybe<Scalars['String']>;
  redemption_not_starts_with?: Maybe<Scalars['String']>;
  redemption_not_starts_with_nocase?: Maybe<Scalars['String']>;
  redemption_ends_with?: Maybe<Scalars['String']>;
  redemption_ends_with_nocase?: Maybe<Scalars['String']>;
  redemption_not_ends_with?: Maybe<Scalars['String']>;
  redemption_not_ends_with_nocase?: Maybe<Scalars['String']>;
  redemption_?: Maybe<Redemption_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<RedemptionRequest_Filter>>>;
  or?: Maybe<Array<Maybe<RedemptionRequest_Filter>>>;
};

export enum RedemptionRequest_OrderBy {
  Id = 'id',
  Fund = 'fund',
  FundId = 'fund__id',
  FundName = 'fund__name',
  FundSymbol = 'fund__symbol',
  FundManager = 'fund__manager',
  FundFeeBeneficiary = 'fund__feeBeneficiary',
  FundTimelock = 'fund__timelock',
  FundFeeSweepInterval = 'fund__feeSweepInterval',
  FundManagementFee = 'fund__managementFee',
  FundPerformanceFee = 'fund__performanceFee',
  FundMaxInvestors = 'fund__maxInvestors',
  FundMaxInvestmentsPerInvestor = 'fund__maxInvestmentsPerInvestor',
  FundMinInvestmentAmount = 'fund__minInvestmentAmount',
  FundInitialPrice = 'fund__initialPrice',
  FundLogoUrl = 'fund__logoUrl',
  FundContactInfo = 'fund__contactInfo',
  FundTags = 'fund__tags',
  FundUsingUsdToken = 'fund__usingUsdToken',
  FundDoneImportingInvestments = 'fund__doneImportingInvestments',
  FundLastFeeSweepEndedTimestamp = 'fund__lastFeeSweepEndedTimestamp',
  FundFeeSweeping = 'fund__feeSweeping',
  FundFeesSweptNotWithdrawn = 'fund__feesSweptNotWithdrawn',
  FundClosed = 'fund__closed',
  FundTimestamp = 'fund__timestamp',
  RedemptionRequestId = 'redemptionRequestId',
  Investment = 'investment',
  InvestmentId = 'investment__id',
  InvestmentInvestmentId = 'investment__investmentId',
  InvestmentTimestamp = 'investment__timestamp',
  InvestmentLockupTimestamp = 'investment__lockupTimestamp',
  InvestmentInitialUsdAmount = 'investment__initialUsdAmount',
  InvestmentInitialFundAmount = 'investment__initialFundAmount',
  InvestmentInitialHighWaterMark = 'investment__initialHighWaterMark',
  InvestmentInitialLastFeeSweepTimestamp = 'investment__initialLastFeeSweepTimestamp',
  InvestmentManagementFeeCostBasis = 'investment__managementFeeCostBasis',
  InvestmentUsdTransferred = 'investment__usdTransferred',
  InvestmentImported = 'investment__imported',
  InvestmentRemainingFundAmount = 'investment__remainingFundAmount',
  InvestmentUsdManagementFeesSwept = 'investment__usdManagementFeesSwept',
  InvestmentUsdPerformanceFeesSwept = 'investment__usdPerformanceFeesSwept',
  InvestmentFundManagementFeesSwept = 'investment__fundManagementFeesSwept',
  InvestmentFundPerformanceFeesSwept = 'investment__fundPerformanceFeesSwept',
  InvestmentHighWaterMark = 'investment__highWaterMark',
  InvestmentLastFeeSweepTimestamp = 'investment__lastFeeSweepTimestamp',
  MinUsdAmount = 'minUsdAmount',
  Deadline = 'deadline',
  Timestamp = 'timestamp',
  Redemption = 'redemption',
  RedemptionId = 'redemption__id',
  RedemptionRedemptionId = 'redemption__redemptionId',
  RedemptionFundAmount = 'redemption__fundAmount',
  RedemptionUsdAmount = 'redemption__usdAmount',
  RedemptionTimestamp = 'redemption__timestamp',
  RedemptionUsdTransferred = 'redemption__usdTransferred'
}

export type Redemption_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  fund?: Maybe<Scalars['String']>;
  fund_not?: Maybe<Scalars['String']>;
  fund_gt?: Maybe<Scalars['String']>;
  fund_lt?: Maybe<Scalars['String']>;
  fund_gte?: Maybe<Scalars['String']>;
  fund_lte?: Maybe<Scalars['String']>;
  fund_in?: Maybe<Array<Scalars['String']>>;
  fund_not_in?: Maybe<Array<Scalars['String']>>;
  fund_contains?: Maybe<Scalars['String']>;
  fund_contains_nocase?: Maybe<Scalars['String']>;
  fund_not_contains?: Maybe<Scalars['String']>;
  fund_not_contains_nocase?: Maybe<Scalars['String']>;
  fund_starts_with?: Maybe<Scalars['String']>;
  fund_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_not_starts_with?: Maybe<Scalars['String']>;
  fund_not_starts_with_nocase?: Maybe<Scalars['String']>;
  fund_ends_with?: Maybe<Scalars['String']>;
  fund_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_not_ends_with?: Maybe<Scalars['String']>;
  fund_not_ends_with_nocase?: Maybe<Scalars['String']>;
  fund_?: Maybe<Fund_Filter>;
  redemptionId?: Maybe<Scalars['BigInt']>;
  redemptionId_not?: Maybe<Scalars['BigInt']>;
  redemptionId_gt?: Maybe<Scalars['BigInt']>;
  redemptionId_lt?: Maybe<Scalars['BigInt']>;
  redemptionId_gte?: Maybe<Scalars['BigInt']>;
  redemptionId_lte?: Maybe<Scalars['BigInt']>;
  redemptionId_in?: Maybe<Array<Scalars['BigInt']>>;
  redemptionId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  investment?: Maybe<Scalars['String']>;
  investment_not?: Maybe<Scalars['String']>;
  investment_gt?: Maybe<Scalars['String']>;
  investment_lt?: Maybe<Scalars['String']>;
  investment_gte?: Maybe<Scalars['String']>;
  investment_lte?: Maybe<Scalars['String']>;
  investment_in?: Maybe<Array<Scalars['String']>>;
  investment_not_in?: Maybe<Array<Scalars['String']>>;
  investment_contains?: Maybe<Scalars['String']>;
  investment_contains_nocase?: Maybe<Scalars['String']>;
  investment_not_contains?: Maybe<Scalars['String']>;
  investment_not_contains_nocase?: Maybe<Scalars['String']>;
  investment_starts_with?: Maybe<Scalars['String']>;
  investment_starts_with_nocase?: Maybe<Scalars['String']>;
  investment_not_starts_with?: Maybe<Scalars['String']>;
  investment_not_starts_with_nocase?: Maybe<Scalars['String']>;
  investment_ends_with?: Maybe<Scalars['String']>;
  investment_ends_with_nocase?: Maybe<Scalars['String']>;
  investment_not_ends_with?: Maybe<Scalars['String']>;
  investment_not_ends_with_nocase?: Maybe<Scalars['String']>;
  investment_?: Maybe<Investment_Filter>;
  redemptionRequest?: Maybe<Scalars['String']>;
  redemptionRequest_not?: Maybe<Scalars['String']>;
  redemptionRequest_gt?: Maybe<Scalars['String']>;
  redemptionRequest_lt?: Maybe<Scalars['String']>;
  redemptionRequest_gte?: Maybe<Scalars['String']>;
  redemptionRequest_lte?: Maybe<Scalars['String']>;
  redemptionRequest_in?: Maybe<Array<Scalars['String']>>;
  redemptionRequest_not_in?: Maybe<Array<Scalars['String']>>;
  redemptionRequest_contains?: Maybe<Scalars['String']>;
  redemptionRequest_contains_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_not_contains?: Maybe<Scalars['String']>;
  redemptionRequest_not_contains_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_starts_with?: Maybe<Scalars['String']>;
  redemptionRequest_starts_with_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_not_starts_with?: Maybe<Scalars['String']>;
  redemptionRequest_not_starts_with_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_ends_with?: Maybe<Scalars['String']>;
  redemptionRequest_ends_with_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_not_ends_with?: Maybe<Scalars['String']>;
  redemptionRequest_not_ends_with_nocase?: Maybe<Scalars['String']>;
  redemptionRequest_?: Maybe<RedemptionRequest_Filter>;
  fundAmount?: Maybe<Scalars['BigInt']>;
  fundAmount_not?: Maybe<Scalars['BigInt']>;
  fundAmount_gt?: Maybe<Scalars['BigInt']>;
  fundAmount_lt?: Maybe<Scalars['BigInt']>;
  fundAmount_gte?: Maybe<Scalars['BigInt']>;
  fundAmount_lte?: Maybe<Scalars['BigInt']>;
  fundAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  fundAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  usdAmount?: Maybe<Scalars['BigInt']>;
  usdAmount_not?: Maybe<Scalars['BigInt']>;
  usdAmount_gt?: Maybe<Scalars['BigInt']>;
  usdAmount_lt?: Maybe<Scalars['BigInt']>;
  usdAmount_gte?: Maybe<Scalars['BigInt']>;
  usdAmount_lte?: Maybe<Scalars['BigInt']>;
  usdAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  usdAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  usdTransferred?: Maybe<Scalars['Boolean']>;
  usdTransferred_not?: Maybe<Scalars['Boolean']>;
  usdTransferred_in?: Maybe<Array<Scalars['Boolean']>>;
  usdTransferred_not_in?: Maybe<Array<Scalars['Boolean']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Redemption_Filter>>>;
  or?: Maybe<Array<Maybe<Redemption_Filter>>>;
};

export enum Redemption_OrderBy {
  Id = 'id',
  Fund = 'fund',
  FundId = 'fund__id',
  FundName = 'fund__name',
  FundSymbol = 'fund__symbol',
  FundManager = 'fund__manager',
  FundFeeBeneficiary = 'fund__feeBeneficiary',
  FundTimelock = 'fund__timelock',
  FundFeeSweepInterval = 'fund__feeSweepInterval',
  FundManagementFee = 'fund__managementFee',
  FundPerformanceFee = 'fund__performanceFee',
  FundMaxInvestors = 'fund__maxInvestors',
  FundMaxInvestmentsPerInvestor = 'fund__maxInvestmentsPerInvestor',
  FundMinInvestmentAmount = 'fund__minInvestmentAmount',
  FundInitialPrice = 'fund__initialPrice',
  FundLogoUrl = 'fund__logoUrl',
  FundContactInfo = 'fund__contactInfo',
  FundTags = 'fund__tags',
  FundUsingUsdToken = 'fund__usingUsdToken',
  FundDoneImportingInvestments = 'fund__doneImportingInvestments',
  FundLastFeeSweepEndedTimestamp = 'fund__lastFeeSweepEndedTimestamp',
  FundFeeSweeping = 'fund__feeSweeping',
  FundFeesSweptNotWithdrawn = 'fund__feesSweptNotWithdrawn',
  FundClosed = 'fund__closed',
  FundTimestamp = 'fund__timestamp',
  RedemptionId = 'redemptionId',
  Investment = 'investment',
  InvestmentId = 'investment__id',
  InvestmentInvestmentId = 'investment__investmentId',
  InvestmentTimestamp = 'investment__timestamp',
  InvestmentLockupTimestamp = 'investment__lockupTimestamp',
  InvestmentInitialUsdAmount = 'investment__initialUsdAmount',
  InvestmentInitialFundAmount = 'investment__initialFundAmount',
  InvestmentInitialHighWaterMark = 'investment__initialHighWaterMark',
  InvestmentInitialLastFeeSweepTimestamp = 'investment__initialLastFeeSweepTimestamp',
  InvestmentManagementFeeCostBasis = 'investment__managementFeeCostBasis',
  InvestmentUsdTransferred = 'investment__usdTransferred',
  InvestmentImported = 'investment__imported',
  InvestmentRemainingFundAmount = 'investment__remainingFundAmount',
  InvestmentUsdManagementFeesSwept = 'investment__usdManagementFeesSwept',
  InvestmentUsdPerformanceFeesSwept = 'investment__usdPerformanceFeesSwept',
  InvestmentFundManagementFeesSwept = 'investment__fundManagementFeesSwept',
  InvestmentFundPerformanceFeesSwept = 'investment__fundPerformanceFeesSwept',
  InvestmentHighWaterMark = 'investment__highWaterMark',
  InvestmentLastFeeSweepTimestamp = 'investment__lastFeeSweepTimestamp',
  RedemptionRequest = 'redemptionRequest',
  RedemptionRequestId = 'redemptionRequest__id',
  RedemptionRequestRedemptionRequestId = 'redemptionRequest__redemptionRequestId',
  RedemptionRequestMinUsdAmount = 'redemptionRequest__minUsdAmount',
  RedemptionRequestDeadline = 'redemptionRequest__deadline',
  RedemptionRequestTimestamp = 'redemptionRequest__timestamp',
  FundAmount = 'fundAmount',
  UsdAmount = 'usdAmount',
  Timestamp = 'timestamp',
  UsdTransferred = 'usdTransferred'
}

export enum Status {
  Pending = 'Pending',
  Processed = 'Processed',
  Updated = 'Updated',
  Cancelled = 'Cancelled'
}

export type Subscription = {
  __typename?: 'Subscription';
  fund?: Maybe<Fund>;
  funds: Array<Fund>;
  nav?: Maybe<Nav>;
  navs: Array<Nav>;
  investor?: Maybe<Investor>;
  investors: Array<Investor>;
  investment?: Maybe<Investment>;
  investments: Array<Investment>;
  feeSweep?: Maybe<FeeSweep>;
  feeSweeps: Array<FeeSweep>;
  feeWithdrawal?: Maybe<FeeWithdrawal>;
  feeWithdrawals: Array<FeeWithdrawal>;
  investmentRequest?: Maybe<InvestmentRequest>;
  investmentRequests: Array<InvestmentRequest>;
  redemptionRequest?: Maybe<RedemptionRequest>;
  redemptionRequests: Array<RedemptionRequest>;
  redemption?: Maybe<Redemption>;
  redemptions: Array<Redemption>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionFundArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFundsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Fund_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Fund_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionNavArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionNavsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Nav_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Nav_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionInvestorArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionInvestorsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Investor_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Investor_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionInvestmentArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionInvestmentsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Investment_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Investment_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFeeSweepArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFeeSweepsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FeeSweep_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FeeSweep_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFeeWithdrawalArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFeeWithdrawalsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FeeWithdrawal_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FeeWithdrawal_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionInvestmentRequestArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionInvestmentRequestsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<InvestmentRequest_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<InvestmentRequest_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRedemptionRequestArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRedemptionRequestsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RedemptionRequest_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RedemptionRequest_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRedemptionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRedemptionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Redemption_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Redemption_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_MetaArgs = {
  block?: Maybe<Block_Height>;
};


export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type FundsQueryVariables = Exact<{ [key: string]: never; }>;


export type FundsQuery = (
  { __typename?: 'Query' }
  & { funds: Array<(
    { __typename?: 'Fund' }
    & Pick<Fund, 'id' | 'name' | 'symbol' | 'manager' | 'feeBeneficiary' | 'timelock' | 'feeSweepInterval' | 'managementFee' | 'performanceFee' | 'maxInvestors' | 'maxInvestmentsPerInvestor' | 'minInvestmentAmount' | 'initialPrice' | 'logoUrl' | 'contactInfo' | 'tags' | 'usingUsdToken' | 'doneImportingInvestments' | 'lastFeeSweepEndedTimestamp' | 'feeSweeping' | 'feesSweptNotWithdrawn' | 'closed' | 'timestamp'>
    & { latestNav?: Maybe<(
      { __typename?: 'Nav' }
      & Pick<Nav, 'id' | 'navId' | 'aum' | 'supply' | 'totalCapitalContributed' | 'timestamp' | 'ipfsHash'>
    )> }
  )> }
);

export type FundQueryVariables = Exact<{
  fund?: Maybe<Scalars['ID']>;
}>;


export type FundQuery = (
  { __typename?: 'Query' }
  & { funds: Array<(
    { __typename?: 'Fund' }
    & Pick<Fund, 'id' | 'name' | 'symbol' | 'manager' | 'feeBeneficiary' | 'timelock' | 'feeSweepInterval' | 'managementFee' | 'performanceFee' | 'maxInvestors' | 'maxInvestmentsPerInvestor' | 'minInvestmentAmount' | 'initialPrice' | 'logoUrl' | 'contactInfo' | 'tags' | 'usingUsdToken' | 'doneImportingInvestments' | 'lastFeeSweepEndedTimestamp' | 'feeSweeping' | 'feesSweptNotWithdrawn' | 'closed' | 'timestamp'>
    & { latestNav?: Maybe<(
      { __typename?: 'Nav' }
      & Pick<Nav, 'id' | 'navId' | 'aum' | 'supply' | 'totalCapitalContributed' | 'timestamp' | 'ipfsHash'>
    )> }
  )> }
);

export type FundByManagerQueryVariables = Exact<{
  manager?: Maybe<Scalars['String']>;
}>;


export type FundByManagerQuery = (
  { __typename?: 'Query' }
  & { funds: Array<(
    { __typename?: 'Fund' }
    & Pick<Fund, 'id' | 'name' | 'symbol' | 'manager' | 'feeBeneficiary' | 'timelock' | 'feeSweepInterval' | 'managementFee' | 'performanceFee' | 'maxInvestors' | 'maxInvestmentsPerInvestor' | 'minInvestmentAmount' | 'initialPrice' | 'logoUrl' | 'contactInfo' | 'tags' | 'usingUsdToken' | 'doneImportingInvestments' | 'lastFeeSweepEndedTimestamp' | 'feeSweeping' | 'feesSweptNotWithdrawn' | 'closed' | 'timestamp'>
    & { latestNav?: Maybe<(
      { __typename?: 'Nav' }
      & Pick<Nav, 'id' | 'navId' | 'aum' | 'supply' | 'totalCapitalContributed' | 'timestamp' | 'ipfsHash'>
    )> }
  )> }
);

export type NavsByFundQueryVariables = Exact<{
  fund?: Maybe<Scalars['String']>;
}>;


export type NavsByFundQuery = (
  { __typename?: 'Query' }
  & { navs: Array<(
    { __typename?: 'Nav' }
    & Pick<Nav, 'id' | 'navId' | 'aum' | 'supply' | 'totalCapitalContributed' | 'timestamp' | 'ipfsHash'>
  )> }
);

export type NavByFundWithLatestIpfsHashQueryVariables = Exact<{
  fund?: Maybe<Scalars['String']>;
}>;


export type NavByFundWithLatestIpfsHashQuery = (
  { __typename?: 'Query' }
  & { navs: Array<(
    { __typename?: 'Nav' }
    & Pick<Nav, 'id' | 'navId' | 'aum' | 'supply' | 'totalCapitalContributed' | 'timestamp' | 'ipfsHash'>
  )> }
);

export type InvestmentRequestsByFundQueryVariables = Exact<{
  fund?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type InvestmentRequestsByFundQuery = (
  { __typename?: 'Query' }
  & { investmentRequests: Array<(
    { __typename?: 'InvestmentRequest' }
    & Pick<InvestmentRequest, 'id' | 'investmentRequestId' | 'usdAmount' | 'minFundAmount' | 'maxFundAmount' | 'deadline' | 'timestamp' | 'status'>
    & { fund: (
      { __typename?: 'Fund' }
      & Pick<Fund, 'id'>
    ), investor: (
      { __typename?: 'Investor' }
      & Pick<Investor, 'id'>
    ), investment?: Maybe<(
      { __typename?: 'Investment' }
      & Pick<Investment, 'id'>
    )> }
  )> }
);

export type InvestmentRequestsByInvestorQueryVariables = Exact<{
  investor?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type InvestmentRequestsByInvestorQuery = (
  { __typename?: 'Query' }
  & { investmentRequests: Array<(
    { __typename?: 'InvestmentRequest' }
    & Pick<InvestmentRequest, 'id' | 'investmentRequestId' | 'usdAmount' | 'minFundAmount' | 'maxFundAmount' | 'deadline' | 'timestamp' | 'status'>
    & { fund: (
      { __typename?: 'Fund' }
      & Pick<Fund, 'id' | 'name' | 'logoUrl'>
      & { latestNav?: Maybe<(
        { __typename?: 'Nav' }
        & Pick<Nav, 'id' | 'aum' | 'supply'>
      )> }
    ), investor: (
      { __typename?: 'Investor' }
      & Pick<Investor, 'id'>
    ), investment?: Maybe<(
      { __typename?: 'Investment' }
      & Pick<Investment, 'id'>
    )> }
  )> }
);

export type InvestmentRequestsByInvestorAndFundQueryVariables = Exact<{
  investor?: Maybe<Scalars['String']>;
  fund?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type InvestmentRequestsByInvestorAndFundQuery = (
  { __typename?: 'Query' }
  & { investmentRequests: Array<(
    { __typename?: 'InvestmentRequest' }
    & Pick<InvestmentRequest, 'id' | 'investmentRequestId' | 'usdAmount' | 'minFundAmount' | 'maxFundAmount' | 'deadline' | 'timestamp' | 'status'>
    & { fund: (
      { __typename?: 'Fund' }
      & Pick<Fund, 'id'>
    ), investor: (
      { __typename?: 'Investor' }
      & Pick<Investor, 'id'>
    ), investment?: Maybe<(
      { __typename?: 'Investment' }
      & Pick<Investment, 'id'>
    )> }
  )> }
);

export type InvestmentsByFundQueryVariables = Exact<{
  fund: Scalars['String'];
}>;


export type InvestmentsByFundQuery = (
  { __typename?: 'Query' }
  & { investments: Array<(
    { __typename?: 'Investment' }
    & Pick<Investment, 'id' | 'investmentId' | 'timestamp' | 'lockupTimestamp' | 'initialFundAmount' | 'managementFeeCostBasis' | 'usdTransferred' | 'imported' | 'remainingFundAmount' | 'usdManagementFeesSwept' | 'usdPerformanceFeesSwept' | 'fundManagementFeesSwept' | 'fundPerformanceFeesSwept' | 'highWaterMark' | 'lastFeeSweepTimestamp'>
    & { fund: (
      { __typename?: 'Fund' }
      & Pick<Fund, 'id'>
    ), investor: (
      { __typename?: 'Investor' }
      & Pick<Investor, 'id'>
    ), investmentRequest?: Maybe<(
      { __typename?: 'InvestmentRequest' }
      & Pick<InvestmentRequest, 'id'>
    )>, feeSweeps: Array<(
      { __typename?: 'FeeSweep' }
      & Pick<FeeSweep, 'id'>
    )>, redemptionRequest?: Maybe<(
      { __typename?: 'RedemptionRequest' }
      & Pick<RedemptionRequest, 'id'>
    )>, redemption?: Maybe<(
      { __typename?: 'Redemption' }
      & Pick<Redemption, 'id' | 'usdAmount' | 'timestamp' | 'usdTransferred'>
    )> }
  )> }
);

export type InvestmentsByInvestorQueryVariables = Exact<{
  investor: Scalars['String'];
}>;


export type InvestmentsByInvestorQuery = (
  { __typename?: 'Query' }
  & { investments: Array<(
    { __typename?: 'Investment' }
    & Pick<Investment, 'id' | 'investmentId' | 'timestamp' | 'lockupTimestamp' | 'initialFundAmount' | 'managementFeeCostBasis' | 'usdTransferred' | 'imported' | 'remainingFundAmount' | 'usdManagementFeesSwept' | 'usdPerformanceFeesSwept' | 'fundManagementFeesSwept' | 'fundPerformanceFeesSwept' | 'highWaterMark' | 'lastFeeSweepTimestamp'>
    & { fund: (
      { __typename?: 'Fund' }
      & Pick<Fund, 'id' | 'name' | 'logoUrl'>
      & { latestNav?: Maybe<(
        { __typename?: 'Nav' }
        & Pick<Nav, 'id' | 'aum' | 'supply'>
      )> }
    ), investor: (
      { __typename?: 'Investor' }
      & Pick<Investor, 'id'>
    ), investmentRequest?: Maybe<(
      { __typename?: 'InvestmentRequest' }
      & Pick<InvestmentRequest, 'id'>
    )>, redemptionRequest?: Maybe<(
      { __typename?: 'RedemptionRequest' }
      & Pick<RedemptionRequest, 'id'>
    )>, redemption?: Maybe<(
      { __typename?: 'Redemption' }
      & Pick<Redemption, 'id' | 'usdAmount' | 'timestamp' | 'usdTransferred'>
    )> }
  )> }
);

export type InvestmentsByInvestorAndFundQueryVariables = Exact<{
  investor: Scalars['String'];
  fund: Scalars['String'];
}>;


export type InvestmentsByInvestorAndFundQuery = (
  { __typename?: 'Query' }
  & { investments: Array<(
    { __typename?: 'Investment' }
    & Pick<Investment, 'id' | 'investmentId' | 'timestamp' | 'lockupTimestamp' | 'initialFundAmount' | 'managementFeeCostBasis' | 'usdTransferred' | 'imported' | 'remainingFundAmount' | 'usdManagementFeesSwept' | 'usdPerformanceFeesSwept' | 'fundManagementFeesSwept' | 'fundPerformanceFeesSwept' | 'highWaterMark' | 'lastFeeSweepTimestamp'>
    & { fund: (
      { __typename?: 'Fund' }
      & Pick<Fund, 'id'>
    ), investor: (
      { __typename?: 'Investor' }
      & Pick<Investor, 'id'>
    ), investmentRequest?: Maybe<(
      { __typename?: 'InvestmentRequest' }
      & Pick<InvestmentRequest, 'id'>
    )>, redemptionRequest?: Maybe<(
      { __typename?: 'RedemptionRequest' }
      & Pick<RedemptionRequest, 'id'>
    )>, redemption?: Maybe<(
      { __typename?: 'Redemption' }
      & Pick<Redemption, 'id' | 'usdAmount' | 'timestamp' | 'usdTransferred'>
    )> }
  )> }
);

export type RedemptionRequestsByFundQueryVariables = Exact<{
  fund?: Maybe<Scalars['String']>;
}>;


export type RedemptionRequestsByFundQuery = (
  { __typename?: 'Query' }
  & { redemptionRequests: Array<(
    { __typename?: 'RedemptionRequest' }
    & Pick<RedemptionRequest, 'id' | 'redemptionRequestId' | 'minUsdAmount' | 'deadline' | 'timestamp'>
    & { fund: (
      { __typename?: 'Fund' }
      & Pick<Fund, 'id'>
    ), investment: (
      { __typename?: 'Investment' }
      & Pick<Investment, 'id'>
      & { investor: (
        { __typename?: 'Investor' }
        & Pick<Investor, 'id'>
      ) }
    ), redemption?: Maybe<(
      { __typename?: 'Redemption' }
      & Pick<Redemption, 'id'>
    )> }
  )> }
);

export type RedemptionsByFundQueryVariables = Exact<{
  fund?: Maybe<Scalars['String']>;
}>;


export type RedemptionsByFundQuery = (
  { __typename?: 'Query' }
  & { redemptions: Array<(
    { __typename?: 'Redemption' }
    & Pick<Redemption, 'id' | 'redemptionId' | 'fundAmount' | 'usdAmount' | 'timestamp' | 'usdTransferred'>
    & { fund: (
      { __typename?: 'Fund' }
      & Pick<Fund, 'id'>
    ), investment: (
      { __typename?: 'Investment' }
      & Pick<Investment, 'id'>
      & { investor: (
        { __typename?: 'Investor' }
        & Pick<Investor, 'id'>
      ) }
    ), redemptionRequest?: Maybe<(
      { __typename?: 'RedemptionRequest' }
      & Pick<RedemptionRequest, 'id'>
    )> }
  )> }
);

export type InvestorsByFundsQueryVariables = Exact<{
  funds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type InvestorsByFundsQuery = (
  { __typename?: 'Query' }
  & { investors: Array<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'id'>
  )> }
);

export type InvestorQueryVariables = Exact<{
  investor: Scalars['ID'];
}>;


export type InvestorQuery = (
  { __typename?: 'Query' }
  & { investor?: Maybe<(
    { __typename?: 'Investor' }
    & Pick<Investor, 'id'>
    & { fundsWhitelisted: Array<(
      { __typename?: 'Fund' }
      & Pick<Fund, 'id'>
    )> }
  )> }
);

export type BlockNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type BlockNumberQuery = (
  { __typename?: 'Query' }
  & { _meta?: Maybe<(
    { __typename?: '_Meta_' }
    & { block: (
      { __typename?: '_Block_' }
      & Pick<_Block_, 'number'>
    ) }
  )> }
);

export type ActivityByFundQueryVariables = Exact<{
  fund: Scalars['String'];
}>;


export type ActivityByFundQuery = (
  { __typename?: 'Query' }
  & { investments: Array<(
    { __typename?: 'Investment' }
    & Pick<Investment, 'id' | 'timestamp' | 'initialUsdAmount' | 'initialFundAmount'>
    & { fund: (
      { __typename?: 'Fund' }
      & Pick<Fund, 'id'>
    ), investor: (
      { __typename?: 'Investor' }
      & Pick<Investor, 'id'>
    ) }
  )>, redemptions: Array<(
    { __typename?: 'Redemption' }
    & Pick<Redemption, 'id' | 'timestamp' | 'usdAmount'>
    & { fund: (
      { __typename?: 'Fund' }
      & Pick<Fund, 'id'>
    ), investment: (
      { __typename?: 'Investment' }
      & Pick<Investment, 'remainingFundAmount'>
      & { investor: (
        { __typename?: 'Investor' }
        & Pick<Investor, 'id'>
      ) }
    ) }
  )> }
);


export const FundsDocument = gql`
    query Funds {
  funds(first: 1000) {
    id
    name
    symbol
    manager
    feeBeneficiary
    timelock
    feeSweepInterval
    managementFee
    performanceFee
    maxInvestors
    maxInvestmentsPerInvestor
    minInvestmentAmount
    initialPrice
    logoUrl
    contactInfo
    tags
    usingUsdToken
    doneImportingInvestments
    lastFeeSweepEndedTimestamp
    feeSweeping
    feesSweptNotWithdrawn
    closed
    timestamp
    latestNav {
      id
      navId
      aum
      supply
      totalCapitalContributed
      timestamp
      ipfsHash
    }
  }
}
    `;

/**
 * __useFundsQuery__
 *
 * To run a query within a React component, call `useFundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFundsQuery(baseOptions?: Apollo.QueryHookOptions<FundsQuery, FundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundsQuery, FundsQueryVariables>(FundsDocument, options);
      }
export function useFundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundsQuery, FundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundsQuery, FundsQueryVariables>(FundsDocument, options);
        }
export type FundsQueryHookResult = ReturnType<typeof useFundsQuery>;
export type FundsLazyQueryHookResult = ReturnType<typeof useFundsLazyQuery>;
export type FundsQueryResult = Apollo.QueryResult<FundsQuery, FundsQueryVariables>;
export const FundDocument = gql`
    query Fund($fund: ID) {
  funds(where: {id: $fund}, first: 1) {
    id
    name
    symbol
    manager
    feeBeneficiary
    timelock
    feeSweepInterval
    managementFee
    performanceFee
    maxInvestors
    maxInvestmentsPerInvestor
    minInvestmentAmount
    initialPrice
    logoUrl
    contactInfo
    tags
    usingUsdToken
    doneImportingInvestments
    lastFeeSweepEndedTimestamp
    feeSweeping
    feesSweptNotWithdrawn
    closed
    timestamp
    latestNav {
      id
      navId
      aum
      supply
      totalCapitalContributed
      timestamp
      ipfsHash
    }
  }
}
    `;

/**
 * __useFundQuery__
 *
 * To run a query within a React component, call `useFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundQuery({
 *   variables: {
 *      fund: // value for 'fund'
 *   },
 * });
 */
export function useFundQuery(baseOptions?: Apollo.QueryHookOptions<FundQuery, FundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundQuery, FundQueryVariables>(FundDocument, options);
      }
export function useFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundQuery, FundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundQuery, FundQueryVariables>(FundDocument, options);
        }
export type FundQueryHookResult = ReturnType<typeof useFundQuery>;
export type FundLazyQueryHookResult = ReturnType<typeof useFundLazyQuery>;
export type FundQueryResult = Apollo.QueryResult<FundQuery, FundQueryVariables>;
export const FundByManagerDocument = gql`
    query FundByManager($manager: String) {
  funds(where: {manager: $manager}, first: 1) {
    id
    name
    symbol
    manager
    feeBeneficiary
    timelock
    feeSweepInterval
    managementFee
    performanceFee
    maxInvestors
    maxInvestmentsPerInvestor
    minInvestmentAmount
    initialPrice
    logoUrl
    contactInfo
    tags
    usingUsdToken
    doneImportingInvestments
    lastFeeSweepEndedTimestamp
    feeSweeping
    feesSweptNotWithdrawn
    closed
    timestamp
    latestNav {
      id
      navId
      aum
      supply
      totalCapitalContributed
      timestamp
      ipfsHash
    }
  }
}
    `;

/**
 * __useFundByManagerQuery__
 *
 * To run a query within a React component, call `useFundByManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundByManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundByManagerQuery({
 *   variables: {
 *      manager: // value for 'manager'
 *   },
 * });
 */
export function useFundByManagerQuery(baseOptions?: Apollo.QueryHookOptions<FundByManagerQuery, FundByManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundByManagerQuery, FundByManagerQueryVariables>(FundByManagerDocument, options);
      }
export function useFundByManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundByManagerQuery, FundByManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundByManagerQuery, FundByManagerQueryVariables>(FundByManagerDocument, options);
        }
export type FundByManagerQueryHookResult = ReturnType<typeof useFundByManagerQuery>;
export type FundByManagerLazyQueryHookResult = ReturnType<typeof useFundByManagerLazyQuery>;
export type FundByManagerQueryResult = Apollo.QueryResult<FundByManagerQuery, FundByManagerQueryVariables>;
export const NavsByFundDocument = gql`
    query NavsByFund($fund: String) {
  navs(first: 1000, orderBy: timestamp, orderDirection: asc, where: {fund: $fund}) {
    id
    navId
    aum
    supply
    totalCapitalContributed
    timestamp
    ipfsHash
  }
}
    `;

/**
 * __useNavsByFundQuery__
 *
 * To run a query within a React component, call `useNavsByFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavsByFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavsByFundQuery({
 *   variables: {
 *      fund: // value for 'fund'
 *   },
 * });
 */
export function useNavsByFundQuery(baseOptions?: Apollo.QueryHookOptions<NavsByFundQuery, NavsByFundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavsByFundQuery, NavsByFundQueryVariables>(NavsByFundDocument, options);
      }
export function useNavsByFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavsByFundQuery, NavsByFundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavsByFundQuery, NavsByFundQueryVariables>(NavsByFundDocument, options);
        }
export type NavsByFundQueryHookResult = ReturnType<typeof useNavsByFundQuery>;
export type NavsByFundLazyQueryHookResult = ReturnType<typeof useNavsByFundLazyQuery>;
export type NavsByFundQueryResult = Apollo.QueryResult<NavsByFundQuery, NavsByFundQueryVariables>;
export const NavByFundWithLatestIpfsHashDocument = gql`
    query NavByFundWithLatestIpfsHash($fund: String) {
  navs(
    first: 1
    orderBy: timestamp
    orderDirection: desc
    where: {fund: $fund, ipfsHash_not: ""}
  ) {
    id
    navId
    aum
    supply
    totalCapitalContributed
    timestamp
    ipfsHash
  }
}
    `;

/**
 * __useNavByFundWithLatestIpfsHashQuery__
 *
 * To run a query within a React component, call `useNavByFundWithLatestIpfsHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavByFundWithLatestIpfsHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavByFundWithLatestIpfsHashQuery({
 *   variables: {
 *      fund: // value for 'fund'
 *   },
 * });
 */
export function useNavByFundWithLatestIpfsHashQuery(baseOptions?: Apollo.QueryHookOptions<NavByFundWithLatestIpfsHashQuery, NavByFundWithLatestIpfsHashQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavByFundWithLatestIpfsHashQuery, NavByFundWithLatestIpfsHashQueryVariables>(NavByFundWithLatestIpfsHashDocument, options);
      }
export function useNavByFundWithLatestIpfsHashLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavByFundWithLatestIpfsHashQuery, NavByFundWithLatestIpfsHashQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavByFundWithLatestIpfsHashQuery, NavByFundWithLatestIpfsHashQueryVariables>(NavByFundWithLatestIpfsHashDocument, options);
        }
export type NavByFundWithLatestIpfsHashQueryHookResult = ReturnType<typeof useNavByFundWithLatestIpfsHashQuery>;
export type NavByFundWithLatestIpfsHashLazyQueryHookResult = ReturnType<typeof useNavByFundWithLatestIpfsHashLazyQuery>;
export type NavByFundWithLatestIpfsHashQueryResult = Apollo.QueryResult<NavByFundWithLatestIpfsHashQuery, NavByFundWithLatestIpfsHashQueryVariables>;
export const InvestmentRequestsByFundDocument = gql`
    query InvestmentRequestsByFund($fund: String, $first: Int = 1000) {
  investmentRequests(
    first: $first
    orderBy: investmentRequestId
    orderDirection: desc
    where: {fund: $fund}
  ) {
    id
    fund {
      id
    }
    investmentRequestId
    investor {
      id
    }
    usdAmount
    minFundAmount
    maxFundAmount
    deadline
    timestamp
    investment {
      id
    }
    status
  }
}
    `;

/**
 * __useInvestmentRequestsByFundQuery__
 *
 * To run a query within a React component, call `useInvestmentRequestsByFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentRequestsByFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentRequestsByFundQuery({
 *   variables: {
 *      fund: // value for 'fund'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useInvestmentRequestsByFundQuery(baseOptions?: Apollo.QueryHookOptions<InvestmentRequestsByFundQuery, InvestmentRequestsByFundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestmentRequestsByFundQuery, InvestmentRequestsByFundQueryVariables>(InvestmentRequestsByFundDocument, options);
      }
export function useInvestmentRequestsByFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestmentRequestsByFundQuery, InvestmentRequestsByFundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestmentRequestsByFundQuery, InvestmentRequestsByFundQueryVariables>(InvestmentRequestsByFundDocument, options);
        }
export type InvestmentRequestsByFundQueryHookResult = ReturnType<typeof useInvestmentRequestsByFundQuery>;
export type InvestmentRequestsByFundLazyQueryHookResult = ReturnType<typeof useInvestmentRequestsByFundLazyQuery>;
export type InvestmentRequestsByFundQueryResult = Apollo.QueryResult<InvestmentRequestsByFundQuery, InvestmentRequestsByFundQueryVariables>;
export const InvestmentRequestsByInvestorDocument = gql`
    query InvestmentRequestsByInvestor($investor: String, $first: Int = 1000) {
  investmentRequests(
    first: $first
    orderBy: investmentRequestId
    orderDirection: desc
    where: {investor: $investor}
  ) {
    id
    fund {
      id
      name
      logoUrl
      latestNav {
        id
        aum
        supply
      }
    }
    investmentRequestId
    investor {
      id
    }
    usdAmount
    minFundAmount
    maxFundAmount
    deadline
    timestamp
    investment {
      id
    }
    status
  }
}
    `;

/**
 * __useInvestmentRequestsByInvestorQuery__
 *
 * To run a query within a React component, call `useInvestmentRequestsByInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentRequestsByInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentRequestsByInvestorQuery({
 *   variables: {
 *      investor: // value for 'investor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useInvestmentRequestsByInvestorQuery(baseOptions?: Apollo.QueryHookOptions<InvestmentRequestsByInvestorQuery, InvestmentRequestsByInvestorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestmentRequestsByInvestorQuery, InvestmentRequestsByInvestorQueryVariables>(InvestmentRequestsByInvestorDocument, options);
      }
export function useInvestmentRequestsByInvestorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestmentRequestsByInvestorQuery, InvestmentRequestsByInvestorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestmentRequestsByInvestorQuery, InvestmentRequestsByInvestorQueryVariables>(InvestmentRequestsByInvestorDocument, options);
        }
export type InvestmentRequestsByInvestorQueryHookResult = ReturnType<typeof useInvestmentRequestsByInvestorQuery>;
export type InvestmentRequestsByInvestorLazyQueryHookResult = ReturnType<typeof useInvestmentRequestsByInvestorLazyQuery>;
export type InvestmentRequestsByInvestorQueryResult = Apollo.QueryResult<InvestmentRequestsByInvestorQuery, InvestmentRequestsByInvestorQueryVariables>;
export const InvestmentRequestsByInvestorAndFundDocument = gql`
    query InvestmentRequestsByInvestorAndFund($investor: String, $fund: String, $first: Int = 1000) {
  investmentRequests(
    first: $first
    orderBy: investmentRequestId
    orderDirection: desc
    where: {investor: $investor, fund: $fund}
  ) {
    id
    fund {
      id
    }
    investmentRequestId
    investor {
      id
    }
    usdAmount
    minFundAmount
    maxFundAmount
    deadline
    timestamp
    investment {
      id
    }
    status
  }
}
    `;

/**
 * __useInvestmentRequestsByInvestorAndFundQuery__
 *
 * To run a query within a React component, call `useInvestmentRequestsByInvestorAndFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentRequestsByInvestorAndFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentRequestsByInvestorAndFundQuery({
 *   variables: {
 *      investor: // value for 'investor'
 *      fund: // value for 'fund'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useInvestmentRequestsByInvestorAndFundQuery(baseOptions?: Apollo.QueryHookOptions<InvestmentRequestsByInvestorAndFundQuery, InvestmentRequestsByInvestorAndFundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestmentRequestsByInvestorAndFundQuery, InvestmentRequestsByInvestorAndFundQueryVariables>(InvestmentRequestsByInvestorAndFundDocument, options);
      }
export function useInvestmentRequestsByInvestorAndFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestmentRequestsByInvestorAndFundQuery, InvestmentRequestsByInvestorAndFundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestmentRequestsByInvestorAndFundQuery, InvestmentRequestsByInvestorAndFundQueryVariables>(InvestmentRequestsByInvestorAndFundDocument, options);
        }
export type InvestmentRequestsByInvestorAndFundQueryHookResult = ReturnType<typeof useInvestmentRequestsByInvestorAndFundQuery>;
export type InvestmentRequestsByInvestorAndFundLazyQueryHookResult = ReturnType<typeof useInvestmentRequestsByInvestorAndFundLazyQuery>;
export type InvestmentRequestsByInvestorAndFundQueryResult = Apollo.QueryResult<InvestmentRequestsByInvestorAndFundQuery, InvestmentRequestsByInvestorAndFundQueryVariables>;
export const InvestmentsByFundDocument = gql`
    query InvestmentsByFund($fund: String!) {
  investments(where: {fund: $fund}, first: 1000) {
    id
    fund {
      id
    }
    investmentId
    investor {
      id
    }
    timestamp
    lockupTimestamp
    initialFundAmount
    managementFeeCostBasis
    investmentRequest {
      id
    }
    usdTransferred
    imported
    remainingFundAmount
    usdManagementFeesSwept
    usdPerformanceFeesSwept
    fundManagementFeesSwept
    fundPerformanceFeesSwept
    highWaterMark
    lastFeeSweepTimestamp
    feeSweeps {
      id
    }
    redemptionRequest {
      id
    }
    redemption {
      id
      usdAmount
      timestamp
      usdTransferred
    }
  }
}
    `;

/**
 * __useInvestmentsByFundQuery__
 *
 * To run a query within a React component, call `useInvestmentsByFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentsByFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentsByFundQuery({
 *   variables: {
 *      fund: // value for 'fund'
 *   },
 * });
 */
export function useInvestmentsByFundQuery(baseOptions: Apollo.QueryHookOptions<InvestmentsByFundQuery, InvestmentsByFundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestmentsByFundQuery, InvestmentsByFundQueryVariables>(InvestmentsByFundDocument, options);
      }
export function useInvestmentsByFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestmentsByFundQuery, InvestmentsByFundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestmentsByFundQuery, InvestmentsByFundQueryVariables>(InvestmentsByFundDocument, options);
        }
export type InvestmentsByFundQueryHookResult = ReturnType<typeof useInvestmentsByFundQuery>;
export type InvestmentsByFundLazyQueryHookResult = ReturnType<typeof useInvestmentsByFundLazyQuery>;
export type InvestmentsByFundQueryResult = Apollo.QueryResult<InvestmentsByFundQuery, InvestmentsByFundQueryVariables>;
export const InvestmentsByInvestorDocument = gql`
    query InvestmentsByInvestor($investor: String!) {
  investments(where: {investor: $investor}, first: 1000) {
    id
    fund {
      id
      name
      logoUrl
      latestNav {
        id
        aum
        supply
      }
    }
    investmentId
    investor {
      id
    }
    timestamp
    lockupTimestamp
    initialFundAmount
    managementFeeCostBasis
    investmentRequest {
      id
    }
    usdTransferred
    imported
    remainingFundAmount
    usdManagementFeesSwept
    usdPerformanceFeesSwept
    fundManagementFeesSwept
    fundPerformanceFeesSwept
    highWaterMark
    lastFeeSweepTimestamp
    redemptionRequest {
      id
    }
    redemption {
      id
      usdAmount
      timestamp
      usdTransferred
    }
  }
}
    `;

/**
 * __useInvestmentsByInvestorQuery__
 *
 * To run a query within a React component, call `useInvestmentsByInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentsByInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentsByInvestorQuery({
 *   variables: {
 *      investor: // value for 'investor'
 *   },
 * });
 */
export function useInvestmentsByInvestorQuery(baseOptions: Apollo.QueryHookOptions<InvestmentsByInvestorQuery, InvestmentsByInvestorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestmentsByInvestorQuery, InvestmentsByInvestorQueryVariables>(InvestmentsByInvestorDocument, options);
      }
export function useInvestmentsByInvestorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestmentsByInvestorQuery, InvestmentsByInvestorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestmentsByInvestorQuery, InvestmentsByInvestorQueryVariables>(InvestmentsByInvestorDocument, options);
        }
export type InvestmentsByInvestorQueryHookResult = ReturnType<typeof useInvestmentsByInvestorQuery>;
export type InvestmentsByInvestorLazyQueryHookResult = ReturnType<typeof useInvestmentsByInvestorLazyQuery>;
export type InvestmentsByInvestorQueryResult = Apollo.QueryResult<InvestmentsByInvestorQuery, InvestmentsByInvestorQueryVariables>;
export const InvestmentsByInvestorAndFundDocument = gql`
    query InvestmentsByInvestorAndFund($investor: String!, $fund: String!) {
  investments(where: {investor: $investor, fund: $fund}, first: 1000) {
    id
    fund {
      id
    }
    investmentId
    investor {
      id
    }
    timestamp
    lockupTimestamp
    initialFundAmount
    managementFeeCostBasis
    investmentRequest {
      id
    }
    usdTransferred
    imported
    remainingFundAmount
    usdManagementFeesSwept
    usdPerformanceFeesSwept
    fundManagementFeesSwept
    fundPerformanceFeesSwept
    highWaterMark
    lastFeeSweepTimestamp
    redemptionRequest {
      id
    }
    redemption {
      id
      usdAmount
      timestamp
      usdTransferred
    }
  }
}
    `;

/**
 * __useInvestmentsByInvestorAndFundQuery__
 *
 * To run a query within a React component, call `useInvestmentsByInvestorAndFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentsByInvestorAndFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentsByInvestorAndFundQuery({
 *   variables: {
 *      investor: // value for 'investor'
 *      fund: // value for 'fund'
 *   },
 * });
 */
export function useInvestmentsByInvestorAndFundQuery(baseOptions: Apollo.QueryHookOptions<InvestmentsByInvestorAndFundQuery, InvestmentsByInvestorAndFundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestmentsByInvestorAndFundQuery, InvestmentsByInvestorAndFundQueryVariables>(InvestmentsByInvestorAndFundDocument, options);
      }
export function useInvestmentsByInvestorAndFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestmentsByInvestorAndFundQuery, InvestmentsByInvestorAndFundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestmentsByInvestorAndFundQuery, InvestmentsByInvestorAndFundQueryVariables>(InvestmentsByInvestorAndFundDocument, options);
        }
export type InvestmentsByInvestorAndFundQueryHookResult = ReturnType<typeof useInvestmentsByInvestorAndFundQuery>;
export type InvestmentsByInvestorAndFundLazyQueryHookResult = ReturnType<typeof useInvestmentsByInvestorAndFundLazyQuery>;
export type InvestmentsByInvestorAndFundQueryResult = Apollo.QueryResult<InvestmentsByInvestorAndFundQuery, InvestmentsByInvestorAndFundQueryVariables>;
export const RedemptionRequestsByFundDocument = gql`
    query RedemptionRequestsByFund($fund: String) {
  redemptionRequests(where: {fund: $fund}, first: 1000) {
    id
    fund {
      id
    }
    redemptionRequestId
    investment {
      id
      investor {
        id
      }
    }
    minUsdAmount
    deadline
    timestamp
    redemption {
      id
    }
  }
}
    `;

/**
 * __useRedemptionRequestsByFundQuery__
 *
 * To run a query within a React component, call `useRedemptionRequestsByFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedemptionRequestsByFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedemptionRequestsByFundQuery({
 *   variables: {
 *      fund: // value for 'fund'
 *   },
 * });
 */
export function useRedemptionRequestsByFundQuery(baseOptions?: Apollo.QueryHookOptions<RedemptionRequestsByFundQuery, RedemptionRequestsByFundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RedemptionRequestsByFundQuery, RedemptionRequestsByFundQueryVariables>(RedemptionRequestsByFundDocument, options);
      }
export function useRedemptionRequestsByFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RedemptionRequestsByFundQuery, RedemptionRequestsByFundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RedemptionRequestsByFundQuery, RedemptionRequestsByFundQueryVariables>(RedemptionRequestsByFundDocument, options);
        }
export type RedemptionRequestsByFundQueryHookResult = ReturnType<typeof useRedemptionRequestsByFundQuery>;
export type RedemptionRequestsByFundLazyQueryHookResult = ReturnType<typeof useRedemptionRequestsByFundLazyQuery>;
export type RedemptionRequestsByFundQueryResult = Apollo.QueryResult<RedemptionRequestsByFundQuery, RedemptionRequestsByFundQueryVariables>;
export const RedemptionsByFundDocument = gql`
    query RedemptionsByFund($fund: String) {
  redemptions(where: {fund: $fund}, first: 1000) {
    id
    fund {
      id
    }
    redemptionId
    investment {
      id
      investor {
        id
      }
    }
    redemptionRequest {
      id
    }
    fundAmount
    usdAmount
    timestamp
    usdTransferred
  }
}
    `;

/**
 * __useRedemptionsByFundQuery__
 *
 * To run a query within a React component, call `useRedemptionsByFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedemptionsByFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedemptionsByFundQuery({
 *   variables: {
 *      fund: // value for 'fund'
 *   },
 * });
 */
export function useRedemptionsByFundQuery(baseOptions?: Apollo.QueryHookOptions<RedemptionsByFundQuery, RedemptionsByFundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RedemptionsByFundQuery, RedemptionsByFundQueryVariables>(RedemptionsByFundDocument, options);
      }
export function useRedemptionsByFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RedemptionsByFundQuery, RedemptionsByFundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RedemptionsByFundQuery, RedemptionsByFundQueryVariables>(RedemptionsByFundDocument, options);
        }
export type RedemptionsByFundQueryHookResult = ReturnType<typeof useRedemptionsByFundQuery>;
export type RedemptionsByFundLazyQueryHookResult = ReturnType<typeof useRedemptionsByFundLazyQuery>;
export type RedemptionsByFundQueryResult = Apollo.QueryResult<RedemptionsByFundQuery, RedemptionsByFundQueryVariables>;
export const InvestorsByFundsDocument = gql`
    query InvestorsByFunds($funds: [String!]) {
  investors(where: {fundsWhitelisted_contains: $funds}, first: 1000) {
    id
  }
}
    `;

/**
 * __useInvestorsByFundsQuery__
 *
 * To run a query within a React component, call `useInvestorsByFundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorsByFundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorsByFundsQuery({
 *   variables: {
 *      funds: // value for 'funds'
 *   },
 * });
 */
export function useInvestorsByFundsQuery(baseOptions?: Apollo.QueryHookOptions<InvestorsByFundsQuery, InvestorsByFundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestorsByFundsQuery, InvestorsByFundsQueryVariables>(InvestorsByFundsDocument, options);
      }
export function useInvestorsByFundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestorsByFundsQuery, InvestorsByFundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestorsByFundsQuery, InvestorsByFundsQueryVariables>(InvestorsByFundsDocument, options);
        }
export type InvestorsByFundsQueryHookResult = ReturnType<typeof useInvestorsByFundsQuery>;
export type InvestorsByFundsLazyQueryHookResult = ReturnType<typeof useInvestorsByFundsLazyQuery>;
export type InvestorsByFundsQueryResult = Apollo.QueryResult<InvestorsByFundsQuery, InvestorsByFundsQueryVariables>;
export const InvestorDocument = gql`
    query investor($investor: ID!) {
  investor(id: $investor) {
    id
    fundsWhitelisted {
      id
    }
  }
}
    `;

/**
 * __useInvestorQuery__
 *
 * To run a query within a React component, call `useInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorQuery({
 *   variables: {
 *      investor: // value for 'investor'
 *   },
 * });
 */
export function useInvestorQuery(baseOptions: Apollo.QueryHookOptions<InvestorQuery, InvestorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestorQuery, InvestorQueryVariables>(InvestorDocument, options);
      }
export function useInvestorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestorQuery, InvestorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestorQuery, InvestorQueryVariables>(InvestorDocument, options);
        }
export type InvestorQueryHookResult = ReturnType<typeof useInvestorQuery>;
export type InvestorLazyQueryHookResult = ReturnType<typeof useInvestorLazyQuery>;
export type InvestorQueryResult = Apollo.QueryResult<InvestorQuery, InvestorQueryVariables>;
export const BlockNumberDocument = gql`
    query BlockNumber {
  _meta {
    block {
      number
    }
  }
}
    `;

/**
 * __useBlockNumberQuery__
 *
 * To run a query within a React component, call `useBlockNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlockNumberQuery(baseOptions?: Apollo.QueryHookOptions<BlockNumberQuery, BlockNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockNumberQuery, BlockNumberQueryVariables>(BlockNumberDocument, options);
      }
export function useBlockNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockNumberQuery, BlockNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockNumberQuery, BlockNumberQueryVariables>(BlockNumberDocument, options);
        }
export type BlockNumberQueryHookResult = ReturnType<typeof useBlockNumberQuery>;
export type BlockNumberLazyQueryHookResult = ReturnType<typeof useBlockNumberLazyQuery>;
export type BlockNumberQueryResult = Apollo.QueryResult<BlockNumberQuery, BlockNumberQueryVariables>;
export const ActivityByFundDocument = gql`
    query ActivityByFund($fund: String!) {
  investments(where: {fund: $fund}) {
    id
    fund {
      id
    }
    investor {
      id
    }
    timestamp
    initialUsdAmount
    initialFundAmount
  }
  redemptions(where: {fund: $fund}) {
    id
    fund {
      id
    }
    timestamp
    usdAmount
    investment {
      remainingFundAmount
      investor {
        id
      }
    }
  }
}
    `;

/**
 * __useActivityByFundQuery__
 *
 * To run a query within a React component, call `useActivityByFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityByFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityByFundQuery({
 *   variables: {
 *      fund: // value for 'fund'
 *   },
 * });
 */
export function useActivityByFundQuery(baseOptions: Apollo.QueryHookOptions<ActivityByFundQuery, ActivityByFundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityByFundQuery, ActivityByFundQueryVariables>(ActivityByFundDocument, options);
      }
export function useActivityByFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityByFundQuery, ActivityByFundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityByFundQuery, ActivityByFundQueryVariables>(ActivityByFundDocument, options);
        }
export type ActivityByFundQueryHookResult = ReturnType<typeof useActivityByFundQuery>;
export type ActivityByFundLazyQueryHookResult = ReturnType<typeof useActivityByFundLazyQuery>;
export type ActivityByFundQueryResult = Apollo.QueryResult<ActivityByFundQuery, ActivityByFundQueryVariables>;