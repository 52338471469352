import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function CenteredProgress() {
  return (
    <Box textAlign='center'>
      <CircularProgress sx={{ my: 5 }} />
    </Box>
  );
}
