import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';

import AppLayout from '../components/AppLayout';

const Manage = lazy(() => import('./Manage'));
const Portfolio = lazy(() => import('./Portfolio'));
const Funds = lazy(() => import('./Funds'));
const Fund = lazy(() => import('./Funds/Fund'));
const Invest = lazy(() => import('./Funds/Fund/Invest'));
const Welcome = lazy(() => import('./Welcome'));
const Contributions = lazy(() => import('./Manage/Fund/Contributions'));
const Assets = lazy(() => import('./Manage/Fund/Assets'));
const Investors = lazy(() => import('./Manage/Fund/Investors'));
const Fees = lazy(() => import('./Manage/Fund/Fees'));
const ManagerOnlyCheck = lazy(() => import('../components/ManagerOnlyCheck'));
const FundExistsCheck = lazy(() => import('../components/FundExistsCheck'));
const NotFound = lazy(() => import('../components/NotFound'));
const Wallets = lazy(() => import('./Manage/Fund/Wallets'));
const Details = lazy(() => import('./Manage/Fund/Details'));
const DataRoomPage = lazy(() => import('./Manage/Fund/DataRoomPage'));

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<AppLayout />}>
        <Route path='' element={<Welcome />} />
        <Route path='portfolio' element={<Portfolio />} />
        <Route path='funds'>
          <Route path='' element={<Funds />} />
          <Route path=':id' element={<FundExistsCheck />}>
            <Route path='' element={<Fund />} />
            <Route path='invest' element={<Invest />} />
          </Route>
        </Route>
        <Route path='manage'>
          <Route path='' element={<Manage />} />
          <Route path=':id' element={<ManagerOnlyCheck />}>
            <Route path='assets' element={<Assets />} />
            <Route path='contributions' element={<Contributions />} />
            <Route path='investors' element={<Investors />} />
            <Route path='fees' element={<Fees />} />
            <Route path='wallets' element={<Wallets />} />
            <Route path='dataroom' element={<DataRoomPage />} />
            <Route path='details' element={<Details />} />
          </Route>
        </Route>
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  );
}
