import { createReducer } from '@reduxjs/toolkit';
import {
  updateUsdBalance,
  updateRefreshTimestamp,
  setGraph,
  txSent,
  txMined,
  updateEthBalance,
  toggleSidebar,
} from './actions';

export interface BoolMap {
  readonly [key: string]: boolean;
}

export interface ApplicationState {
  readonly refreshTimestamp: { readonly [chainId: string]: number };
  readonly txPending: boolean;
  readonly ethBalance: string;
  readonly usdBalance: string;
  readonly selectedGraph: BoolMap;
  readonly sidebarOpen: boolean;
}

const initialState: ApplicationState = {
  // Web3
  refreshTimestamp: {},
  txPending: false,

  // Contract data and analysis
  ethBalance: '0',
  usdBalance: '0.00',

  selectedGraph: {
    aum: true,
    price: false,
    supply: false,
    totalCapitalContributed: true,
  },
  sidebarOpen: false,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateRefreshTimestamp, (state, action) => {
      const { chainId, timestamp } = action.payload;
      if (typeof state.refreshTimestamp[chainId] !== 'number') {
        state.refreshTimestamp[chainId] = timestamp;
      } else {
        state.refreshTimestamp[chainId] = Math.max(
          timestamp,
          state.refreshTimestamp[chainId],
        );
      }
    })
    .addCase(updateUsdBalance, (state, action) => {
      const { balance } = action.payload;
      state.usdBalance = balance;
    })
    .addCase(updateEthBalance, (state, action) => {
      const { balance } = action.payload;
      state.ethBalance = balance;
    })
    .addCase(setGraph, (state, action) => {
      const { graph } = action.payload;
      state.selectedGraph[graph] = !state.selectedGraph[graph];
    })
    .addCase(txSent, (state, action) => {
      state.txPending = true;
    })
    .addCase(txMined, (state, action) => {
      state.txPending = false;
    })
    .addCase(toggleSidebar, (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    }),
);
